//React Functionality
import React from 'react';
import {
  SortingState,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table';
//Components
import layout from '@/constants/layout';
import ExpandableRow from './ExpandableRow';
//Types
import { LocumsLog } from '@/types/entities.d';
//CSS
import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';
import { IconArrowCarrotDown, IconArrowCarrotUp } from '../Icons/ArrowCarrot';
import { Divider } from '@mui/material';
import styled from 'styled-components';

const {
  components: { iconStyles },
} = layout;

const ROW_HEIGHT = 40;
const TABLE_BACKGROUND_COLOR = 'white';
const TABLE_BORDER_COLOR = appColors.border.primary;
const TABLE_BORDER_WIDTH = 1;
const ROW_HOVER_COLOR = '#f5f8ff';
const ROW_SELECTED_COLOR = '#f5f8ff';
const ROW_SELECTED_BORDERCOLOR = appColors.content.tertiary;

const ROW_FINAL_HEIGHT = ROW_HEIGHT + TABLE_BORDER_WIDTH * 2;

const PADDING = 8;

const tableStyles = css`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${TABLE_BACKGROUND_COLOR};
  border: ${TABLE_BORDER_WIDTH}px solid ${TABLE_BORDER_COLOR};
`;

const tableBodyStyles = css`
  background-color: ${TABLE_BACKGROUND_COLOR};
`;

const tableHeadStyles = css`
  background-color: ${TABLE_BACKGROUND_COLOR};
  border-bottom: ${TABLE_BORDER_WIDTH}px solid ${TABLE_BORDER_COLOR}; 
  border-collapse: separate;
  border-spacing: 0;
`;

const tableHeaderStyles = css`
  height: 24px;
  padding: 10px ${PADDING}px;
  padding-bottom: 10px;
`;

const tableHeaderContentStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const tableHeaderContentArrowStyles = css`
  ${iconStyles}
  width: 16px;
  height: 16px;
`;

const tableRowStyles = css`
  align-items: center;
  justify-content: center;
  vertical-align: center;
  border: none;
  cursor: pointer;
  height: ${ROW_FINAL_HEIGHT}px;
  &:hover {
    background-color: ${ROW_HOVER_COLOR};
  }
  overflow: hidden;
`;
const DividerStyle = styled(Divider)`
  width: 100%;
  margin: auto;
  border-width: 1px 0px thin;
`;
interface CustomDataTableProps<Model> {
  columns: ColumnDef<Model>[];
  data: Model[];
  sorting: SortingState;
  rowSelection: RowSelectionState;
  onSortingChange: OnChangeFn<SortingState>;
  onRowSelectionChange: OnChangeFn<RowSelectionState> | undefined;
  onClickRow?: (row: Model) => void;
}

function CustomDataTable<Model>({
  columns,
  data,
  sorting,
  rowSelection,
  onSortingChange,
  onRowSelectionChange,
  onClickRow,
}: CustomDataTableProps<Model>) {
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    debugTable: true,
    manualSorting: true,
    enableMultiRowSelection: true,
    onSortingChange,
    onRowSelectionChange: (r) =>
      !!onRowSelectionChange && onRowSelectionChange(r),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table
      {...{
        css: tableStyles,
      }}
    >
      <thead
        {...{
          css: tableHeadStyles,
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const isSelectHeader = header.id === 'select';
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  {...{
                    css: tableHeaderStyles,
                    style: {
                      ...(isSelectHeader ? { paddingLeft: 9 } : {}),
                    },
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        css: tableHeaderContentStyles,
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div
                        {...{
                          css: tableHeaderContentArrowStyles,
                        }}
                      >
                        {header.id !== 'select' && (
                          <SortIcon
                            {...{ headerProps: header.column.getIsSorted() }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
        <tr>
          <td colSpan={columns.length}>
            <DividerStyle />
          </td>
        </tr>
      </thead>

      <tbody
        {...{
          css: tableBodyStyles,
        }}
      >
        {table
          .getRowModel()
          .rows.slice(0, 10 | 25 | 50 | 75 | 100)
          .map((row) => {
            const isRowSelected = row.getIsSelected();
            return (
              <React.Fragment key={row.id}>
                <tr
                  {...{
                    css: css`
                      ${tableRowStyles}
                      background-color: ${isRowSelected
                        ? ROW_SELECTED_COLOR
                        : 'transparent'};
                    `,
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isSelectColumn = [
                      'select',
                      'application_count',
                      'email',
                      'website',
                    ].includes(cell.column.id);
                    return (
                      <td
                        {...{
                          css: css`
                            border: none;
                            flex-shrink: 1;
                            border-radius: 10px;
                            height: ${ROW_FINAL_HEIGHT}px;
                            padding: 0px ${PADDING}px;
                          `,
                          ...(isSelectColumn
                            ? {}
                            : {
                                onClick: () =>
                                  onClickRow && onClickRow(row.original),
                              }),
                          key: cell.id,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    <td colSpan={columns.length}>
                      <div
                        css={css`
                          padding: 10px;
                          background-color: #f0f3fa;
                        `}
                      >
                        <ExpandableRow item={row.original as LocumsLog} />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
}

export default CustomDataTable;

const SortIcon = ({ headerProps }: { headerProps: false | 'asc' | 'desc' }) => {
  const noSort = headerProps === null || headerProps === false;

  if (noSort) return <></>;

  return (
    <>
      {headerProps === 'desc' && <IconArrowCarrotDown />}
      {headerProps === 'asc' && (
        <IconArrowCarrotUp {...{ style: { transform: 'rotate(180deg)' } }} />
      )}
    </>
  );
};
