//React Functionality
import { memo } from 'react';
import useDropdownsData from '@/hooks/useDropdownsData';
import { Controller, UseFormReturn } from 'react-hook-form';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
//Types
import { EditLocumsLogFormValues } from '@/types/forms';
//Components
import Input, { INPUT_COMMONS } from '@/components/Input';
import InputMask from '@/components/InputMask';
import InputArea from '@/components/InputArea';
import InputDropdown from '@/components/InputDropdown';
import { FieldsContainer, FormContainer, RowContainer } from '../';
//CSS
import { css } from '@emotion/react';

const placeHolderStyle = css`
  color: #9b9ba4;
`;

const EditLocumsLogRoleForm = ({
  methods,
}: {
  methods: UseFormReturn<EditLocumsLogFormValues, any>;
}) => {
  const { control } = methods;

  const options = {
    yes: {
      value: true,
      label: 'Yes',
    },
    no: {
      value: false,
      label: 'No',
    },
  };

  const { dropdownOptions } = useDropdownsData();

  return (
    <form>
      <FormContainer>
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.role_title',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...field,
                        label: 'Job Title',
                        placeholder: 'Enter Job Title',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.specialty',
                control,
                rules: { ...fieldsDefaultRules['specialty'] },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        ...fieldsDefaultProps['specialty'],
                        placeholder: 'Enter Specialty',
                        options: dropdownOptions?.specialty,
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.pitch_date',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error, isTouched },
                  }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['pitch_date'],
                          ...field,
                          isTouched,
                          error,
                          id: field.name,
                          //min: new Date().toISOString().split('T')[0],
                          css: css`
                            ${isTouched
                              ? { color: '#05051B' }
                              : placeHolderStyle}
                          `,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.rate',
                  control,
                  // rules: {
                  //   required: {
                  //     value: true,
                  //     message: 'Min hourly compensation is required',
                  //   },
                  // },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['min_hourly_rate_gt'],
                          ...field,
                          label: 'Offer Rate',
                          // required: true,
                          error,
                          id: field.name,
                          mask: Number,
                          hasExtraChar: true,
                          //subText: datatableUtils.MIN_HOUR_SUBTEXT,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.role_start_date',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error, isTouched },
                  }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['role_start_date'],
                          ...field,
                          isTouched,
                          error,
                          id: field.name,
                          // min: new Date().toISOString().split('T')[0],
                          css: css`
                            ${isTouched
                              ? { color: '#05051B' }
                              : placeHolderStyle}
                          `,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.role_end_date',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error, isTouched },
                  }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['role_end_date'],
                          ...field,
                          isTouched,
                          error,
                          id: field.name,
                          //min: new Date().toISOString().split('T')[0],
                          css: css`
                            ${isTouched
                              ? { color: '#05051B' }
                              : placeHolderStyle}
                          `,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.travel_included',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['travel_included'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.lodging_included',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['lodging_included'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>

          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.role_description',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...field,
                        label: 'Job Description',
                        placeholder: 'Enter Job Description',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.role_notes',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        label: 'Additional Notes',
                        placeholder: 'Add other additional notes',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(EditLocumsLogRoleForm);
