import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  INPUT_COMMONS,
  Input,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledLabel,
  inputDefaultStyles,
} from '../Input';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

export interface InputAreaProps
  extends Input,
    InputHTMLAttributes<HTMLTextAreaElement> {
  labelHint?: string;
}

export const StyledInputArea = styled.textarea<InputAreaProps>`
  ${inputDefaultStyles}
  resize: vertical;
  max-height: 400px;
  min-height: 200px;
  min-width: 100px;
  &:focus {
    outline: none;
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_FOCUS};
    color: ${INPUT_COMMONS.TEXT_COLOR_DEFAULT};
  }
  &:disabled {
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED};
    color: ${INPUT_COMMONS.TEXT_COLOR_DISABLED};
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  }
  &:focus::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS};
  }
  &:disabled::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DISABLED};
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${INPUT_COMMONS.ERROR_COLOR};
      color: ${INPUT_COMMONS.ERROR_COLOR};
      ::placeholder {
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
      &:focus {
        outline: none;
        border-color: ${INPUT_COMMONS.ERROR_COLOR};
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
      &:focus::placeholder {
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
    `}
`;

const InputArea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  InputAreaProps
> = ({ label, labelHint, ...props }, ref) => {
  return (
    <StyledInputWrapper>
      <StyledLabel
        {...{
          children: label,
          style: {
            ...(props.disabled
              ? {
                  color: appColors.content.disabled,
                }
              : {}),
          },
        }}
      >
        {label}
        {!!labelHint && (
          <Paragraph
            {...{
              children: `  ${labelHint}`,
              variant: 'sm',
              style: {
                color: appColors.content.secondary,
                flexWrap: 'wrap',
              },
            }}
          />
        )}
      </StyledLabel>
      <StyledInputArea
        {...{
          ...props,
          ref,
        }}
      />
      {!!props.error?.message && (
        <StyledErrorMessage
          {...{
            children: props.error?.message,
          }}
        />
      )}
    </StyledInputWrapper>
  );
};

export default forwardRef(InputArea);
