import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Bottom from '../../../assets/images/Burnout-Bottom.jpg';
import Top from '../../../assets/images/Burnout-Top.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Locums as a way to avoid burnout</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeOne: {
              topImageSrc: Top,
              topImageAlt: 'Doctor on the floor',
              topSectionTitle: 'Locums as a way to avoid burnout ',
              topSectionText: (
                <>
                  The 2024 Medscape Physician Burnout & Depression Report: “We
                  Have Much Work to Do”* reported that nearly half (49%) of
                  physicians said they felt burned out at least once in their
                  careers. When physicians feel drained or burned out, it can
                  affect the rest of the staff, which can jeopardize the quality
                  of patient care. Because of the national shortage of
                  physicians, many healthcare facilities are understaffed. This
                  means full-time staffers are taking on more job tasks than
                  they should, because there are simply not enough people to
                  support the patient load. And being overworked can cause
                  burnout, which is why more physicians and providers are
                  choosing to work locum tenens. Other contributors to physician
                  burnout include:
                  <br />
                  <br />
                  <ol>
                    <li>
                      Facilities being understaffed, short staffed, or not able
                      to scale up to meet an increase in patient demand.
                      Staffing issues can be caused by:
                      <br />
                      <br />
                    </li>
                    <ol type="a">
                      <li>
                        A facility located in a remote area, where physician
                        recruitment can be difficult.
                      </li>
                      <li>Staff out on leave, such as maternity leave.</li>
                      <li>
                        Staff turnover. Being understaffed for any length of
                        time puts a strain on full-time staff, which contributes
                        to exhaustion.
                      </li>
                    </ol>
                    <br />
                    <li>
                      Physicians and other providers who work in full-time roles
                      have reported that they have little or no control over
                      their schedules. This can lead to feelings of frustration
                      and burnout. Practitioners have also voiced concerns over
                      missing family functions and not having enough time to
                      spend at home. The lack of downtime and missing family
                      events can wear physicians down.
                    </li>
                  </ol>
                </>
              ),
              hasQuote: true,
              bottomImageSrc: Bottom,
              bottomImageAlt: 'Recruiter typing data',
              bottomImageTitle:
                'More providers are choosing to work locums to prevent burnout',
              bottomSectionText: (
                <>
                  More healthcare professionals are choosing to work on
                  temporary assignments as locum tenens rather than working in
                  permanent, full-time roles, because it offers perks that
                  support work/life balance. Working locums gives them
                  scheduling flexibility, a variety of clinical settings to
                  choose from, and the opportunity to work in places around the
                  country. <br /><br />In particular, physicians and other practitioners are
                  choosing locum tenens to have a more rewarding work/life
                  balance that allows them the flexibility to pursue outside
                  interests and attend family events. They are also able to take
                  breaks between assignment, enabling them to take time for
                  travel and other interests. <br /><br />All of these perks together have
                  been shown to alleviate burnout, making for happier providers.
                  Locums offers: Flexibility. By working locum tenens,
                  physicians and other locum tenens practitioners can choose
                  where and when to practice and how much they want to work.
                  Their work schedules are set at their own discretion, which
                  means better control and balance than in permanent practice
                  settings. <br /><br />Variety. Locums physicians, dentists, and CRNAs can
                  choose to work shifts in interesting places around the country
                  and in different clinical settings. Reduced administrative
                  burden. Locums physicians aren’t typically responsible for the
                  same administrative workload as full-time employees.<br /><br />
                  Continuity of care. Well-rested providers are able to provide
                  better patient care, leading to better patient outcomes. Are
                  you ready to work locum tenens? Apply for jobs on locums.com
                  today.<br /><br /> [LEGAL] <br />*Medscape Physician Burnout & Depression Report
                  2024: “We Have Much Work To Do”, Jon McKenna, January 26, 2024
                </>
              ),
              withTestimonial: false,
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
