import { memo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FieldsContainer, FormContainer, RowContainer } from '../';
import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import { EditProfessionalReferencesFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { masks } from '@/constants/input-masks';
import RecruitersOnlyDetailsCard from '@/components/Dashboard/RecruitersOnlyDetailsCard';

const LocumsLogFlowReferencesEditForm = ({
  methods,
}: {
  methods: UseFormReturn<EditProfessionalReferencesFormValues, any>;
}) => {
  const { control } = methods;

  return (
    <form>
      <FormContainer>
        <RecruitersOnlyDetailsCard
          {...{
            content: (
              <FieldsContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'references.first_name',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                ...fieldsDefaultProps['first_name'],
                                placeholder: 'Enter First Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'references.last_name',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                ...fieldsDefaultProps['last_name'],
                                placeholder: 'Enter Last Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'references.phone',
                        control,
                        rules: { ...fieldsDefaultRules['phone'] },
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <InputMask
                              {...{
                                ...fieldsDefaultProps['phone'],
                                label: 'Phone #',
                                placeholder: 'Enter Phone #',
                                ...field,
                                mask: masks['phone'],
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'references.email',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Email',
                                placeholder: 'Enter Email',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'references.company',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Company Name',
                                placeholder: 'Enter Company Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
              </FieldsContainer>
            ),
          }}
        />
      </FormContainer>
    </form>
  );
};

export default memo(LocumsLogFlowReferencesEditForm);
