import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import produce from 'immer';

import { getFormInitialValueFromParam } from '@/utils/form-data';
import { getJobById } from '@/api/jobs';
import { Status } from '@/types/types';
import { Job } from '@/types/entities.d';
import { PostEditJobFormValues } from '@/types/forms';
import useDropdownsData from '@/hooks/useDropdownsData';
import { statesHash } from '@/constants/selects/states';
import { GetJobByIdResponse } from '@/types/api/responses';
import ModalLayout from '../../../Layout/Modal/ModalLayout';
import { boardCertificationHash } from '@/constants/selects/boardCertification';
import RecruitersDashboardTileModalContentShowMode from './RecruitersDashboardTileModalContentShowMode';
import RecruitersDashboardTileModalContentEditMode from './RecruitersDashboardTileModalContentEditMode';
import { credentialingTimeHash } from '@/constants/selects/credentialingtime';
import { anyStateLicensesHash } from '@/constants/selects/anyStateLicenses';

const initialFormValues: PostEditJobFormValues = {
  // Recruiter Info
  recruiter: {
    company_information: {
      logo_url: '',
      name: '',
    },
    first_name: '',
    last_name: '',
  },
  job: {
    id: -1,
    // Details
    title: '',
    profession: '',
    employment_type: '',
    assignment: '',
    facility: '',
    board_certification: '',
    credentialing_time: '',
    specialty: '',
    state: '',
    min_hourly_rate: '',
    target_start_date: '',
    any_state_licenses: '',
    // RECRUITERS ONLY //
    reference_number: '',
    facility_name: '',
    city: '',
    notes: '',
    // Description and Requirements
    description: '',
    requirements: '',
  },
};

interface RecruitersDashboardTileModalContentProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  job: Job;
}

interface State {
  jobByIdResponse: GetJobByIdResponse;
  status: Status;
  isEditMode: boolean;
}

type Action =
  | {
      payload: GetJobByIdResponse;
      type: 'SET_JOB';
    }
  | {
      payload: boolean;
      type: 'SET_MODE';
    }
  | {
      payload: Status;
      type: 'SET_STATUS';
    };

const initialState: State = {
  jobByIdResponse: {
    recruiter: {},
    job: {},
    company: {},
  },
  status: 'IDLE',
  isEditMode: false,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_MODE':
      draft.isEditMode = payload;
      return;
    case 'SET_JOB':
      draft.jobByIdResponse = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const RecruitersDashboardTileModalContent: FC<
  RecruitersDashboardTileModalContentProps
> = ({ job, modalProps }) => {
  const { dropdownsHashes } = useDropdownsData();

  const [{ isEditMode, jobByIdResponse, status }, localDispatch] = useReducer(
    reducer,
    initialState
  );

  const changeMode = useCallback(() => {
    localDispatch({ type: 'SET_MODE', payload: !isEditMode });
  }, [isEditMode]);

  const methods = useForm<PostEditJobFormValues>({
    defaultValues: { ...initialFormValues },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    reset,
  } = methods;

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await getJobById(job.id);

      if (response) {
        localDispatch({ type: 'SET_JOB', payload: response });
        const {
          job,
          recruiter,
          session: { current_user },
        } = response;
        const hasBoardCert = !!job.board_certification;
        const hasCredentialingTime = !!job.credentialing_time;
        const hasAnyStateLicenses = !!job.any_state_licenses;
        reset(
          {
            //
            recruiter,
            job: {
              id: job.id,
              title: job.title || '',

              ...getFormInitialValueFromParam(
                'profession',
                job.profession,
                dropdownsHashes.profession
              ),
              // Details

              ...getFormInitialValueFromParam(
                'employment_type',
                job.employment_type,
                dropdownsHashes.employment
              ),

              ...getFormInitialValueFromParam(
                'assignment',
                job.assignment,
                dropdownsHashes.assignment
              ),

              ...getFormInitialValueFromParam(
                'facility',
                job.facility,
                dropdownsHashes.facility
              ),

              ...getFormInitialValueFromParam(
                'specialty',
                job.specialty,
                dropdownsHashes.specialty
              ),

              ...getFormInitialValueFromParam('state', job.state, statesHash),
              //
              board_certification: hasBoardCert
                ? boardCertificationHash?.[job.board_certification]
                : '',
              min_hourly_rate: String(job.min_hourly_rate) || '',

              credentialing_time: hasCredentialingTime
                ? credentialingTimeHash?.[job.credentialing_time]
                : '',
              target_start_date: job.target_start_date || '',
              any_state_licenses: hasAnyStateLicenses
                ? anyStateLicensesHash?.[job.any_state_licenses]
                : '',
              // RECRUITERS ONLY //
              reference_number: job.reference_number || '',
              facility_name: job.facility_name || '',
              city: job.city || '',
              notes: job.notes || '',
              // Description and Requirements
              description: job.description || '',
              requirements: job.requirements || '',
            },
          },
          {}
        );
      }
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [job.id]);

  useEffect(() => {
    if (!isEditMode) {
      initialRequest();
    }
    return () => {};
  }, [isEditMode, initialRequest]);

  const isDone = status === 'SUCCESS';

  if (isEditMode) {
    return (
      <ModalLayout
        {...{
          ...modalProps,
          hasLogo: false,
          isLoading: isSubmitting,
          containerStyles: {
            padding: 24,
          },
        }}
      >
        <FormProvider {...methods}>
          {isDone && (
            <RecruitersDashboardTileModalContentEditMode
              {...{
                modalProps,
                jobByIdResponse,
                changeMode,
              }}
            />
          )}
        </FormProvider>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout
      {...{
        ...modalProps,
        hasLogo: false,
        isLoading: isSubmitting,
        containerStyles: {
          padding: 24,
        },
      }}
    >
      <FormProvider {...methods}>
        {isDone && (
          <RecruitersDashboardTileModalContentShowMode
            {...{
              modalProps,
              jobByIdResponse,
              changeMode,
            }}
          />
        )}
      </FormProvider>
    </ModalLayout>
  );
};

export default RecruitersDashboardTileModalContent;
