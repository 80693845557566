import { FC, memo } from 'react';
import { css } from '@emotion/react';

import NavLink from './NavLink';
import { NavLinkProps } from '@/types/layout';
import layout from '@/constants/layout';
const {
  windowSizes: { mobile },
} = layout;

const navLinkContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const mobileContainer = css`
  @media (max-width: ${mobile}px) {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const NavLinks: FC<{ navLinks?: NavLinkProps[] }> = (props) => {
  const navLinks = props.navLinks || [];
  const isMobileStyle = navLinks.length >= 4;

  return (
    <div
      {...{
        css: isMobileStyle ? mobileContainer : navLinkContainerStyle,
      }}
    >
      {!!props.navLinks &&
        props.navLinks.length > 0 &&
        props.navLinks.map((item, index) => {
          return <NavLink {...{ ...item, key: index }} />;
        })}
    </div>
  );
};

export default memo(NavLinks);
