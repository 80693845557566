import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import ProviderLocumsLog from '@/components/Dashboard/Clinician/ProviderLocumsLog';

export default function Search() {
  return (
    <>
      <TopContentFooterLayout>
        <ProviderLocumsLog />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
