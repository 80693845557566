import { ReactNode, useCallback, useEffect } from 'react';

import { useAppDispatch } from '@/storage/store';
import { fetchDropdownDataThunk } from '@/storage/dropdowns/dropdowns-actions';

const AppWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const initialRequest = useCallback(async () => {
    const response = await dispatch(fetchDropdownDataThunk()).unwrap();
  }, [dispatch]);

  useEffect(() => {
    initialRequest();
    return () => {};
  }, []);

  return <>{children}</>;
};

export default AppWrapper;
