import { NavBarProps } from '@/types/layout';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import { Head, router } from '@inertiajs/react';
import Heading from '@/components/Typography/Heading';
import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';
import layout from '@/constants/layout';
import imageCard from '../../../assets/images/Slide-1.svg';
import imageCard2 from '../../../assets/images/Slide-2.svg';
import imageCard3 from '../../../assets/images/Slide-3.svg';
import imageCard4 from '../../../assets/images/Slide-4.svg';
import InstructionsCard from '@/components/Card/InstructionsCard';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { mobile },
} = layout;



const navProps: NavBarProps = {
  navLinks: [
    { label: 'Design System', url: '/design_system' },
    { label: 'Home', url: '/' },
  ],
  buttons: [
    { children: 'Log in', variant: '1' },
    { children: 'Sign Up', variant: '2' },
  ],
};

const cardConent = [
  {
    image: imageCard,
    title: 'Home screen icon.',
    subTitle: 'Introducing Save Search',
    description: ' A new way to save and access your previous searches.',
  },
  {
    image: imageCard2,
    title: 'Job board search bar.',
    subTitle: 'Sign in and start searching.',
    description:
      'After you’re signed in, go to the search bar and start adding filters to your profession, location of interest, and specialty.',
  },
  {
    image: imageCard3,
    title: 'Search button.',
    subTitle: 'Save your new search.',
    description:
      ' Once you’ve made your specifications, click the “Save Search” button.',
  },
  {
    image: imageCard4,
    title: ' Save search modal.',
    subTitle: ' Label your search and get notifications.',
    description:
      '    You have the option to receive notifications about jobs that fall into this search.',
  },
];

export default function ProvidersInstructions() {
  return (
    <>
      <Head>
        <title>Saved Search - Instructions</title>
      </Head>
      <TopContentFooterLayout
        {...{
          topBar: <NavBar {...navProps} />,
          footer: <Footer {...navProps} />,
        }}
      >
        <div {...{ css: pageContentStyle }}>
          <div>
            <Heading
              {...{
                style: { color: '#315EFF', margin: '0' },
                variant: 'h1',
                children: 'Saved Search Guide',
                css: mobileFontStyle,
              }}
            />
          </div>

          <div {...{ css: cardContainer }}>
            {cardConent.slice(0, 2).map((card, index) => (
              <InstructionsCard
                key={index} 
                {...{
                  image: card.image,
                  title: card.title,
                  subTitle: card.subTitle,
                  description: card.description,
                }}
              />
            ))}
          </div>

          <div {...{ css: cardContainer }}>
            {cardConent.slice(2, 4).map((card, index) => (
              <InstructionsCard
                key={index + 2} 
                {...{
                  image: card.image,
                  title: card.title,
                  subTitle: card.subTitle,
                  description: card.description,
                }}
              />
            ))}
          </div>
        </div>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  gap: 30px;
`;

const mobileFontStyle = css`
  font-size: 55px;
  color: #fff;
  @media (max-width: ${mobile}px) {
    font-size: 30px;
  }
`;

const cardContainer = css`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 80px;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    margin: auto;
    gap: 40px;
  }
`;
