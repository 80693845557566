import { ColumnDef } from '@tanstack/react-table';
import CellRendererText from '@/components/Table/CellRendererText';
import { LocumsLog } from '@/types/entities.d';
import ColumnHeader from '@/components/Table/ColumnHeader';
import Checkbox from '@/components/Checkbox';
import ActionsCell from '@/components/Table/ActionsCell';
import ViewReferencesModalContent from '@/components/Dashboard/ViewReferencesModalContent';
import { SpecialtyCell } from './_index';

const columns: ColumnDef<LocumsLog>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Pitch Day' }} />,
    accessorKey: 'pitch_date',
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue() || '') }} />
    ),
  },
  {
    header: () => <ColumnHeader {...{ label: 'Agency' }} />,
    accessorKey: 'agency',
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue() || '') }} />
    ),
  },
  {
    header: () => <ColumnHeader {...{ label: 'Recruiter' }} />,
    accessorKey: 'recruiter',
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue() || '') }} />
    ),
  },
  {
    header: () => <ColumnHeader {...{ label: 'facility name' }} />,
    accessorKey: 'facility_name',
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue() || '') }} />
    ),
  },
  {
    header: () => <ColumnHeader {...{ label: 'job title' }} />,
    accessorKey: 'role_title',
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue() || '') }} />
    ),
  },
  {
    header: () => <ColumnHeader {...{ label: 'Specialty' }} />,
    accessorKey: 'specialty',
    cell: (info) => <SpecialtyCell {...{ value: info.getValue() as string }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'rate' }} />,
    accessorKey: 'rate',
    cell: (info) => {
      const value = info.getValue();
      return (
        <CellRendererText
          {...{ text: value && value !== 0 ? '$' + value : '' }}
        />
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'References' }} />,
    accessorKey: 'professional_reference_count',
    cell: (info) => {
      const count = info.getValue<number>();
      const log = info.row.original;
      return (
        <>
          <ViewReferencesModalContent {...{ count: String(count), log }} />
        </>
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Actions' }} />,
    accessorKey: 'actions',
    cell: ({ row }) => <ActionsCell {...{ row }} />,
  },
];
const providersCustomDatatableConfig = {
  columns,
};

export { providersCustomDatatableConfig };
