//React Functionality
import {
  ComponentProps,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { router, usePage, useForm as useInertiaForm } from '@inertiajs/react';

import {
  initialState,
  reducer,
} from '@/constants/contexts/datatable-providers-locums-log';
import { Controller, useForm } from 'react-hook-form';
import { datatableUtils } from '@/constants/datatables/_index';
import { getMetaToken } from '@/utils/meta-token';
import { providersCustomDatatableConfig } from '@/constants/datatables/provider-locums-log';
import { transformSortingValues } from '@/utils/data-table';
//Types
import { ProviderLocumsLogProps } from '@/types/page';
import { LocumsLog } from '@/types/entities.d';
import { useUrlSearchParams } from 'use-url-search-params';
import { LocumsLogFormValues } from '@/types/forms';
import { transformQueryParams } from '@/utils/query-params';
import { SortKey, SortType } from '@/types/types';
//Components
import NavButton from '../NavButton';
import PageHeader from '../PageHeader';
import layout from '@/constants/layout';
import { HEADER_TITLE } from '../_index';
import CustomDataTable from '@/components/Table/DataTable';
import CustomPaginator from '@/components/Paginator';
import NoResultsFound from '@/components/List/NoResultsFound';
import PostNewLocumsLogButton from '../PostNewLocumsLogButton';
import Modal from '@/components/Modal';
import DeleteProviderLocumsLogButton from '@/components/DeleteRows/DeleteProviderLocumsLog';
import LocumsLogTileModalContent from './TableListTitleModal/LocumsLogTileModalContent';
import SearchBar from '../SearchBar';
import List from '@/components/List';
import ProvidersDashboardListTile from '@/components/JobTiles/ProvidersDashboardListTile';
import SelectNavigation from './SelectNavigation';
//CSS
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  makeStyles,
} from '@mui/material';
import { INPUT_COMMONS, inputTtextStyles } from '@/components/Input';
import { appColors } from '@/constants/colors';
import DropdownIndicator from '@/components/InputDropdown/DropdownIndicator';
import { IconArrowDown } from '@/components/Icons/Arrow';
import SelectSearchBar from '../SelectSearchBar';
import Button from '@/components/Button';
import { IconInfo } from '@/components/Icons/Info';

const {
  windowSizes: { tablet, mobile, desktop, desktopHD },
} = layout;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Applied Jobs',
    onClick: () => {
      router.get(`/providers/applied_jobs`);
    },
  },
  {
    children: 'Saved Jobs',
    onClick: () => {
      router.get(`/providers/saved_jobs`);
    },
  },
  {
    children: 'Saved Search',
    onClick: () => {
      router.get(`/providers/saved_searches`);
    },
  },
  {
    children: 'Locums Log',
    onClick: () => {
      router.get(`/providers/locums_log`);
    },
  },
  {
    children: 'My Details',
    onClick: () => {
      router.get(
        `/providers/details`,
        {},
        {
          preserveState: false,
          preserveScroll: false,
        }
      );
    },
  },
];

const ProviderDashboardTable = CustomDataTable<LocumsLog>;
const ProviderDashboardList = List<LocumsLog>;
export default function ProviderLocumsLog() {
  const { get, transform } = useInertiaForm();

  //URL PARAMS
  const {
    props: { session, status, ...rest },
  } = usePage<ProviderLocumsLogProps>();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as LocumsLogFormValues;
  const parsedParams = transformQueryParams<LocumsLogFormValues>(urlParams);

  // INITAL STATES
  const initialRowSelection = {};
  const initialSorting = [
    {
      id: '',
      desc: true,
    },
  ];

  const initialFilterValues: LocumsLogFormValues = {
    q: {
      facility_name_cont: parsedParams?.q?.facility_name_cont || '',
      recruiter_cont: parsedParams?.q?.recruiter_cont || '',
      agency_cont: parsedParams?.q?.agency_cont || '',
      role_title_cont: parsedParams?.q?.role_title_cont || '',
      specialty_cont: parsedParams?.q?.specialty_cont || '',
    },
    recruiter: '',
    recruiter_phone: '',
    recruiter_email: '',
    facility_name: '',
    facility_city: '',
    facility_state: '',
    facility_phone: '',
    website: '',
    facility_contact: '',
    management_group: '',
    role_title: '',
    specialty: '',
    role_description: '',
    role_start_date: '',
    role_end_date: '',
    rate: '',
    role_notes: '',
    pitch_date: '',
    site_contact: '',
    site_decision: '',
    position_accepted: '',
    position_reason: '',
    per: 0,
    sort: '',
    page: 0,
    travel_included: '',
    lodging_included: '',
    permission_to_present: '',
    site_interview: '',
    site_date: '',
    agency: '',
  };
  //

  // LOCAL STATE
  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });

  // UI TABLE CONTROLS
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [sorting, setSorting] = useState(initialSorting);
  const [isTablet, setIsTablet] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedSearch, setSelectedSearch] = useState<string>('agency_cont');

  // FORM STATE
  const methods = useForm<LocumsLogFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  // CONSTANTS
  const { isTileModalOpen, clickedTile } = modal;
  const { control, handleSubmit, setValue, watch } = methods;
  const { collection, pagination, sort } = rest;
  const rowSelected = Object.keys(rowSelection).map((id) => {
    return collection[parseInt(id)];
  });

  const isEmpty = collection.length === 0;

  const triggerSearch = useCallback(async (form: LocumsLogFormValues) => {
    const hasSort = !!form?.sort;
    const isPageDiffOne = !!form?.page && form.page > 1;
    const isPerDiffTen = !!form?.per && form.per !== 10;

    const params = {
      q: {
        ...(form.q.facility_name_cont
          ? { facility_name_cont: form.q.facility_name_cont }
          : {}),
        ...(form.q.recruiter_cont
          ? { recruiter_cont: form.q.recruiter_cont }
          : {}),
        ...(form.q.agency_cont ? { agency_cont: form.q.agency_cont } : {}),
        ...(form.q.role_title_cont
          ? { role_title_cont: form.q.role_title_cont }
          : {}),
        ...(form.q.specialty_cont
          ? { specialty_cont: form.q.specialty_cont }
          : {}),
      },
      ...(hasSort ? { sort: form.sort } : {}),
      ...(isPerDiffTen ? { per: form.per } : {}),
      ...(isPageDiffOne ? { page: form.page } : {}),
    };
    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      get('/providers/locums_log', {
        preserveState: true,
        //@ts-ignore
        data: transform(() => ({
          ...params,
        })),
        headers: {
          ...metaTokenHeader,
        },
      });
    }
  }, []);

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, []);

  const onCloseTileModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const resetRows = useCallback(() => {
    setRowSelection(initialRowSelection);
  }, [initialRowSelection]);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  const onOpenTileModal = useCallback(
    (log: LocumsLog) => {
      localDispatch({ type: 'OPEN_TILE_MODAL', payload: log });
    },
    [localDispatch]
  );

  const onRowClicked = useCallback((log: LocumsLog) => {
    onOpenTileModal(log);
  }, []);

  // EFFECT FOR SORTING
  useEffect(() => {
    const sortValue = transformSortingValues(sorting) as SortKey<LocumsLog>;
    setValue('sort', sortValue);
    changePage(1);
    return () => {};
  }, [sorting]);

  const EmptyComponent = () => {
    return (
      <div
        {...{
          css: buttonsContainer,
        }}
      >
        <Button
          {...{
            onClick: () => {
              router.get(`/locum_log_instructions`);
            },
            children: 'View Full Guide',
            icon: IconInfo,
          }}
        />
        <PostNewLocumsLogButton />
      </div>
    );
  };

  //Effect for modal in mobile
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsTablet(width <= tablet && width > 0);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tablet]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const selectedUrl = event.target.value as string;
    setSelectedOption(selectedUrl);
    if (selectedUrl) {
      window.location.href = selectedUrl;
    }
  };

  const handleSearchChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as string;
    if (selectedValue !== selectedSearch) {
      setSelectedSearch(selectedValue);
      setValue('q.agency_cont', '');
    }
  };

  const options = [
    { value: 'agency_cont', label: 'Agency' },
    { value: 'facility_name_cont', label: 'Facility Name' },
    { value: 'role_title_cont', label: 'Job Title' },
    { value: 'recruiter_cont', label: 'Recruiter' },
    { value: 'specialty_cont', label: 'Specialty' },
  ];

  return (
    <div
      {...{
        css: providerDashboardContainer,
      }}
    >
      <HeaderContainer>
        <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
        <NavHeaderContainer>
          <NavHeaderButtonsContainer>
            {navButtons.map((navButton, index) => {
              const isActive = index === 3;
              return <NavButton {...{ ...navButton, isActive, key: index }} />;
            })}
          </NavHeaderButtonsContainer>
          {/* Mobile Navigation */}
          <MobileSelectContainer>
            <SelectNavigation
              {...{
                value: selectedOption,
                onChange: handleSelectChange,
              }}
            />
          </MobileSelectContainer>
        </NavHeaderContainer>
        {!isEmpty ? (
          <SearchHeaderContainer>
            <SelectSearchBar
              {...{
                control,
                SelectSearchField: selectedSearch,
                selectedSearch: `q.${selectedSearch}`,
                options: options,
                placeholder: `Search by ${selectedSearch
                  .replace('_cont', '')
                  .replace('_', ' ')}`,
                onChange: handleSearchChange,
                searchIconAction: handleSubmit(submitSearch),
              }}
            />
            <div
              {...{
                css: buttonsContainer,
              }}
            >
              <div
                {...{
                  css: css`
                    display: flex;
                    @media (max-width: ${tablet}px) {
                      display: none;
                    }
                  `,
                }}
              >
                <DeleteProviderLocumsLogButton
                  {...{
                    resetRows,
                    rowSelectedIds: rowSelected.map(({ id }) => id),
                  }}
                />
              </div>
              <Button
                {...{
                  onClick: () => {
                    router.get(`/locum_log_instructions`);
                  },
                  children: 'View Full Guide',
                  icon: IconInfo,
                }}
              />

              <PostNewLocumsLogButton />
            </div>
          </SearchHeaderContainer>
        ) : null}
      </HeaderContainer>

      {isEmpty ? (
        <NoResultsFound
          {...{
            header: datatableUtils.EMPTY_HEADER,
            node: EmptyComponent,
          }}
        />
      ) : (
        <>
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;
                overflow: auto;
                @media (max-width: ${tablet}px) {
                  display: none;
                }
              `,
            }}
          >
            <ProviderDashboardTable
              {...{
                data: collection,
                sorting,
                rowSelection,
                onSortingChange: setSorting,
                onRowSelectionChange: setRowSelection,
                columns: providersCustomDatatableConfig.columns,
              }}
            />
            <CustomPaginator
              {...{
                pagination,
                changePage,
              }}
            />
          </div>
          <div
            {...{
              css: css`
                display: none;
                @media (max-width: ${tablet}px) {
                  display: flex;
                  width: 100%;
                }
              `,
            }}
          >
            <ProviderDashboardList
              {...{
                data: collection,
                pagination: pagination,
                sort: (sort as SortType) || undefined,
                ListEmptyComponent: EmptyComponent,
                changePage,
                RenderedItem: (props) => (
                  <ProvidersDashboardListTile
                    {...{ ...props, onClick: () => onRowClicked(props.item) }}
                  />
                ),
              }}
            />
          </div>
          {isTileModalOpen && isTablet && (
            <Modal {...{ position: 'top', onClose: onCloseTileModal }}>
              {!!clickedTile && (
                <LocumsLogTileModalContent
                  {...{
                    modalProps: { closeModal: onCloseTileModal },
                    log: clickedTile,
                  }}
                />
              )}
            </Modal>
          )}
        </>
      )}
    </div>
  );
}

const providerDashboardContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding: 0 100px;
  @media (max-width: ${desktopHD}px) {
    padding: 0 10%;
  }
  @media (max-width: ${desktop}px) {
    padding: 0 10%;
  }
  @media (max-width: ${tablet}px) {
    padding: 0 5%;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 16px;
    gap: 20px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const NavHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
  @media (max-width: ${mobile}px) {
    display: none;
  }
`;

const SearchHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mobile}px) {
    flex-direction: column-reverse;
    gap: 24px;
    align-items: flex-start;
  }
`;
const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: ${mobile}px) {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }
`;

const MobileSelectContainer = styled.div`
  display: none;
  @media (max-width: ${mobile}px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    padding: 0 8px;
  }
`;
const BORDER_WIDTH = 1;
const BORDER_RADIUS = 100;

const StyledSelect = styled(Select)`
  background-color: white;
  width: 190px;
  border-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
  box-shadow: 0px 10px 30px 0px rgba(49, 94, 255, 0.08);
  border: ${BORDER_WIDTH}px solid ${appColors.border.primary};
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiOutlinedInput-input:hover {
    border-color: transparent;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    transition: none;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiSelect-icon {
    right: 8px;
    top: calc(50% - 0.6em);
  }
  & .MuiSelect-select {
    margin: 0 0 0 8px;
  }
`;
