import { SVGProps, useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconInfo = ({ iconColor = appColors.content.primary, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={5.75}
          y={5}
          width={14}
          height={14}
          rx={7}
          stroke={iconColor}
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M12.75 11v5"
          stroke={iconColor}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x={12}
          y={8}
          width={1.5}
          height={1.5}
          rx={0.75}
          fill={iconColor}
        />
      </svg>
    ),
    [props]
  );

const IconInfoFilled = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={5}
          y={5}
          width={14}
          height={14}
          rx={7}
          fill={'#05051B' || appColors.content.primary}
          stroke={'#05051B' || appColors.content.primary}
        />
        <path
          d="M12 11v5"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={11.625}
          y={8.375}
          width={0.75}
          height={0.75}
          rx={0.375}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.75}
        />
      </svg>
    ),
    [props]
  );

export { IconInfo, IconInfoFilled };
