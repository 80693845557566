//React Functionality
import { FC, useCallback, useMemo, useState } from 'react';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { getMetaToken } from '@/utils/meta-token';
import { datatableUtils } from '@/constants/datatables/_index';
//Components
import Modal from '../Modal';
import Button from '../Button';
import { PromptMessage } from '../ModalPrompt';
import FakeDeleteButton from '../FakeDeleteButton';
import QuestionPrompt from '../ModalPrompt/QuestionPrompt';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
//CSS
import { IconTrash } from '../Icons/Trash';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

const URL = '/providers/locums_log/log_bulk_destroy';
interface DeleteRowsButtonProps {
  rowSelectedIds: number[];
  deleteButtonLabel?: string;
  hasIcon?: boolean;
  resetRows: () => void;
}

interface PromptProps {
  rowSelectedIds: number[];
  resetRows: () => void;
  prompt: {
    close: () => void;
    open: () => void;
  };
}

const DeleteProviderLocumsLogButton: FC<DeleteRowsButtonProps> = ({
  deleteButtonLabel,
  rowSelectedIds,
  hasIcon = true,
  resetRows,
}) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const isDisabled = rowSelectedIds.length < 1;

  const closePrompt = useCallback(() => {
    setIsPromptOpen(false);
  }, []);

  const openPrompt = useCallback(() => {
    setIsPromptOpen(true);
  }, []);

  if (isDisabled) {
    return (
      <FakeDeleteButton
        {...{
          buttonProps: {
            disabled: true,
          },
          hasIcon: true,
        }}
      />
    );
  }

  return (
    <>
      <Button
        {...{
          variant: '5',
          onClick: openPrompt,
          children: deleteButtonLabel || 'Delete',
          disabled: rowSelectedIds.length < 1,
          ...(hasIcon ? { icon: IconTrash } : {}),
        }}
      />
      {isPromptOpen && (
        <Modal
          {...{
            position: 'center',
            isAnimated: true,
            onClose: closePrompt,
          }}
        >
          <Prompt
            {...{
              rowSelectedIds,
              resetRows,
              prompt: {
                close: closePrompt,
                open: openPrompt,
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default DeleteProviderLocumsLogButton;

const Prompt: FC<PromptProps> = ({ rowSelectedIds, prompt, resetRows }) => {
  const { patch } = useInertiaForm<{ ids: number[] }>({ ids: rowSelectedIds });

  const onDelete = async () => {
    const rowSelectedIdsSize = rowSelectedIds.length;
    const isMoreThanOne = rowSelectedIdsSize > 1;
    const id = `Search${isMoreThanOne ? 'es' : ''}-delete-${rowSelectedIds.join(
      '/'
    )}`;

    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      patch(URL, {
        preserveState: true,
        headers: {
          ...metaTokenHeader,
        },
        onProgress: () => {},
        onError: () => {
          defaultToastError(id);
        },
        onSuccess: () => {
          const toastSuccessMessage = `${
            isMoreThanOne
              ? datatableUtils.log.DELETED_PLURAL
              : datatableUtils.log.DELETED_PLURAL
          }`;
          defaultToastSuccess(id, toastSuccessMessage);
          !!prompt?.close && prompt.close();
          !!resetRows && resetRows();
        },
      });
    }
  };

  const getDeleteModalMessages: EmotionJSX.Element[] = useMemo(() => {
    const qty = rowSelectedIds.length;
    if (qty > 1) {
      return [
        <PromptMessage>
          {datatableUtils.TO_DELETE_MSG}
          <PromptMessage
            {...{
              children: qty,
              style: {
                fontWeight: 700,
              },
            }}
          />
          {datatableUtils.log.SEARCH}
        </PromptMessage>,
        <PromptMessage {...{ children: datatableUtils.log.DELETE_MSG }} />,
      ];
    }
    return [<PromptMessage {...{ children: datatableUtils.log.DELETE_MSG }} />];
  }, [rowSelectedIds]);

  return (
    <QuestionPrompt
      {...{
        headerProps: {
          children: 'Are you sure?',
        },
        msgProps: getDeleteModalMessages,
        buttonsProps: {
          leftButton: {
            variant: '2',
            children: 'Cancel',
            onClick: async (e) => {
              e?.preventDefault();
              !!prompt?.close && prompt.close();
            },
          },
          rightButton: {
            variant: '5',
            children: 'Yes, delete',
            onClick: async (e) => {
              e?.preventDefault();
              onDelete();
            },
          },
        },
      }}
    />
  );
};
