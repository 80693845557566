import { ComponentProps, useState } from 'react';
import styled from '@emotion/styled';
import { router, usePage } from '@inertiajs/react';

import NavButton from '../NavButton';
import PageHeader from '../PageHeader';
import layout from '@/constants/layout';
import { ProviderMyDetailsProps } from '@/types/page';
import AccountDetails from '../MyDetails/AccountDetails';
import ApplicationDetails from '../MyDetails/ApplicationDetails';
import { HEADER_TITLE, providerDashboardContainer } from '../_index';
import { SelectChangeEvent } from '@mui/material';
import SelectNavigation from './SelectNavigation';

const {
  windowSizes: { tablet, mobile, desktop, desktopHD },
} = layout;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 0 23%;
  @media (max-width: ${desktopHD}px) {
    padding: 0 21%;
  }
  @media (max-width: ${desktop}px) {
    padding: 0 12.5%;
  }
  @media (max-width: ${tablet}px) {
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const NavHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
  @media (max-width: ${mobile}px) {
    display: none;
  }
`;

const MobileSelectContainer = styled.div`
  display: none;
  @media (max-width: ${mobile}px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Applied Jobs',
    onClick: () => {
      router.get(`/providers/applied_jobs`);
    },
  },
  {
    children: 'Saved Jobs',
    onClick: () => {
      router.get(`/providers/saved_jobs`);
    },
  },
  {
    children: 'Saved Search',
    onClick: () => {
      router.get(`/providers/saved_searches`);
    },
  },
  {
    children: 'Locums Log',
    onClick: () => {
      router.get(`/providers/locums_log`);
    },
  },
  {
    children: 'My Details',
    onClick: () => {},
  },
];

function ProvidersMyDetails() {
  // URL PARAMS
  const [selectedOption, setSelectedOption] = useState('');
  const page = usePage<ProviderMyDetailsProps>();
  const {
    props: {
      session: { current_user },
      resume,
      profession_detail,
    },
  } = page;

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const selectedUrl = event.target.value as string;
    setSelectedOption(selectedUrl);
    if (selectedUrl) {
      window.location.href = selectedUrl;
    }
  };

  return (
    <div
      {...{
        css: providerDashboardContainer,
      }}
    >
      <HeaderContainer>
        <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
        <NavHeaderContainer>
          <NavHeaderButtonsContainer>
            {navButtons.map((navButton, index) => {
              const isActive = index === 4;
              return <NavButton {...{ ...navButton, isActive, key: index }} />;
            })}
          </NavHeaderButtonsContainer>
          {/* Mobile Navigation */}
          <MobileSelectContainer>
            <SelectNavigation
              {...{
                value: selectedOption,
                onChange: handleSelectChange,
              }}
            />
          </MobileSelectContainer>
        </NavHeaderContainer>
      </HeaderContainer>
      <DetailsContainer>
        {!!current_user && (
          <AccountDetails
            {...{
              details: {
                user_id: current_user?.id || -1,
                is_type: 'provider',
                first_name: current_user?.first_name || '',
                last_name: current_user?.last_name || '',
                email: current_user?.email || '',
              },
            }}
          />
        )}
        {!!current_user && (
          <ApplicationDetails
            {...{
              details: {
                provider: current_user,
                resume,
                profession_detail,
              },
            }}
          />
        )}
      </DetailsContainer>
    </div>
  );
}

export default ProvidersMyDetails;
