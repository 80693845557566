import styled from '@emotion/styled';
import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  KeyboardEvent,
  CSSProperties,
} from 'react';

import Button from '../Button';
import layout from '@/constants/layout';
import { inputTtextStyles } from '../Input';
import { IconSearch } from '../Icons/Search';
import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';

const BORDER_WIDTH = 1;
const BORDER_RADIUS = 100;

const {
  windowSizes: { mobile },
} = layout;

const Container = styled.div<{ unsetLeftRadius?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 8px 8px 8px 24px;
  background-color: white;
  width: 280px;
  border-radius: ${BORDER_RADIUS}px;
  gap: 4px;
  box-shadow: 0px 10px 30px 0px rgba(49, 94, 255, 0.08);
  border: ${BORDER_WIDTH}px solid ${appColors.border.primary};
  ${({ unsetLeftRadius }) =>
    unsetLeftRadius &&
    `
      border-bottom-left-radius: unset;
      border-top-left-radius: unset;
    `}
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  color: inherit;
  appearance: none;
  width: 100%;
  flex-shrink: 1;
  ${inputTtextStyles}
`;

const buttonContainer = css`
  margin: auto;
`;

interface SearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  searchIconAction?: () => void;
  containerStyles?: CSSProperties;
  unsetLeftRadius?: boolean;
}

const SearchBar: ForwardRefRenderFunction<HTMLInputElement, SearchBarProps> = (
  props,
  ref
) => {
  const { unsetLeftRadius } = props;
  const onClickSearchIcon = useCallback(() => {
    !!props?.searchIconAction && props.searchIconAction();
  }, [props.searchIconAction]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onClickSearchIcon();
      }
    },
    [onClickSearchIcon]
  );

  return (
    <Container {...{ style: { ...props?.containerStyles }, unsetLeftRadius }}>
      <StyledInput {...{ ...props, onKeyDown: handleKeyDown, ref }} />
      <div {...{ css: buttonContainer }}>
        <Button
          {...{
            type: 'submit',
            style: {
              width: 30,
              height: 30,
              padding: 0,
              borderRadius: 100,
            },
            icon: IconSearch,
            onClick: onClickSearchIcon,
          }}
        />
      </div>
    </Container>
  );
};

export default forwardRef(SearchBar);
