//React Functionality
import { memo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
//Components
import { FieldsContainer, FormContainer, RowContainer } from '../';
import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import Checkbox from '@/components/Checkbox';
import RecruitersOnlyDetailsCard from '@/components/Dashboard/RecruitersOnlyDetailsCard';
import Paragraph from '@/components/Typography/Paragraph';
//Types
import { ProfessionalReferencesFormValues } from '@/types/forms';

const LocumsLogFlowReferencesForm = ({
  methods,
  oneReference,
}: {
  methods: UseFormReturn<ProfessionalReferencesFormValues, any>;
  oneReference?: boolean;
}) => {
  const { control } = methods;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdditionalFields(event.target.checked);
  };

  if (oneReference) {
    return (
      <form>
        <FormContainer>
          <RecruitersOnlyDetailsCard
            {...{
              content: (
                <FieldsContainer>
                  <RowContainer>
                    {
                      //@ts-ignore
                      <Controller
                        {...{
                          name: 'first_name',
                          control,
                          render: ({ field, fieldState: { error } }) => {
                            return (
                              <Input
                                {...{
                                  ...field,
                                  ...fieldsDefaultProps['first_name'],
                                  placeholder: 'Enter First Name',
                                  id: field.name,
                                  error,
                                }}
                              />
                            );
                          },
                        }}
                      />
                    }
                    {
                      //@ts-ignore
                      <Controller
                        {...{
                          name: 'last_name',
                          control,
                          render: ({ field, fieldState: { error } }) => {
                            return (
                              <Input
                                {...{
                                  ...field,
                                  ...fieldsDefaultProps['last_name'],
                                  placeholder: 'Enter Last Name',
                                  id: field.name,
                                  error,
                                }}
                              />
                            );
                          },
                        }}
                      />
                    }
                  </RowContainer>
                  <RowContainer>
                    {
                      //@ts-ignore
                      <Controller
                        {...{
                          name: 'phone',
                          control,
                          rules: { ...fieldsDefaultRules['phone'] },
                          render: ({ field, fieldState: { error } }) => {
                            return (
                              <InputMask
                                {...{
                                  ...fieldsDefaultProps['phone'],
                                  label: 'Phone #',
                                  placeholder: 'Enter Phone #',
                                  ...field,
                                  mask: masks['phone'],
                                  id: field.name,
                                  error,
                                }}
                              />
                            );
                          },
                        }}
                      />
                    }
                    {
                      //@ts-ignore
                      <Controller
                        {...{
                          name: 'email',
                          control,
                          render: ({ field, fieldState: { error } }) => {
                            return (
                              <Input
                                {...{
                                  ...field,
                                  label: 'Email',
                                  placeholder: 'Enter Email',
                                  id: field.name,
                                  error,
                                }}
                              />
                            );
                          },
                        }}
                      />
                    }
                  </RowContainer>
                  <RowContainer>
                    {
                      //@ts-ignore
                      <Controller
                        {...{
                          name: 'company',
                          control,
                          render: ({ field, fieldState: { error } }) => {
                            return (
                              <Input
                                {...{
                                  ...field,
                                  label: 'Company Name',
                                  placeholder: 'Enter Company Name',
                                  id: field.name,
                                  error,
                                }}
                              />
                            );
                          },
                        }}
                      />
                    }
                  </RowContainer>
                </FieldsContainer>
              ),
            }}
          />
        </FormContainer>
      </form>
    );
  }
  return (
    <form>
      <FormContainer>
        <RecruitersOnlyDetailsCard
          {...{
            header: (
              <Paragraph
                {...{
                  children: 'Reference 1',
                  variant: 'md',
                  style: {
                    fontWeight: 500,
                  },
                }}
              />
            ),
            content: (
              <FieldsContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'first_name',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                ...fieldsDefaultProps['first_name'],
                                placeholder: 'Enter First Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'last_name',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                ...fieldsDefaultProps['last_name'],
                                placeholder: 'Enter Last Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'phone',
                        control,
                        rules: { ...fieldsDefaultRules['phone'] },
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <InputMask
                              {...{
                                ...fieldsDefaultProps['phone'],
                                label: 'Phone #',
                                placeholder: 'Enter Phone #',
                                ...field,
                                mask: masks['phone'],
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'email',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Email',
                                placeholder: 'Enter Email',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'company',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Company Name',
                                placeholder: 'Enter Company Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
              </FieldsContainer>
            ),
          }}
        />

        <div>
          <Checkbox
            {...{
              label: 'More References',
              checked: showAdditionalFields,
              onChange: handleCheckboxChange,
            }}
          />
        </div>

        {showAdditionalFields && (
          <>
            <RecruitersOnlyDetailsCard
              {...{
                header: (
                  <Paragraph
                    {...{
                      children: 'Reference 2',
                      variant: 'md',
                      style: {
                        fontWeight: 500,
                      },
                    }}
                  />
                ),
                content: (
                  <FieldsContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'first_name_1',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    ...fieldsDefaultProps['first_name'],
                                    placeholder: 'Enter First Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'last_name_1',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    ...fieldsDefaultProps['last_name'],
                                    placeholder: 'Enter Last Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'phone_1',
                            control,
                            rules: { ...fieldsDefaultRules['phone'] },
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <InputMask
                                  {...{
                                    ...fieldsDefaultProps['phone'],
                                    label: 'Phone #',
                                    placeholder: 'Enter Phone #',
                                    ...field,
                                    mask: masks['phone'],
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'email_1',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    label: 'Email',
                                    placeholder: 'Enter Email',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'company_1',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    label: 'Company Name',
                                    placeholder: 'Enter Company Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                  </FieldsContainer>
                ),
              }}
            />
            <RecruitersOnlyDetailsCard
              {...{
                header: (
                  <Paragraph
                    {...{
                      children: 'Reference 3',
                      variant: 'md',
                      style: {
                        fontWeight: 500,
                      },
                    }}
                  />
                ),
                content: (
                  <FieldsContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'first_name_2',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    ...fieldsDefaultProps['first_name'],
                                    placeholder: 'Enter First Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'last_name_2',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    ...fieldsDefaultProps['last_name'],
                                    placeholder: 'Enter Last Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'phone_2',
                            control,
                            rules: { ...fieldsDefaultRules['phone'] },
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <InputMask
                                  {...{
                                    ...fieldsDefaultProps['phone'],
                                    label: 'Phone #',
                                    placeholder: 'Enter Phone #',
                                    ...field,
                                    mask: masks['phone'],
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'email_2',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    label: 'Email',
                                    placeholder: 'Enter Email',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                    <RowContainer>
                      {
                        //@ts-ignore
                        <Controller
                          {...{
                            name: 'company_2',
                            control,
                            render: ({ field, fieldState: { error } }) => {
                              return (
                                <Input
                                  {...{
                                    ...field,
                                    label: 'Company Name',
                                    placeholder: 'Enter Company Name',
                                    id: field.name,
                                    error,
                                  }}
                                />
                              );
                            },
                          }}
                        />
                      }
                    </RowContainer>
                  </FieldsContainer>
                ),
              }}
            />
          </>
        )}
      </FormContainer>
    </form>
  );
};

export default memo(LocumsLogFlowReferencesForm);
