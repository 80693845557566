import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import TopSpecialties from '../../../assets/images/top-specialties.png';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  margin-top: 0;
  li::marker {
    font-weight: bold;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 15px;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>
          Top Specialties and States Providers Are Searching for on Locums.com'
        </title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeTwo: {
              topImageSrc: TopSpecialties,
              topImageAlt: 'Locums.com infographic',
              specilatyArticle: true,
              bottomImageTitle:
                'Top Specialties and States Providers Are Searching for on Locums.com',
              bottomSectionText: (
                <>
                  As the healthcare industry increasingly relies on locum tenens
                  providers to meet growing patient demands, certain specialties
                  are seeing higher demand than ever. Over the past month on
                  Locums.com, five specialties stood out as the most searched by
                  providers. Here’s a closer look at the top specialties and the
                  states where providers are actively seeking opportunities. rs,
                  so read below at the top things you’ll need to know.
                  <br />
                  <ol {...{ css: orderList }}>
                    <li>
                      <b>Anesthesiology</b>
                      <br />
                      Top States Searching: Florida (FL), Illinois (IL),
                      California (CA) Anesthesiology leads the pack, reflecting
                      the continuous need for surgical and procedural support
                      across the nation. Florida and California, with their
                      large, aging populations, have significant surgical
                      caseloads, while Illinois mirrors national trends with a
                      rising demand for specialized procedures.
                      <br />
                      <b>Industry Insight:</b> According to the American Society
                      of Anesthesiologists, workforce shortages have increased
                      reliance on locum tenens anesthesiologists to fill gaps
                      during peak times and staff transitions.
                    </li>
                    <br />
                    <li>
                      <b>Psychiatry</b>
                      <br />
                      Top States Searching: Florida (FL), Illinois (IL), South
                      Carolina (SC) Mental health services remain critical, and
                      searches for psychiatry roles continue to rise. Florida’s
                      population growth, combined with increased mental health
                      awareness, drives its demand. Similarly, Illinois and
                      South Carolina are addressing shortages in rural and
                      underserved areas. <br />
                      <b>Industry Insight:</b> The National Alliance on Mental
                      Illness (NAMI) reports a steady increase in mental health
                      diagnoses, highlighting the ongoing need for psychiatric
                      services.
                    </li>
                    <br />
                    <li>
                      <b>Family Practice </b>
                      <br />
                      Top States Searching: Arizona (AZ), California (CA),
                      Florida (FL) Family practice is the backbone of primary
                      care, and searches for these roles are strong in states
                      with growing populations. Arizona, for example, has seen
                      rapid expansion in suburban areas, while California and
                      Florida continue to attract retirees requiring regular
                      care. <br />
                      <b>Industry Insight:</b> The AAMC predicts a shortage of
                      up to 48,000 primary care physicians by 2034, further
                      emphasizing the value of locum tenens in this specialty.
                    </li>
                    <br />
                    <li>
                      <b>Emergency Medicine</b>
                      <br />
                      Top States Searching: Pennsylvania (PA), South Carolina
                      (SC), Texas (TX) Emergency medicine remains a high-demand
                      specialty, especially in states like Texas, where rural
                      hospitals often rely on locum providers. Pennsylvania and
                      South Carolina also see seasonal increases in ER visits,
                      necessitating flexible staffing solutions. <br />
                      <b>Industry Insight:</b> Emergency departments are
                      experiencing higher patient volumes post-pandemic,
                      underscoring the need for locum tenens providers to
                      prevent burnout among permanent staff.
                    </li>
                    <br />
                    <li>
                      <b>Hospitalist</b>
                      <br />
                      Top States Searching: Florida (FL), Pennsylvania (PA),
                      Texas (TX) Hospitalists play a key role in inpatient care,
                      and states like Florida and Texas are seeing consistent
                      demand due to large hospital networks and increasing
                      patient admissions. Pennsylvania’s focus on rural
                      healthcare access further drives hospitalist searches.
                      <br />
                      <b>Industry Insight:</b> Data from the Society of Hospital
                      Medicine suggests that hospitalist programs have grown
                      steadily, reflecting an ongoing shift toward team-based
                      inpatient care.
                    </li>
                  </ol>
                  <b> What Does This Mean for Providers and Recruiters? </b>
                  <br />
                  If you’re a locum provider in one of these specialties, now is
                  the perfect time to explore opportunities in high-demand
                  states. For recruiters, understanding these trends can help
                  target efforts to fill urgent openings. With locum tenens
                  continuing to offer flexible, lucrative career options, both
                  providers and recruiters stand to benefit from tapping into
                  this evolving market.
                  <br />
                  <br />
                  <b>Ready to Find or Post a Job?</b>
                  <br /> Locums.com is your go-to resource for finding or
                  posting locum tenens jobs. Whether you’re looking to fill an
                  urgent opening or secure your next assignment, we’ve got you
                  covered.{' '}
                  <a href="https://www.locums.com/jobs">
                    Visit our jobs page today to get started!
                  </a>
                </>
              ),
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
