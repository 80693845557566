import { NavBarProps } from '@/types/layout';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import { Head, router } from '@inertiajs/react';
import Heading from '@/components/Typography/Heading';
import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';
import layout from '@/constants/layout';
import imageCard from '../../../assets/images/locum-log1.jpg';
import imageCard2 from '../../../assets/images/locum-log2.jpg';
import imageCard3 from '../../../assets/images/locum-log3.jpg';
import imageCard4 from '../../../assets/images/locum-log4.jpg';
import imageCard5 from '../../../assets/images/locum-log5.jpg';
import imageCard6 from '../../../assets/images/locum-log6.jpg';
import imageCard7 from '../../../assets/images/locum-log7.jpg';
import imageCard8 from '../../../assets/images/locum-log8.jpg';
import imageCard9 from '../../../assets/images/locum-log9.jpg';
import InstructionsCard from '@/components/Card/InstructionsCard';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { mobile, tablet },
} = layout;

const navProps: NavBarProps = {
  navLinks: [
    { label: 'Design System', url: '/design_system' },
    { label: 'Home', url: '/' },
  ],
  buttons: [
    { children: 'Log in', variant: '1' },
    { children: 'Sign Up', variant: '2' },
  ],
};

const cardContent = [
  {
    image: imageCard,
    title: 'Locum Log logo.',
    subTitle: (
      <>
        Introducing
        <br /> Locum Log tool
      </>
    ),
    description: ' Discover the powerful features of the Locums Log tool.',
  },
  {
    image: imageCard2,
    title: 'Mobile Navigation menu.',
    subTitle: 'Sign In and Access Your Dashboard',
    description:
      'Log in to your account and navigate to your personalized dashboard.',
  },
  {
    image: imageCard3,
    title: 'Mobile new log button',
    subTitle: (
      <>
        Add a New Locum
        <br /> Job Log
      </>
    ),
    description:
      'Create a new locum log to track job details and opportunities.',
  },
  {
    image: imageCard4,
    title: ' Locum Log modal.',
    subTitle: ' Enter Locum Job Information Details',
    description:
      'Fill out all the relevant information for your locum job quickly.',
  },
  {
    image: imageCard5,
    title: 'Mobile save button.',
    subTitle: (
      <>
        Save the Locum
        <br />
        Job Log
      </>
    ),
    description:
      'Save your locum log to ensure all details are securely stored.',
  },
  {
    image: imageCard6,
    title: 'Mobile Card info locum log.',
    subTitle: "View Your Locum Log in the Dashboard's Locum Log Tab",
    description:
      'Find and access your saved locum logs in the designated dashboard tab.',
  },
  {
    image: imageCard7,
    title: 'Mobile Navigation tab menu.',
    subTitle: 'View or Edit Locum Log Details Across All Tabs',
    description:
      'Check or update your locum log details across multiple log tabs.',
  },
  {
    image: imageCard8,
    title: ' Mobile Add references button.',
    subTitle: 'Add or Edit Professional References',
    description:
      'Easily manage your professional references for your locum job.',
  },
  {
    image: imageCard9,
    title: ' delete locum log button.',
    subTitle: 'Permanently delete a Locum Job Log',
    description:
      'Remove a locum log from your dashboard when it’s no longer needed.',
  },
];

export default function ProvidersInstructions() {
  return (
    <>
      <Head>
        <title>Locum Log - Instructions</title>
      </Head>
      <TopContentFooterLayout
        {...{
          topBar: <NavBar {...navProps} />,
          footer: <Footer {...navProps} />,
        }}
      >
        <div {...{ css: pageContentStyle }}>
          <div>
            <Heading
              {...{
                style: { color: '#315EFF', margin: '0' },
                variant: 'h1',
                children: 'Locum Log Guide',
                css: mobileFontStyle,
              }}
            />
          </div>

          <div {...{ css: cardContainer }}>
            {cardContent.map((card, index) => (
              <InstructionsCard
                key={index}
                {...{
                  image: card.image,
                  title: card.title,
                  subTitle: card.subTitle,
                  description: card.description,
                  isLocumLog: true,
                }}
              />
            ))}
          </div>
        </div>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  gap: 30px;
`;

const mobileFontStyle = css`
  font-size: 55px;
  color: #fff;
  @media (max-width: ${mobile}px) {
    font-size: 30px;
  }
`;

const cardContainer = css`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

`;
