import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import TopStates from '../../../assets/images/top-states.png';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  margin-top: 0;
  li::marker {
    font-weight: bold;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 15px;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>
          Top States with the Most New Job Opportunities on Locums.com
        </title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeTwo: {
              topImageSrc: TopStates,
              topImageAlt: 'Locums.com infographic',
              specilatyArticle: true,
              bottomImageTitle:
                'Top States with the Most New Job Opportunities on Locums.com',
              bottomSectionText: (
                <>
                  {' '}
                  Looking for your next locum tenens assignment? The hottest
                  states for new jobs right now are:
                  <br />
                  <ol {...{ css: orderList }}>
                    <li>
                      <b>Pennsylvania (PA)</b>
                      <br />
                      Pennsylvania tops the list with a high volume of openings
                      in emergency medicine, hospitalist, and psychiatry roles.
                      The state’s mix of urban centers and rural regions creates
                      diverse opportunities for providers.
                      <br />
                      <b>Industry Insight:</b> Pennsylvania has seen a 15%
                      increase in healthcare job postings as facilities work to
                      keep up with patient demand and seasonal fluctuations.
                    </li>
                    <br />
                    <li>
                      <b>New York (NY)</b>
                      <br />
                      New York’s bustling healthcare environment offers
                      positions across specialties such as family practice,
                      psychiatry, and anesthesiology. Providers can find
                      opportunities in both urban hospitals and rural clinics.{' '}
                      <br />
                      <b>Industry Insight:</b> The state has prioritized
                      expanding access to care, especially in underserved areas,
                      driving the need for locum tenens providers.
                    </li>
                    <br />
                    <li>
                      <b>Michigan (MI)</b>
                      <br />
                      Michigan’s growing need for family practice, emergency
                      medicine, and hospitalist roles reflects its evolving
                      healthcare landscape. Both urban centers and rural regions
                      present ample job opportunities. <br />
                      <b>Industry Insight:</b> Michigan is addressing an aging
                      population and increasing healthcare accessibility,
                      fueling the demand for flexible locum staffing.
                    </li>
                    <br />
                    <li>
                      <b>Illinois (IL)</b>
                      <br />
                      llinois is experiencing strong demand for anesthesiology,
                      psychiatry, and hospitalist providers. With Chicago as a
                      healthcare hub, there’s a steady need for locum tenens
                      support. <br />
                      <b>Industry Insight:</b> Industry Insight: Illinois has
                      been investing in healthcare workforce development to
                      address staffing shortages and ensure quality patient care
                      statewide.
                    </li>
                    <br />
                    <li>
                      <b>California (CA)</b>
                      <br />
                      California remains a prime location for locum tenens
                      providers, especially in family practice, emergency
                      medicine, and psychiatry. From large urban hospitals to
                      rural clinics, opportunities are abundant.
                      <br />
                      <b>Industry Insight:</b> California’s diverse and growing
                      population ensures consistent demand for healthcare
                      services, making it one of the top states for locum tenens
                      roles.
                    </li>
                  </ol>
                  <b> Why Locum Tenens Is Growing </b>
                  <br />
                  The locum tenens industry is thriving as healthcare facilities
                  increasingly rely on flexible staffing to fill gaps, reduce
                  burnout, and maintain high-quality care. Providers benefit
                  from competitive pay, flexible schedules, and the chance to
                  travel and explore new regions. Market Trend: A recent report
                  by Staffing Industry Analysts predicts continued growth in the
                  locum tenens market, with a focus on specialties like
                  emergency medicine, psychiatry, and family practice.
                  <br />
                  <br />
                  <b>Ready to Find or Post a Job?</b>
                  <br /> Whether you're ready to apply for your next role or
                  you're a recruiter looking to post jobs and connect with top
                  talent—Locums.com has you covered. <br />
                  <br />
                  Providers – <a href='https://www.locums.com/jobs'>Browse and apply today! </a>
                  <br />
                  Recruiters – <a href='https://www.locums.com/recruiter-sign-up'>Sign up and start posting your open jobs now!</a>
                </>
              ),
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
