import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';
import { useMemo } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BusinessIcon from '@mui/icons-material/Business';

const iconStyles = {
  //fontSize: 60,
  color: appColors.content.tertiary,
  // zIndex: 9,
};

export const IconCalendar = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <CalendarMonthOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconTravel = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <FlightOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconLodging = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <HotelOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconWebsite = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <WebAssetOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconContact = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <PersonOutlineOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconPhone = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <LocalPhoneOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconEMail = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <EmailOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconGroup = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <GroupsOutlinedIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );

export const IconAgency = ({
  iconColor,
  size = 24,
  ...props
}: IconCustomProps) =>
  useMemo(
    () => <BusinessIcon sx={{ ...iconStyles, fontSize: size }} />,
    [size]
  );
