import { SetStateAction, memo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldsContainer,
  FormContainer,
  MultiValueText,
  RecruitersOnlyDetailsCardHeader,
  RowContainer,
} from '../';
import Input, { INPUT_COMMONS, inputDefaultStyles } from '@/components/Input';
import selectsData from '@/constants/selects';
import InputMask from '@/components/InputMask';
import InputArea from '@/components/InputArea';
import {
  EditLocumsLogFormValues,
  LocumsLogFormValues,
  PostNewJobFormValues,
} from '@/types/forms';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';

import { fieldsDefaultRules } from '@/constants/form-validations';
import { masks } from '@/constants/input-masks';
import { css } from '@emotion/react';
import RecruitersOnlyDetailsCard from '@/components/Dashboard/RecruitersOnlyDetailsCard';
import Paragraph from '@/components/Typography/Paragraph';
import Checkbox from '@/components/Checkbox';
import { appColors } from '@/constants/colors';

const placeHolderStyle = css`
  color: #9b9ba4;
`;
const checkBoxContainer = css`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

const EditLocumsLogMiscForm = ({
  methods,
}: {
  methods: UseFormReturn<EditLocumsLogFormValues, any>;
}) => {
  const { control, watch } = methods;

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showMiscFields, setShowMiscFields] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdditionalFields(event.target.checked);
  };
  const handleMiscChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowMiscFields(event.target.checked);
  };
  const options = {
    yes: {
      value: true,
      label: 'Yes',
    },
    no: {
      value: false,
      label: 'No',
    },
  };

  return (
    <form>
      <FormContainer>
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.permission_to_present',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['permission_to_present'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.site_contact',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Site Contact',
                          placeholder: 'Enter Site Contact Name',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.site_interview',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['site_interview'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.site_date',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error, isTouched },
                  }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['site_date'],
                          ...field,
                          isTouched,
                          error,
                          id: field.name,
                          //min: new Date().toISOString().split('T')[0],
                          css: css`
                            ${isTouched
                              ? { color: '#05051B' }
                              : placeHolderStyle}
                          `,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.site_decision',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['site_decision'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.position_accepted',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          options: [
                            {
                              value: options.yes.value,
                              label: options.yes.label,
                            },
                            {
                              value: options.no.value,
                              label: options.no.label,
                            },
                          ],
                          ...fieldsDefaultProps['position_accepted'],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.position_reason',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        label: 'Reason Yes/No',
                        placeholder: 'Add Reason Notes',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(EditLocumsLogMiscForm);
