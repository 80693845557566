//React Functionality
import { ComponentProps, FC, useCallback, useState } from 'react';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { getMetaToken } from '@/utils/meta-token';
import { datatableUtils } from '@/constants/datatables/_index';
import { createProfessionalReferencesThunk } from '@/storage/jobboard/jobboard-actions';
import { useAppDispatch } from '@/storage/store';
import { FormProvider, useForm } from 'react-hook-form';
//Components
import layout from '@/constants/layout';
import DeleteButton from '../../DeleteButton';
import Heading from '../../../Typography/Heading';
import JobPostedId from '../../../Job/JobPostedId';
import Button, { ButtonProps } from '../../../Button';
import { PromptMessage } from '@/components/ModalPrompt';
import ModalLayout from '../../../Layout/Modal/ModalLayout';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
import JobShowDescriptionHeader from '../../../Job/JobShowDescriptionHeader';
import LogDetails from '../LogTiles/LogDetails';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Card from '@/components/Card';
import JobDescDetail from '@/components/Job/JobDescDetail';
import { paragraphDesktop } from '@/constants/typography';
import Paragraph from '@/components/Typography/Paragraph';
import LocumsLogFlowReferencesEditForm from '../../../Forms/EditLocumsLog/EditLocumsReferencesForm';
import LocumsLogFlowReferencesForm from '@/components/Forms/NewLocumsLog/LocumsLogFlowReferencesForm';
import { CancelNextButtons } from '@/components/Forms';
//Types
import { GetLocumsLogByIdResponse } from '@/types/api/responses';
import { ProfessionalReference } from '@/types/types';
import { NewProfessionalReferencesParams } from '@/types/api/params';
import {
  EditProfessionalReferencesFormValues,
  ProfessionalReferencesFormValues,
} from '@/types/forms';
//CSS
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { appColors } from '@/constants/colors';
import { IconEdit } from '../../../Icons/Edit';
import { IconPlus } from '@/components/Icons/Plus';
import {
  IconAgency,
  IconContact,
  IconEMail,
  IconPhone,
} from '@/components/Icons/ExpandableContentIcons';

const HeaderText = styled.label({ ...paragraphDesktop.lg });

const {
  windowSizes: { tablet },
} = layout;

const minTablet = tablet + 1;

const URL = '/providers/professional_reference';
interface LocumsLogTileModalContentShowModeProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  logByIdResponse: GetLocumsLogByIdResponse;
  changeMode: () => void;
}

//Tabs Function & Render
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LocumsLogTileModalContentShowMode: FC<
  LocumsLogTileModalContentShowModeProps
> = ({ logByIdResponse, modalProps, changeMode }) => {
  //Local State
  const [value, setValue] = useState(0);
  const [isAddingReference, setIsAddingReference] = useState(false);
  const [isEditingReference, setIsEditingReference] = useState(false);

  const dispatch = useAppDispatch();
  const { patch, transform } = useInertiaForm();
  const { locums_log } = logByIdResponse;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //Add reference Methods
  const addMethods = useForm<ProfessionalReferencesFormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
    },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    watch,
    reset,
  } = addMethods;

  const handleAddReference = () => {
    setIsAddingReference(true);
  };

  //Edit Methods
  const initialFormValues: EditProfessionalReferencesFormValues = {
    references: {
      id: 0,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
    },
  };

  const methods = useForm<EditProfessionalReferencesFormValues>({
    defaultValues: { ...initialFormValues },
    mode: 'onSubmit',
  });

  const handleEditReference = (reference: ProfessionalReference) => {
    setIsEditingReference(true);
    methods.reset({ references: reference });
  };

  //Function Action
  const onDeleteRows = useCallback(async () => {
    const id = `Job-delete-${locums_log.id}`;
    const URL = '/providers/locums_log/log_bulk_destroy';
    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      patch(URL, {
        preserveState: true,
        //@ts-ignore
        data: transform(() => ({
          ids: [locums_log.id],
        })),

        headers: {
          ...metaTokenHeader,
        },
        onProgress: () => {},
        onError: () => {
          defaultToastError(id);
        },
        onSuccess: () => {
          modalProps.closeModal();
          const toastSuccessMessage = `Locums log has been deleted`;
          defaultToastSuccess(id, toastSuccessMessage);
        },
      });
    }
  }, [locums_log.id]);

  const onSubmit = useCallback(
    async (form: ProfessionalReferencesFormValues) => {
      const references = [
        {
          first_name: form.first_name,
          last_name: form.last_name,
          phone: form.phone,
          email: form.email,
          company: form.company,
        },
      ];
      const params = {
        references,
        locum_id: locums_log.locum_id,
      };
      try {
        const response = await dispatch(
          createProfessionalReferencesThunk(params)
        ).unwrap();
        if (response) {
          reset(params);
          setIsAddingReference(false);
          modalProps.closeModal();
          defaultToastSuccess('', 'Your professional reference has been saved');
        }
        return;
      } catch (error) {
        defaultToastError('There was an error', 'Please try again.');
      }
      return;
    },
    [dispatch]
  );

  const onSaveChanges = useCallback(
    async (form: EditProfessionalReferencesFormValues) => {
      try {
        const params = {
          references: {
            first_name: form.references.first_name,
            last_name: form.references.last_name,
            email: form.references.email,
            phone: form.references.phone,
            company: form.references.company,
          },
        } as unknown as NewProfessionalReferencesParams;

        const metaTokenHeader = await getMetaToken();
        if (!!metaTokenHeader) {
          patch(`${URL}/${form.references.id}`, {
            preserveState: true,
            //@ts-ignore
            data: transform(() => ({
              ...params,
            })),
            headers: {
              ...metaTokenHeader,
            },
            onSuccess: (successResponse) => {
              setIsEditingReference(false);
              modalProps.closeModal();
              defaultToastSuccess(
                '',
                'Your professional reference has been updated'
              );
            },
            onError: (errorResponse) => {
              console.log({ errorResponse });
            },
          });
        }
        return;
      } catch (error) {}
    },
    []
  );

  //Buttons
  const addButtonProps: ButtonProps = {
    onClick: handleAddReference,
    variant: '1',
    children: 'Add Reference',
    icon: IconPlus,
  };

  const editButtonProps: ButtonProps = {
    onClick: changeMode,
    variant: '2',
    children: 'Edit',
  };

  const deleteButtonProps: ComponentProps<typeof DeleteButton> = {
    deleteButtonLabel: 'Delete this log',
    hasIcon: false,
    deleteButtonProps: {},
    //@ts-ignore
    questionPromptProps: {
      msgProps: [
        <PromptMessage {...{ children: datatableUtils.log.DELETE_MSG }} />,
      ],
      buttonsProps: {
        leftButton: {},
        rightButton: { onClick: onDeleteRows },
      },
    },
  };

  return (
    <>
      <div {...{ css: contentContainer }}>
        <div
          {...{
            css: headerContainer,
          }}
        >
          <Heading
            {...{ children: locums_log.role_description, variant: 'h2' }}
          />
          <div
            {...{
              css: subheaderContainer,
            }}
          >
            <JobPostedId
              {...{
                id: locums_log.id,
                posted_at: locums_log.created_at,
                locum_log: true,
              }}
            />
          </div>
        </div>
        <div>
          {/*  MOBILE DETAILS  */}
          <div
            {...{
              css: css`
                ${subheaderContentContainerStyles}
                @media (min-width: ${minTablet}px) {
                  display: none;
                }
              `,
            }}
          >
            <JobShowDescriptionHeader
              {...{
                text: 'Locum Log Details',
              }}
            />
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="locums log details tabs"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '100%',
                overflow: 'auto',
                ...tabStyles,
              }}
            >
              <Tab
                label="Facility"
                {...a11yProps(0)}
                sx={{ flexShrink: 1, minWidth: 'auto', padding: '6px 12px' }}
              />
              <Tab
                label="Job"
                {...a11yProps(1)}
                sx={{ flexShrink: 1, minWidth: 'auto', padding: '6px 12px' }}
              />
              <Tab
                label="Misc"
                {...a11yProps(2)}
                sx={{ flexShrink: 1, minWidth: 'auto', padding: '6px 12px' }}
              />
              <Tab
                label="Agency"
                {...a11yProps(3)}
                sx={{ flexShrink: 1, minWidth: 'auto', padding: '6px 12px' }}
              />
              <Tab
                label="References"
                {...a11yProps(4)}
                sx={{ flexShrink: 1, minWidth: 'auto', padding: '6px 12px' }}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <LogDetails
                  {...{
                    log: locums_log,
                    defaultCardProps: {
                      extraChar: '',
                    },
                    facility: true,
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <LogDetails
                  {...{
                    log: locums_log,
                    defaultCardProps: {
                      extraChar: '',
                    },
                    role: true,
                  }}
                />
              </div>
              <div {...{ css: descContainer }}>
                {!!locums_log.role_description && (
                  <Card>
                    <JobDescDetail
                      {...{
                        header: 'Description',
                        detail: locums_log.role_description,
                      }}
                    />
                  </Card>
                )}
                {!!locums_log.role_notes && (
                  <Card>
                    <JobDescDetail
                      {...{
                        header: 'Notes',
                        detail: locums_log.role_notes,
                      }}
                    />
                  </Card>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <LogDetails
                  {...{
                    log: locums_log,
                    defaultCardProps: {
                      extraChar: '',
                    },
                    misc: true,
                  }}
                />
              </div>
              <div {...{ css: descContainer }}>
                {!!locums_log.position_reason && (
                  <Card>
                    <JobDescDetail
                      {...{
                        header: 'Reason Yes/No',
                        detail: locums_log.position_reason,
                      }}
                    />
                  </Card>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <LogDetails
                  {...{
                    log: locums_log,
                    defaultCardProps: {
                      extraChar: '',
                    },
                    agency: true,
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              {!isEditingReference && !isAddingReference && (
                <div {...{ css: MainContainer }}>
                  <HeaderText>
                    Professional References (
                    {locums_log.professional_references?.length})
                  </HeaderText>

                  {locums_log.professional_references?.map((reference) => (
                    <Card key={reference.id}>
                      <div {...{ css: ItemsContainer }}>
                        <div {...{ css: infoContainer }}>
                          <div css={iconTextContainer}>
                            <IconContact
                              {...{
                                iconColor: appColors.content.secondary,
                                size: 16,
                              }}
                            />
                            <Paragraph
                              {...{
                                children: `${reference.first_name} ${reference.last_name}`,
                                variant: 'md',
                                style: {
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </div>
                          <div css={iconTextContainer}>
                            <IconAgency
                              {...{
                                iconColor: appColors.content.secondary,
                                size: 16,
                              }}
                            />
                            <Paragraph
                              {...{
                                children: reference.company,
                                variant: 'sm',
                                style: {
                                  color: appColors.content.secondary,
                                },
                              }}
                            />
                          </div>
                          <div css={iconTextContainer}>
                            <IconEMail
                              {...{
                                iconColor: appColors.content.secondary,
                                size: 16,
                              }}
                            />
                            <Paragraph
                              {...{
                                children: reference.email,
                                variant: 'sm',
                                style: {
                                  color: appColors.content.secondary,
                                },
                              }}
                            />
                          </div>
                          <div css={iconTextContainer}>
                            <IconPhone
                              {...{
                                iconColor: appColors.content.secondary,
                                size: 16,
                              }}
                            />
                            <Paragraph
                              {...{
                                children: reference.phone,
                                variant: 'sm',
                                style: {
                                  color: appColors.content.secondary,
                                },
                              }}
                            />
                          </div>
                        </div>

                        <Button
                          {...{
                            onClick: () => handleEditReference(reference), // Move logic inside map
                            variant: '2',
                            icon: IconEdit,
                            children: '',
                            style: { marginBlockEnd: 'auto', width: '50px' },
                          }}
                        />
                      </div>
                    </Card>
                  ))}
                  <Button {...addButtonProps} />
                </div>
              )}
            </TabPanel>
          </div>
        </div>
        {/* BUTTONS  */}
        {value !== 4 ? (
          <div {...{ css: desktopButtonsContainer }}>
            <Button {...editButtonProps} />
            <DeleteButton {...{ ...deleteButtonProps }} />
          </div>
        ) : null}
      </div>
      {isAddingReference && (
        <>
          <LocumsLogFlowReferencesForm
            {...{ methods: addMethods, oneReference: true }}
          />
          <div {...{ css: buttonsContainer }}>
            <CancelNextButtons
              {...{
                cancelButtonProps: { onClick: modalProps.closeModal },
                nextButtonProps: {
                  children: ' Save Reference',
                  onClick: addMethods.handleSubmit(onSubmit),
                },
              }}
            />
          </div>
        </>
      )}

      {isEditingReference && (
        <FormProvider {...methods}>
          <LocumsLogFlowReferencesEditForm {...{ methods }} />
          <div {...{ css: buttonsContainer }}>
            <CancelNextButtons
              {...{
                cancelButtonProps: {
                  onClick: modalProps.closeModal,
                },
                nextButtonProps: {
                  children: 'Save Reference',
                  onClick: methods.handleSubmit(onSaveChanges),
                },
              }}
            />
          </div>
        </FormProvider>
      )}
    </>
  );
};

export default LocumsLogTileModalContentShowMode;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const subheaderContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
`;

const subheaderContentContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const desktopButtonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: ${tablet}px) {
    //visibility: hidden;
    //  display: none;
    //height: 0;
  }
`;

const detailGridContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const tabStyles = css`
  @media (max-width: ${tablet}px) {
    .MuiTabs-root {
      flex-wrap: wrap;
    }

    .MuiTab-root {
      padding: 6px 8px;
      min-width: auto;
    }
  }
`;

const MainContainer = css`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
`;

const infoContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ItemsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* @media (max-width: ${tablet}px) {
    flex-direction: column;
    gap: 8px;
  } */
`;

const iconTextContainer = css`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const descContainer = css`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
