import React, { useCallback, useReducer, useState } from 'react';
import { IconButton, Dialog } from '@mui/material';
import { LocumsLog } from '@/types/entities.d';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { getMetaToken } from '@/utils/meta-token';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
import { IconArrowDown, IconArrowUp } from '../Icons/Arrow';
import { appColors } from '@/constants/colors';
import { IconEdit } from '../Icons/Edit';
import { IconTrash } from '../Icons/Trash';
import QuestionPrompt from '../ModalPrompt/QuestionPrompt';
import { PromptMessage } from '../ModalPrompt';
import useModal from '@/hooks/useModal';
import Modal from '../Modal';
import {
  initialState,
  reducer,
} from '@/constants/contexts/datatable-providers-locums-log';
import LocumsLogTileModalContent from '../Dashboard/Clinician/TableListTitleModal/LocumsLogTileModalContent';
import CustomTooltip from '../Tooltip';
import { LogoSpinner } from '../Icons/Logo';

interface ActionsCellProps {
  row: {
    original: LocumsLog;
    toggleExpanded: () => void;
    getIsExpanded: () => boolean;
  };
}

const ActionsCell: React.FC<ActionsCellProps> = ({ row }) => {
  // LOCAL STATE
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isExpanded = row.getIsExpanded();
  const locums_log = row.original;

  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });
  // Modal functions for the delete button
  const { ModalComponent, openModal, closeModal } = useModal();

  const handleClickOpen = () => {
    openModal();
  };

  const handleClose = () => {
    closeModal();
  };
  // Modal functions for the edit button
  // Open edit modal logic
  const openEditModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onClickEditModal = useCallback(() => {
    openEditModal();
  }, [openEditModal]);

  const closeEditModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
    setShowModal(false);
  }, [localDispatch]);

  const { patch, transform } = useInertiaForm();

  // Delete rows API integration using Inertia.js
  const onDeleteRows = useCallback(async () => {
    const id = `Log-delete-${locums_log.id}`;
    const URL = '/providers/locums_log/log_bulk_destroy';
    const metaTokenHeader = await getMetaToken();

    if (metaTokenHeader) {
      patch(URL, {
        preserveState: true,
        data: transform(() => ({
          ids: [locums_log.id],
        })),
        headers: {
          ...metaTokenHeader,
        },
        onProgress: () => {},
        onError: () => {
          defaultToastError(id);
        },
        onSuccess: () => {
          handleClose();
          const toastSuccessMessage = `Locums log has been deleted`;
          defaultToastSuccess(id, toastSuccessMessage);
        },
      });
    }
  }, [locums_log.id]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <CustomTooltip {...{ title: 'Log information', placement: 'top' }}>
        <IconButton {...{ onClick: () => row.toggleExpanded() }}>
          {isExpanded ? (
            <IconArrowUp {...{ iconColor: appColors.content.tertiary }} />
          ) : (
            <IconArrowDown {...{ iconColor: appColors.content.tertiary }} />
          )}
        </IconButton>
      </CustomTooltip>
      <CustomTooltip {...{ title: 'Edit Log', placement: 'top' }}>
        <IconButton {...{ onClick: onClickEditModal }}>
          <IconEdit iconColor={appColors.content.tertiary} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip {...{ title: 'Delete Log', placement: 'top' }}>
        <IconButton
          onClick={handleClickOpen}
          sx={{
            '&:hover svg path': {
              stroke: appColors.notInDesignSystem.error,
            },
          }}
        >
          <IconTrash {...{ iconColor: appColors.content.tertiary }} />
        </IconButton>
      </CustomTooltip>
      <ModalComponent {...{}}>
        <QuestionPrompt
          {...{
            headerProps: {
              children: 'Are you sure?',
            },
            msgProps: [
              <PromptMessage
                key="delete-msg"
                children="Are you sure you want to delete this locum log?"
              />,
            ],
            buttonsProps: {
              leftButton: {
                variant: '2',
                children: 'Cancel',
                onClick: handleClose,
              },
              rightButton: {
                variant: '5',
                children: 'Delete',
                onClick: onDeleteRows,
              },
            },
          }}
        />
      </ModalComponent>
      {showModal && (
        <Modal {...{ position: 'top', onClose: closeEditModal }}>
          <LocumsLogTileModalContent
            {...{
              modalProps: { closeModal: closeEditModal },
              log: locums_log,
              forceEditMode: true,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ActionsCell;
