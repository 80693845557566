import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { router } from '@inertiajs/react';

import {
  LogoBlueBlack,
  LogoBlueWhite,
  LogoWhiteBlue,
} from '@/components/Icons/Logo';
import NavLinks from '../NavLinks';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import usePageSession from '@/hooks/usePageSession';
import LogoutButton from '@/components/LogoutButton';
import { authButtonsContainerStyle } from '../AuthButtons';
import LoginSignupButton from '@/components/LoginSignupButton';
import Link, { LinkProps } from '@/components/Typography/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { IconButton } from '@mui/material';

const DESKTOP_GAP = 40;
const TABLET_GAP = 20;
const MOBILE_GAP = 16;

const {
  windowSizes: { tablet, desktopHD, desktop, mobile },
  components: { footer },
} = layout;

const footerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  /* height: ${footer.desktop.height}px; */
  padding: ${DESKTOP_GAP}px 0;
  gap: ${DESKTOP_GAP}px;
  @media (max-width: ${desktopHD}px) {
  }
  @media (max-width: ${desktop}px) {
  }
  @media (max-width: ${tablet}px) {
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    gap: ${TABLET_GAP}px;
    padding: ${TABLET_GAP}px 0;
  }
  @media (max-width: ${mobile}px) {
    gap: ${MOBILE_GAP}px;
    padding: ${MOBILE_GAP}px 0;
  }
`;

const logoContainer = css`
  height: 50px;
  width: 234px;
`;

const footerBottomStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${desktopHD}px) {
  }
  @media (max-width: ${desktop}px) {
  }
  @media (max-width: ${tablet}px) {
    flex-direction: column-reverse;
    gap: ${TABLET_GAP}px;
  }
  @media (max-width: ${mobile}px) {
    gap: ${MOBILE_GAP}px;
  }
`;

const footerTopStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${desktopHD}px) {
  }
  @media (max-width: ${desktop}px) {
  }
  @media (max-width: ${tablet}px) {
    flex-direction: column;
    gap: ${TABLET_GAP}px;
  }
  @media (max-width: ${mobile}px) {
    gap: ${MOBILE_GAP}px;
  }
`;

const footerAuthLinksBottomStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${TABLET_GAP}px;
  @media (max-width: ${tablet}px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

const iconStyles = {
  fontSize: 35,
  color: '#FFF',
};

const socialLinks = [
  //{
  //  icon: <LinkedInIcon sx={iconStyles} />,
  //  link: 'https://www.linkedin.com/company/locums',
  // },
  // {
  //   icon: <InstagramIcon sx={iconStyles} />,
  //   link: 'https://www.instagram.com',
  // },
  // {
  //   icon: <FacebookIcon sx={iconStyles} />,
  //   link: 'https://www.facebook.com',
  // },
  // {
  //   icon: <XIcon sx={iconStyles} />,
  //   link: 'https://www.x.com',
  // },
];
const Footer: FC<NavBarProps> = ({
  navLinks = [],
  logoVariant = 'white-blue',
}) => {
  const { session } = usePageSession();
  const { signed_in } = session;

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const formattedNavLinks = navLinks.map((link) => ({
    ...link,
    style: {
      color: 'white',
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Inter',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
    },
  }));

  return (
    <div
      {...{
        css: footerStyle,
      }}
    >
      <div
        {...{
          css: footerTopStyle,
        }}
      >
        <div
          {...{
            css: logoContainer,
          }}
        >
          {logoVariant === 'blue-black' && <LogoBlueBlack {...{}} />}
          {logoVariant === 'blue-white' && <LogoBlueWhite {...{}} />}
          {logoVariant === 'white-blue' && <LogoWhiteBlue {...{}} />}
        </div>

        <div
          {...{
            css: footerAuthLinksBottomStyle,
          }}
        >
          <div>
            <NavLinks {...{ navLinks: formattedNavLinks }} />
          </div>
          {!isSignedIn && (
            <div
              {...{
                css: css`
                  ${authButtonsContainerStyle}
                  @media (max-width: ${tablet}px) {
                    width: 100%;
                    direction: rtl;
                  }
                `,
              }}
            >
              <LoginSignupButton
                {...{
                  mode: 'signIn',
                  buttonProps: {
                    style: {
                      // width: '100%',
                    },
                  },
                }}
              />
              <LoginSignupButton
                {...{
                  mode: 'signUp',
                  buttonProps: {
                    style: {
                      // width: '100%',
                    },
                  },
                }}
              />
            </div>
          )}
          {isSignedIn && (
            <div
              {...{
                css: css`
                  ${authButtonsContainerStyle}
                  @media (max-width: ${tablet}px) {
                    width: 100%;
                    direction: rtl;
                  }
                  display: block;
                `,
              }}
            >
              <LogoutButton />
            </div>
          )}
        </div>
      </div>
      <div
        {...{
          css: footerBottomStyle,
        }}
      >
        <Copyright />
        {/* <div>
          {socialLinks.map((link) => (
            <IconButton key={link.link} onClick={() => window.open(link.link)}>
              {link.icon}
            </IconButton>
          ))}
        </div> */}
        <div
          {...{
            css: css`
              display: flex;
              gap: 32px;
            `,
          }}
        >
          <PrivacyPolicy {...{ style: { color: 'white' } }} />
          <TermsOfUse {...{ style: { color: 'white' } }} />
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);

// // //

const PrivacyPolicy: FC<LinkProps> = (props) => {
  return (
    <Link
      {...{
        style: { ...props.style },
        children: 'Privacy Policy',
        hasUnderline: false,
        onClick: () => router.get('/privacy'),
      }}
    />
  );
};

const TermsOfUse: FC<LinkProps> = (props) => {
  return (
    <Link
      {...{
        style: { ...props.style },
        children: 'Terms of Use',
        hasUnderline: false,
        onClick: () => router.get('/terms'),
      }}
    />
  );
};

const currentYear = new Date().getFullYear();

const copyrightLine = (
  <>
    ©{currentYear} locums.com. All rights reserved. locums.com Intellectual
    Property.
    <br />
    Content may not be copied or shared without prior written permission.
  </>
);

const Copyright: FC<LinkProps> = (props) => {
  return (
    <Link
      {...{
        css: copyContainer,
        style: { ...props.style },
        children: copyrightLine,
        hasUnderline: false,
        onClick: () => {},
      }}
    />
  );
};

export { PrivacyPolicy, TermsOfUse, Copyright };

const copyContainer = css`
  @media (max-width: ${mobile}px) {
    text-align: center;
  }
`;