import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import CandidateFace from '../../../assets/images/candidate-face.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  margin: 0;
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>A Game-Changer for Locum Tenens Physicians</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeThree: {
              topSectionTitle:
                'The Interstate Medical Licensure Compact: A Game-Changer for Locum Tenens Physicians',
              topSectionLeft: (
                <>
                  If you’re a physician working locum tenens—or thinking about
                  it—you know that flexibility is one of the biggest perks of
                  the job. But one of the biggest headaches? Licensing in
                  multiple states. That’s where the{' '}
                  <b>Interstate Medical Licensure Compact (IMLC)</b> comes in,
                  making it faster and easier for doctors to get licensed in
                  participating states. Whether you're an experienced locum
                  tenens physician or just starting out, understanding how the
                  IMLC works could open up more opportunities and make your
                  career path smoother.
                  <br />
                  <br />
                  <b>What Is the IMLC?</b> <br />
                  Simply put, the <b>IMLC is an agreement</b> between 37 states,
                  the District of Columbia, and Guam that simplifies and speeds
                  up the medical licensing process. Instead of applying
                  separately for each state (a time-consuming and expensive
                  process), eligible physicians can use the IMLC to{' '}
                  <b>secure multiple state licenses much faster.</b> <br />{' '}
                  <br />
                  <b>Why the IMLC Is a Big Deal for Locum Tenens Physicians</b>
                  <br /> One of the best things about locum tenens is the
                  ability to work in different states, explore new healthcare
                  settings, and create a schedule that fits your lifestyle. But
                  traditional state licensing can slow you down—sometimes taking
                  months to process.
                  <br />
                  The <b>IMLC removes that roadblock,</b> helping physicians{' '}
                  <b>get licensed faster and with less hassle.</b> <br />
                  Here’s how it makes a difference:
                  <br /> <b>✔ Faster Licensing:</b> No more waiting months—get
                  licensed in multiple states in just a few weeks. That means
                  you can accept new assignments quickly and start working
                  sooner.
                  <br /> <b>✔ Saves Money:</b> While fees are involved, the IMLC
                  can be more cost-effective than applying for each state
                  license individually—especially if you plan to work in
                  multiple states.
                </>
              ),
              topSectionRight: (
                <>
                  <b>✔ More Job Opportunities:</b> With access to multiple state
                  licenses, you’re not limited to just one region. Whether you
                  want to work in rural hospitals, major cities, or specialty
                  clinics, the IMLC gives you the freedom to go where the
                  opportunities are.
                  <br /> <b>✔ Easier Renewals:</b> Keeping up with multiple
                  state licenses can be a headache, but the IMLC simplifies the
                  process, making it easier to stay compliant. <br /> <br />
                  <b>Who’s Eligible for the IMLC?</b>
                  <br /> Not every physician qualifies, but if you meet these
                  criteria, you’re in luck:
                  <ul {...{ css: orderList }}>
                    <li>
                      You <b>hold a full, unrestricted medical license</b> in a
                      participating state (this is called your "State of
                      Principal License").{' '}
                    </li>
                    <li>
                      You <b>live, work, or have an employer in that state.</b>
                    </li>
                    <li>
                      You meet <b>other requirements,</b> like passing
                      background checks and maintaining board certification.
                    </li>
                  </ul>
                  <b>
                    How the IMLC Benefits Healthcare Facilities and Patients
                  </b>{' '}
                  <br />
                  It’s not just locum physicians who benefit from the IMLC—
                  <b>healthcare facilities and patients do, too.</b> By speeding
                  up the licensing process, the IMLC helps hospitals and clinics
                  fill staffing gaps more quickly, ensuring patients get the
                  care they need when they need it. For locum tenens physicians,
                  this means greater flexibility, less red tape, and more
                  chances to make an impact—especially in underserved
                  communities. <br />
                  <br />
                  <b>The Bottom Line</b>
                  <br /> The{' '}
                  <b>
                    Interstate Medical Licensure Compact is changing the game
                  </b>{' '}
                  for locum tenens physicians. If you’re looking to expand your
                  career, pick up assignments faster, or simply avoid the
                  headaches of multiple state licensing, the IMLC is a tool you
                  should seriously consider. At the end of the day, the less
                  time you spend worrying about paperwork, the more time you
                  have to do what really matters—<b>caring for patients.</b>
                </>
              ),
              bottomImageSrc: CandidateFace,
              bottomImageAlt: 'Providers with a patient',
              testimonial:
                'Locums.com is the place to post those jobs, so you can connect to the qualified physicians and providers who have time and availability to take those assignments. Bookmark and visit daily.',
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
