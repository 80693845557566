import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Tax from '../../../assets/images/tax.jpeg';
import Hall from '../../../assets/images/hall.jpeg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  margin-top: 0;
  li::marker {
    font-weight: bold;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 15px;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Provider needs to know about taxes</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeTwo: {
              topImageSrc: Hall,
              topImageAlt: 'Recruiter interviewing a doctor',
              topSectionTitle:
                'What every locum tenens provider needs to know about taxes',
              topSectionText: (
                <>
                  If you’re a locum tenens provider who works temporary,
                  short-term or long-term medical assignments at clinics or
                  hospitals, you’ll need to learn the ins and outs of keeping up
                  with your income, expenses, retirement contributions, and
                  other financial responsibilities. And this includes, “all
                  things taxes.” If you’ve already made the decision to work
                  locum tenens, whether full time or to supplement your income,
                  congratulations! You’re among the ones who know that locum
                  tenens jobs can pay higher and offer more flexibility than
                  full-time staff jobs. But taxes work differently for locum
                  tenens providers, so read below at the top things you’ll need
                  to know.
                </>
              ),
              bottomImageSrc: Tax,
              bottomImageAlt: 'Doctors walking at the lobby',
              bottomImageTitle:
                'When you work with an agency, you’ll want to consider these important considerations:',
              bottomSectionText: (
                <>
                  <ol {...{ css: orderList }}>
                    <li>
                      Because working “locum tenens” means you’re not a
                      full-time facility employee, you’ll be working as an{' '}
                      <b>independent contractor</b> – even if you work in a
                      position through a locum tenens staffing agency. The tax
                      implications of working as an independent contractor are
                      that{' '}
                      <b>
                        you’ll file your taxes through a Form 1099-MISC rather
                        than with a Form W2.
                      </b>{' '}
                      Paying your taxes this way isn’t necessarily more
                      difficult, but you’ll need to be informed on the
                      differences in how to file and when, to make sure you’re
                      properly paying the IRS. Instead of a full-time employer
                      drawing automatic deductions for federal and state income
                      taxes, FICA, Social Security, and Medicare from your
                      paycheck, you’ll need to keep up with your own earnings,
                      set aside money with each pay period for taxes, and submit
                      your tax payments on your own. That said, when you work as
                      a locum tenens provider…
                    </li>
                    <li>
                      <b>
                        A certified public accountant (CPA) who has experience
                        helping independent contractors can advise you on when
                        and how to file your tax payments.
                      </b>{' '}
                      If you are a locum tenens provider that works in multiple
                      states, make sure your CPA is up-to-speed on any
                      implications this might bring. Each state has legal
                      regulatory requirements that could affect tax obligations,
                      and you don’t want to be taxed twice. They can also help
                      determine for you what expenses you may incur that could
                      be tax deductible for your business. Because…
                    </li>
                    <li>
                      <b>
                        You may be able to deduct business expenses that would
                        not apply if you were a full-time W2 employee.
                      </b>{' '}
                      Examples of deductions may include your travel expenses
                      during assignments (if you paid for your expenses out of
                      pocket), continuous education certification fees,
                      licensing fees, and supplies. The cost of health and
                      dental insurance premiums can also be tax-deductible.
                      Other common expenses to deduct include cellphones,
                      computers, scrubs, lab coats — anything you use at work
                      that you pay for out of your own pocket. Once you consult
                      with your accountant on what is deductible for your locum
                      tenens business, you’ll need to keep meticulous records of
                      your expenses – especially all receipts. The IRS won’t
                      accept bank or credit card statements as proof of
                      expenses, so make sure you keep all receipts or a picture
                      or digital image of your receipts. Again, using accounting
                      software can help you stay organized.
                    </li>
                    <li>
                      <b>
                        You will want to consider your retirement planning and
                        retirement contributions, as they are tax deductible.
                      </b>{' '}
                      W2 employees traditionally have retirement plans set up
                      with/through their employers, and contributions are
                      withdrawn from their paychecks. As a locums professional,
                      you’ll want to set up and contribute to your retirement
                      account(s) as an independent contractor. Speak to your
                      accountant or financial planner about these tax-advantaged
                      accounts to find what’s right for you: 1. Individual
                      401ks. 2. Personal defined benefit (cash balance) plans.
                      3. SEP-IRAs - Simplified Employee Pension Plans. 4.
                      Backdoor Roth IRAs. 5. Health Savings Accounts. Carefully
                      considering your individual financial situation and
                      long-term goals is important when choosing the most
                      suitable retirement plan for yourself.
                    </li>
                    <li>
                      <b>
                        Secure your own healthcare insurance coverage; payments
                        may be tax deductible.
                      </b>{' '}
                      Unlike traditional W2 employees who receive
                      employer-sponsored health insurance, when you work as a
                      locum tenens provider, you will need to shop for and sign
                      up for your own health insurance plan. You should also
                      assess other potential insurance needs. Disability
                      insurance and professional liability insurance, for
                      example, may be tax deductible. Consult with your CPA or
                      other financial advisor to discover what is right for you.
                    </li>
                  </ol>
                  The information contained herein is general in nature and is
                  subject to change and does not represent contractual agreement
                  between Locums.com and the reader. Tax information contained
                  in this document is purely hypothetical and not intended to be
                  used and cannot be used by any person as a basis for avoiding
                  tax penalties that may be imposed by the IRS or any state. We
                  recommend each taxpayer seek advice based on their
                  circumstances from an independent tax advisor. All rights
                  reserved.
                </>
              ),
              withTestimonial:true,
              testimonial:
                'Other common expenses you may be able to deduct include cellphones, computers, tablets, scrubs, lab coats — anything you use at work that you pay for out of your own pocket. Check with your advisor for a list that applies to you.',
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
