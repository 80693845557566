import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { Head, usePage } from '@inertiajs/react';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { useUrlSearchParams } from 'use-url-search-params';

import {
  FilterMenuApplyButton,
  FilterMenuButtons,
  FilterMenuHeader,
  FilterMenuResetText,
  FiltersMenuLayout,
} from '@/components/Filter/FiltersMenu';
import {
  handleFilterMenuThunk,
  handleMoreFiltersThunk,
  saveSearchProviderThunk,
} from '@/storage/jobboard/jobboard-actions';
import {
  getFormInitialValueFromParam,
  getParamValueFromForm,
} from '@/utils/form-data';
import List from '@/components/List';
import Modal from '@/components/Modal';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import Filter from '@/components/Filter';
import CloseIcon from '@/components/CloseIcon';
import { useAppDispatch } from '@/storage/store';
import { getMetaToken } from '@/utils/meta-token';
import Bottomsheet from '@/components/Bottomsheet';
import { MultiValueText } from '@/components/Forms';
import selectsData from '@/constants/selects/_index';
import { getActiveFieldsNumber } from '@/utils/utils';
import { FiltersMenuFormValues, SaveSearchFormValues } from '@/types/forms';
import { IconSearch } from '@/components/Icons/Search';
import { statesHash } from '@/constants/selects/states';
import useDropdownsData from '@/hooks/useDropdownsData';
import useJobboardState from '@/hooks/useJobboardState';
import {
  JobboardSearchParams,
  SaveSearchValuesParams,
} from '@/types/api/params';
import { transformQueryParams } from '@/utils/query-params';
import JobListTile from '@/components/JobTiles/JobListTile';
import { fieldsDefaultProps } from '@/constants/form-fields';
import NoResultsFound from '@/components/List/NoResultsFound';
import { JobboardSearchResponse } from '@/types/api/responses';
import FilterForm from '@/components/Forms/Jobboard/FilterForm';
import MultiSelectOption from '../InputDropdown/MultiSelectOption';
import MoreLessFilterButton from '@/components/Filter/MoreLessFilterButton';
import SaveSearchModal from '../Filter/SaveSearchModal';
import SaveSearchForm from '../Forms/Jobboard/SaveSearchForm';
import useModal from '@/hooks/useModal';
import SaveSearchButton from '../SaveSearchButton';
import { IconSaveSearch } from '../Icons/SaveSearch';
import LoginSignupButton from '../LoginSignupButton';
import usePageSession from '@/hooks/usePageSession';
import { defaultToastSuccess } from '@/constants/toasts';

const FILTER_WIDTH = 350;

const {
  windowSizes: { desktop, tablet },
  components: { iconStyles },
} = layout;

const minTablet = tablet + 1;

const GridContainer = styled.div`
  display: flex;
  grid-template-columns: 'auto auto';
  grid-template-rows: 1fr;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  width: 100%;
  @media (max-width: ${desktop}px) {
    gap: 0px;
  }
`;

const JobBoardIndexContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 967px;
  @media (max-width: ${tablet}px) {
    padding: 0px 16px;
    width: 100%;
  }
`;

const Menu = styled.div`
  display: flex;
  @media (max-width: ${desktop}px) {
    display: none;
  }
`;

const buttonContainer = css`
  display: flex;
  gap: 8px;
  padding-left: 8px;
  width: 100%;
`;

const iconContainer = css`
  width: 26px;
  height: 26px;
`;

const styles = {
  container: (base: any) => ({
    ...base,
    '@media (min-width: 768px)': {
      width: 290,
    },
  }),
  menu: (base: any) => ({
    ...base,
    width: 355,
  }),
  valueContainer: (base: any) => ({
    ...base,
    left: '1em',
  }),
};
const locationStyles = {
  valueContainer: (base: any) => ({
    ...base,
    left: '1em',
  }),
};

const initialFilterValues: FiltersMenuFormValues = {
  professions: '',
  state_in: '',
  specialties: '',
  //
  facilities: '',
  employment_types: '',
  posted_at_gt: '',
  min_hourly_rate_gt: '',
  //
  assignments: '',
  sort: 'posted_at desc',
  page: 1,
};

const initialSaveSearchValues: SaveSearchFormValues = {
  search_name: '',
  url: '',
  send_notification: false,
};

const JobList = List<Job>;
const FilterBar = Filter<FiltersMenuFormValues>;
const Form = FilterForm<FiltersMenuFormValues>;
//const SaveForm = SaveSearchForm<SaveSearchFormValues>;

const JobBoardIndexContent = () => {
  const { dropdownsHashes, dropdownOptions } = useDropdownsData();
  const dispatch = useAppDispatch();
  const { props, url } = usePage<JobboardSearchResponse>();

  const { isFilterMenuActive, hasMoreFilters } = useJobboardState();
  const { get, transform } = useInertiaForm();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as JobboardSearchParams;
  const parsedParams = transformQueryParams<JobboardSearchParams>(urlParams);

  const [open, setOpen] = useState(false);
  const { ModalComponent, openModal, closeModal } = useModal();

  //Save Search button login
  const {
    session: { signed_in },
    session,
  } = usePageSession();
  const { current_user } = session;
  const isThereCurrentUser = !!current_user;
  const providerId = (isThereCurrentUser && current_user.id) || -1;
  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;
  // PARAMS

  //  PROFESSIONS
  const professionParam = useMemo(() => {
    if (!!parsedParams?.professions) {
      return getFormInitialValueFromParam(
        'professions',
        parsedParams?.professions,
        dropdownsHashes?.profession
      );
    }
  }, [parsedParams?.professions]);

  //  STATE
  const stateParam = useMemo(() => {
    if (!!parsedParams?.q?.state_in) {
      return getFormInitialValueFromParam(
        'state_in',
        parsedParams.q?.state_in,
        statesHash
      );
    }
  }, [parsedParams?.q?.state_in]);

  //  SPECIALTIES
  const specialtiesParam =
    parsedParams?.[`specialties[]`] || parsedParams?.specialties;

  const specialtyParam = useMemo(() => {
    const isThereParam = !!specialtiesParam;
    if (!!isThereParam) {
      return getFormInitialValueFromParam(
        'specialties',
        typeof specialtiesParam === 'string'
          ? [specialtiesParam]
          : specialtiesParam,
        dropdownsHashes?.specialty
      );
    }
  }, [specialtiesParam]);

  //

  const barParams = {
    ...professionParam,
    ...stateParam,
    ...specialtyParam,
  };

  const methods = useForm<FiltersMenuFormValues>({
    defaultValues: {
      ...initialFilterValues,
      ...barParams,
    },
    shouldUnregister: false,
  });

  const methods2 = useForm<SaveSearchFormValues>({
    defaultValues: {
      ...initialSaveSearchValues,
    },
    mode: 'onSubmit',
  });

  const { sort, pagination, collection } = props;

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = methods;

  const { reset: reset2, handleSubmit: handleSubmit2, setError } = methods2;

  const activeFilterDesktop = getActiveFieldsNumber(
    watch([
      'assignments',
      'facilities',
      'employment_types',
      'min_hourly_rate_gt',
      'posted_at_gt',
    ])
  );

  const activeFilterMobile = getActiveFieldsNumber(
    watch(['professions', 'state_in', 'specialties'])
  );

  const handleFilterMenu = useCallback(() => {
    dispatch(handleFilterMenuThunk());
    return;
  }, [dispatch]);

  const moreLessFilters = useCallback(() => {
    dispatch(handleMoreFiltersThunk());
    return;
  }, [dispatch]);

  const triggerSearch = useCallback(
    async ({ form }: { form: FiltersMenuFormValues }) => {
      const isDefaultSort = form?.sort === 'posted_at desc';
      const isPageDiffOne = !!form?.page && form.page > 1;

      const params: JobboardSearchParams = {
        q: {
          // BAR
          ...getParamValueFromForm('state_in', form?.state_in),
          // FILTER
          ...getParamValueFromForm('facilities', form?.facilities),
          ...getParamValueFromForm('employment_types', form?.employment_types),
          ...getParamValueFromForm('posted_at_gt', form?.posted_at_gt),
          ...getParamValueFromForm(
            'min_hourly_rate_gt',
            form?.min_hourly_rate_gt
          ),
        },
        //
        ...getParamValueFromForm('professions', form?.professions),
        ...getParamValueFromForm('facilities', form?.facilities),
        ...getParamValueFromForm('employment_types', form?.employment_types),
        ...getParamValueFromForm('specialties', form?.specialties),
        //
        ...getParamValueFromForm('assignments', form?.assignments),
        ...(isDefaultSort ? {} : { sort: form.sort }),
        ...(isPageDiffOne ? { page: form.page } : {}),
      };

      const metaTokenHeader = await getMetaToken();

      if (!!metaTokenHeader) {
        get('/jobs', {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
        });
      }
    },
    []
  );

  const submitForm = useCallback(
    async (form: FiltersMenuFormValues) => {
      triggerSearch({
        form,
      });
    },
    [triggerSearch]
  );

  const onClickResetDesktop = useCallback(() => {
    // TO-DO CHECKBOX WITH RESET FOR PROFESSION / SPECIALTY FILTER BUTTONS
    // FOR NOW RESET ON DESKTOP RESETS ALL
    // const [professions, specialties, state_in] = watch([
    //   'professions',
    //   'specialties',
    //   'state_in',
    // ]);
    reset({
      // professions,
      // state_in,
      // specialties,
      ...initialFilterValues,
    });
    changePage(1);
    return;
  }, [watch]);

  const onClickResetMobile = useCallback(() => {
    reset({
      ...initialFilterValues,
    });
    changePage(1);
    return;
  }, []);

  const changeSort = useCallback(async () => {
    setValue(
      'sort',
      sort === 'posted_at asc' ? 'posted_at desc' : 'posted_at asc',
      {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      }
    );
    changePage(1);
  }, [sort]);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(submitForm)();
  }, []);

  const submitFilter = useCallback(() => {
    changePage(1);
  }, [changePage]);

  //Save Search Modal
  const handleOpenModal = () => {
    openModal();
    setOpen(true);
  };

  const handlecloseModal = () => {
    closeModal();
    setOpen(false);
    reset2();
  };

  const onSubmit = useCallback(
    async (form: SaveSearchFormValues) => {
      const params: SaveSearchValuesParams = {
        search_name: form.search_name,
        url: window.location.href,
        send_notification: form.send_notification,
        provider_id: providerId,
      };
      try {
        const response = await dispatch(
          saveSearchProviderThunk(params)
        ).unwrap();
        if (response) {
          handleSubmit2(onSubmit);
          handlecloseModal();
          reset2();
          defaultToastSuccess('', 'Your search has been saved');
        }
        return;
      } catch (error: any) {
        if (error['message'] === 'Search already exists!') {
          setError(
            'search_name',
            {
              message: 'The search name already exists.',
              type: 'manual',
            },
            {
              shouldFocus: true,
            }
          );
        } else if (
          error['message'] === 'Search with this URL already exists!'
        ) {
          setError('search_name', {
            message: 'The search with this parameters already exists.',
            type: 'manual',
          });
        }
      }
    },
    [dispatch]
  );

  return (
    <>
      <Head>
        <title>Search Locums Jobs</title>
        <meta
          name="title"
          property="og:title"
          content="https://www.locums.com/jobs"
        />
        <meta
          name="description"
          property="og:description"
          content="Locums.com offers a vast network of highly qualified healthcare professionals ready to fill your locum staffing needs, saving you time and resources"
        />
        <meta
          name="Locums Logo"
          property="og:image"
          content="https://locums-public.s3.us-west-2.amazonaws.com/locums-logo.png"
        />
      </Head>
      <FormProvider {...methods}>
        <GridContainer>
          <JobBoardIndexContentContainer>
            <FilterBar
              {...{
                hasMoreFilterButton: true,
                isSearchDisabled: false,
                filters: [
                  {
                    dropDownProps: {
                      name: 'professions',
                      placeholder: fieldsDefaultProps['profession_eq'].label,
                      options: dropdownOptions.profession,
                      isClearable: true,
                    },
                  },
                  {
                    dropDownProps: {
                      name: 'state_in',
                      placeholder: fieldsDefaultProps['state_in'].label,
                      options: selectsData['state'],
                      isClearable: true,
                      isMulti: true,
                      isSearchable: true,
                      styles: locationStyles,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueText
                            {...{
                              ...rest,
                              shownValue: 'value',
                              truncateNumber: 2,
                            }}
                          />
                        ),
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    },
                  },
                  {
                    dropDownProps: {
                      name: 'specialties',
                      placeholder: fieldsDefaultProps['specialty_eq'].label,
                      options: dropdownOptions.specialty,
                      isClearable: true,
                      isMulti: true,
                      isSearchable: true,
                      styles: styles,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueText
                            {...{
                              ...rest,
                              shownValue: 'label',
                              isFirstOptionTruncated: true,
                              truncateNumber: 6,
                            }}
                          />
                        ),
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'label' }}
                          />
                        ),
                      },
                    },
                  },
                ],
                submitForm,
                activeFilters: activeFilterDesktop,
                handleFilterMenu,
                isFilterMenuActive,
                openModal: handleOpenModal,
                open,
              }}
            />
            <JobList
              {...{
                data: collection,
                pagination: pagination,
                sort: sort,
                changeSort,
                changePage,
                ListEmptyComponent: () => (
                  <NoResultsFound
                    {...{
                      header: 'No results found.',
                      subheader: 'Reset your filters and try again.',
                    }}
                  />
                ),
                RenderedItem: JobListTile,
              }}
            />
          </JobBoardIndexContentContainer>

          {/* FILTER MENU //> 1 - CONTAINER / 2- MODAL / 3 - BOTTOMSHEET */}

          <>
            <Menu
              {...{
                css: css`
                  display: ${isFilterMenuActive ? 'flex' : 'none'};
                `,
              }}
            >
              <FiltersMenuLayout
                {...{
                  cardProps: {
                    style: { width: FILTER_WIDTH },
                  },
                  header: (
                    <>
                      <FilterMenuHeader {...{ header: 'Filters' }} />
                      <FilterMenuResetText
                        {...{ onClick: onClickResetDesktop }}
                      />
                    </>
                  ),
                  content: (
                    <Form
                      {...{
                        dropdownProps: {
                          menuPosition: 'absolute',
                          menuPlacement: 'bottom',
                        },
                      }}
                    />
                  ),
                  buttons: (
                    <FilterMenuApplyButton
                      {...{
                        buttonProps: {
                          children: 'Apply',
                          onClick: submitFilter,
                        },
                      }}
                    />
                  ),
                }}
              />
            </Menu>
            <div
              {...{
                css: css`
                  display: none;
                  @media (min-width: ${minTablet}px) and (max-width: ${desktop}px) {
                    display: ${isFilterMenuActive ? 'flex' : 'none'};
                  }
                `,
              }}
            >
              <Modal
                {...{
                  onClose: handleFilterMenu,
                  isLoading: isSubmitting,
                  isAnimated: false,
                }}
              >
                <FiltersMenuLayout
                  {...{
                    cardProps: {
                      style: { width: FILTER_WIDTH + 100 },
                    },
                    header: (
                      <>
                        <FilterMenuHeader {...{ header: 'Filters' }} />
                        <CloseIcon {...{ onClick: handleFilterMenu }} />
                      </>
                    ),
                    content: (
                      <Form
                        {...{
                          dropdownProps: {
                            menuPosition: 'absolute',
                            menuPlacement: 'bottom',
                          },
                        }}
                      />
                    ),
                    buttons: (
                      <>
                        <FilterMenuButtons
                          {...{
                            reset: true,
                            resetButtonProps: {
                              onClick: onClickResetDesktop,
                            },
                            applyButtonProps: {
                              children: (
                                <div
                                  {...{
                                    css: css`
                                      display: flex;
                                      flex-direction: row;
                                      align-items: center;
                                      justify-content: center;
                                    `,
                                  }}
                                >
                                  Apply
                                  <div
                                    {...{
                                      css: css`
                                        ${iconStyles}
                                        margin-left: 4px;
                                      `,
                                    }}
                                  >
                                    <IconSearch {...{ iconColor: 'white' }} />
                                  </div>
                                </div>
                              ),
                              onClick: () => {
                                handleFilterMenu();
                                submitFilter();
                              },
                            },
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </Modal>
            </div>
            <div
              {...{
                css: css`
                  display: none;
                  @media (max-width: ${tablet}px) {
                    display: ${isFilterMenuActive ? 'flex' : 'none'};
                  }
                `,
              }}
            >
              <Bottomsheet
                {...{
                  onClose: handleFilterMenu,
                  isLoading: isSubmitting,
                  isOpen: isFilterMenuActive,
                }}
              >
                <FiltersMenuLayout
                  {...{
                    cardProps: { hasBorder: false },
                    header: (
                      <>
                        <FilterMenuHeader {...{ header: 'Search' }} />
                        <FilterMenuResetText
                          {...{ onClick: onClickResetMobile }}
                        />
                        <CloseIcon {...{ onClick: handleFilterMenu }} />
                      </>
                    ),
                    content: (
                      <>
                        <Form
                          {...{
                            hasMoreFilters,
                            dropdownProps: {
                              menuPosition: 'fixed',
                              menuPlacement: 'auto',
                            },
                          }}
                        />
                        {/* <div>
                          
                        </div> */}
                      </>
                    ),
                    buttons: (
                      <>
                        <FilterMenuButtons
                          {...{
                            reset:
                              isSignedIn &&
                              session?.current_user?.is_type !== 'provider',
                            //isMobile: true,
                            resetButtonProps: {
                              onClick: onClickResetMobile,
                            },
                            applyButtonProps: {
                              children: (
                                <div
                                  {...{
                                    css: css`
                                      display: flex;
                                      flex-direction: row;
                                      align-items: center;
                                      justify-content: center;
                                    `,
                                  }}
                                >
                                  Apply
                                  <div
                                    {...{
                                      css: css`
                                        ${iconStyles}
                                        margin-left: 4px;
                                      `,
                                    }}
                                  >
                                    <IconSearch {...{ iconColor: 'white' }} />
                                  </div>
                                </div>
                              ),
                              onClick: () => {
                                handleFilterMenu();
                                submitFilter();
                              },
                              // disabled: !isDirty,
                            },
                          }}
                        />
                        <div
                          {...{
                            css: css`
                              ${buttonContainer}
                              width: ${isSignedIn &&
                              session?.current_user?.is_type === 'provider'
                                ? '100%'
                                : 'null'};
                            `,
                          }}
                        >
                          {!isSignedIn ? (
                            <LoginSignupButton
                              {...{
                                mode: 'signIn',

                                buttonProps: {
                                  variant: '2',
                                  children: (
                                    <>
                                      Save Search
                                      <div {...{ css: iconContainer }}>
                                        <IconSaveSearch />
                                      </div>
                                    </>
                                  ),
                                  style: {
                                    width: '100%',
                                  },
                                },
                              }}
                            />
                          ) : isSignedIn &&
                            session?.current_user?.is_type === 'provider' ? (
                            <SaveSearchButton
                              {...{
                                isButton: true,
                                isActive: open,
                                onClick: openModal,
                                isMobile: true,
                              }}
                            />
                          ) : null}
                        </div>
                      </>
                    ),
                  }}
                />
              </Bottomsheet>
            </div>
          </>

          {/* FILTER MENU // END  */}
          <ModalComponent {...{ onClose: handlecloseModal }}>
            <SaveSearchModal
              {...{
                content: <SaveSearchForm {...{ methods2 }} />,
                closeModal: handlecloseModal,
                onClick: methods2.handleSubmit(onSubmit),
              }}
            />
          </ModalComponent>
        </GridContainer>
      </FormProvider>
    </>
  );
};

export default JobBoardIndexContent;
