import {
  ButtonHTMLAttributes,
  ForwardRefRenderFunction,
  FunctionComponent,
  forwardRef,
} from 'react';
import { Interpolation, Theme, css } from '@emotion/react';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';
import { paragraphDesktop } from '@/constants/typography';

const {
  components: { iconStyles, textIconStyles },
} = layout;

const HEIGHT = 46;
const BORDER_RADIUS = 8;
const BORDER_WIDTH = 1;
const PADDING = 12;

export const BUTTON_COMMONS = {
  HEIGHT,
  PADDING,
  BORDER_RADIUS,
  BORDER_WIDTH,
};

export type ButtonVariants = '1' | '2' | '3' | '4' | '5' | '6';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly variant?: ButtonVariants;
  readonly icon?: FunctionComponent<IconCustomProps>;
}

export const buttonTextStyles = css({
  ...paragraphDesktop.md,
});

export const defaultStyles = css`
  user-select: none;
  height: ${HEIGHT}px;
  box-sizing: border-box;
  padding: 0px ${PADDING}px;
  border-radius: ${BORDER_RADIUS}px;
  border: ${BORDER_WIDTH}px solid transparent;
  ${buttonTextStyles}
  transition: background-color 0.2s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
`;

const ButtonOne = css`
  ${defaultStyles}
  background-color: ${appColors.background.tertiary};
  color: ${appColors.content.white};
  &:hover {
    background-color: ${appColors.notInDesignSystem[1]};
  }
  &:active {
    background-color: ${appColors.notInDesignSystem[2]};
  }
  &:disabled {
    background-color: ${appColors.notInDesignSystem[3]};
  }
`;

const ButtonTwo = css`
  ${defaultStyles}
  background-color: ${appColors.content.white};
  color: ${appColors.content.tertiary};
  border-color: ${appColors.border.primary};
  &:hover {
    background-color: ${appColors.content.white};
    border-color: ${appColors.border.secondary};
  }
  &:active {
    background-color: ${appColors.notInDesignSystem[4]};
    border-color: ${appColors.border.secondary};
  }
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: ${appColors.content.disabled};
  }
`;

const ButtonThree = css`
  ${defaultStyles}
  background: rgba(255, 255, 255, 0.7);
  color: ${appColors.content.tertiary};
  &:hover {
    background: ${appColors.notInDesignSystem[5]};
  }
  &:active {
    background-color: ${appColors.notInDesignSystem[4]};
    background: rgba(224, 232, 255, 0.7);
  }
  &:disabled {
    color: ${appColors.content.disabled};
    background: rgba(255, 255, 255, 0.7);
  }
`;

const ButtonFour = css`
  ${defaultStyles}
  height: 30px;
  padding: 0px 8px;
  background-color: transparent;
  color: ${appColors.content.tertiary};
  &:hover {
    background: rgba(250, 251, 255, 0.7);
  }
  &:active {
    background-color: #e0e8ff;
    background: rgba(224, 232, 255, 0.7);
  }
  &:disabled {
    color: ${appColors.content.disabled};
    background-color: transparent;
  }
`;

const ButtonFive = css`
  ${defaultStyles}
  background-color: ${appColors.content.white};
  color: ${appColors.notInDesignSystem.error};
  border-color: ${appColors.notInDesignSystem.error};
  &:hover {
    background: #fff5f5;
  }
  &:active {
    background: #ffe8e8;
  }
  &:disabled {
    border-color: ${appColors.content.disabled};
    color: ${appColors.content.disabled};
    background: white;
  }
`;

const ButtonSix = css`
  ${defaultStyles}
  background-color: ${appColors.content.primary};
  color: white;
  &:hover {
    background: #41414e;
  }
  &:active {
    background-color: ${appColors.content.primary};
  }
  &:disabled {
    background-color: ${appColors.content.disabled};
  }
`;

export const stylesByVariant: Record<ButtonVariants, Interpolation<Theme>> = {
  1: ButtonOne,
  2: ButtonTwo,
  3: ButtonThree,
  4: ButtonFour,
  5: ButtonFive,
  6: ButtonSix,
};

export const iconColorByVariants: Record<ButtonVariants, string> = {
  1: appColors.content.white,
  2: appColors.content.tertiary,
  3: appColors.content.tertiary,
  4: appColors.content.tertiary,
  5: appColors.notInDesignSystem.error,
  6: appColors.content.white,
};

export const iconDisabledColorByVariants: Record<ButtonVariants, string> = {
  1: 'white',
  2: appColors.content.disabled,
  3: appColors.content.disabled,
  4: appColors.content.disabled,
  5: appColors.content.disabled,
  6: 'white',
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { variant = '1', icon: Icon, ...props },
  ref
) => {
  const iconColor = props.disabled
    ? iconDisabledColorByVariants[variant]
    : iconColorByVariants[variant];

  return (
    <button
      {...{
        ...props,
        onClick: (e) => {
          e.preventDefault();
          !!props?.onClick && props.onClick(e);
        },
        css: stylesByVariant[variant],
        ref,
      }}
    >
      <div
        {...{
          css: textIconStyles,
        }}
      >
        {props.children}
        {!!Icon && (
          <div
            {...{
              css: iconStyles,
            }}
          >
            <Icon
              {...{
                iconColor,
              }}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default forwardRef(Button);
