import {
  ApplyJobAsGuestResponse,
  ApplyJobAsProviderResponse,
  FavoriteJobResponse,
  GetAdminJobByIdResponse,
  GetJobApplicationsResponse,
  GetJobByIdResponse,
  JobboardSearchResponse,
  UnFavoriteJobResponse,
  UpdateProviderResponse,
  SaveSearchProviderResponse,
  UpdateSaveSearchProviderResponse,
  GetLocumsLogByIdResponse,
} from '@/types/api/responses';
import {
  ApplyJobAsGuestParams,
  ApplyJobAsProviderParams,
  FavoriteJobParams,
  JobboardSearchParams,
  NewLogParams,
  NewProfessionalReferencesParams,
  OneProfessionalReferencesParams,
  SaveSearchValuesParams,
  UnFavoriteJobParams,
  UpdateProviderParams,
  UpdateSaveSearchParams,
} from '@/types/api/params';
import Axios from './axios-api';
import { getMetaToken } from '@/utils/meta-token';

const JOBS = '/jobs';
const APPLIED_JOBS = '/providers/applied_jobs';
const FAVORITE_JOBS = '/favorite_jobs';
const UNFAVORITE_JOBS = '/unfavorite';
const GUEST_JOB_APPLY = '/guest_job_applications';
const PROVIDER_JOB_APPLY = '/job_applications';
const PROVIDERS = '/providers';
const RECRUITER_DASH = '/recruiters/jobs';
const RECRUITER_DELETE_JOBS = '/recruiters/jobs/bulk_destroy';
const PROVIDER_SAVE_SEARCH = '/providers/saved_searches';
const DELETE_SAVED_SEARCH = '/providers/saved_searches/bulk_destroy';
const PROVIDER_NEW_LOCUMS_LOG = '/providers/locum_log';
const PROVIDER_PROFESSIONAL_REFERENCE = '/providers/professional_reference';
const ONE_PROVIDER_PROFESSIONAL_REFERENCE = '/providers/professional_reference/create_new';

const jobboardSearch = async (params: JobboardSearchParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<JobboardSearchResponse>(JOBS, {
      params: {
        ...params,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAppliedJobs = async () => {
  const axios = new Axios('');
  try {
    const response = await axios.get<JobboardSearchResponse>(APPLIED_JOBS, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSingleJob = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<any>(`${JOBS}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getJobApplications = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetJobApplicationsResponse>(
      `/recruiters/jobs/${id}/job_applications`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getJobById = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetJobByIdResponse>(
      `${RECRUITER_DASH}/${id}`,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const geAdminJobById = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetAdminJobByIdResponse>(
      `/admins/jobs/${id}`,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLocumsLogById = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetLocumsLogByIdResponse>(
      `${PROVIDER_NEW_LOCUMS_LOG}/${id}`,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// POST

const favoriteJob = async (params: FavoriteJobParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<FavoriteJobResponse>(FAVORITE_JOBS, {
      favorite_job: {
        provider_id: params.provider_id,
        job_id: params.job_id,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const applyJobAsGuest = async (params: ApplyJobAsGuestParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<ApplyJobAsGuestResponse>(
      GUEST_JOB_APPLY,
      {
        job_application: {
          ...params,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const applyJobAsProvider = async (params: ApplyJobAsProviderParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<ApplyJobAsProviderResponse>(
      PROVIDER_JOB_APPLY,
      {
        job_application: {
          ...params,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const createJobAsRecruiter = async (params) => {
  const axios = new Axios('');
  const metaTokenHeader = await getMetaToken();

  try {
    const response = await axios.post(RECRUITER_DASH, {
      job: {
        ...params.job,
      },
      headers: {
        'Content-Type': 'application/json',
        ...metaTokenHeader,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveSearchProvider = async (params: SaveSearchValuesParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<SaveSearchProviderResponse>(
      PROVIDER_SAVE_SEARCH,
      {
        saved_search: {
          ...params,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const createLocumsLog = async (params: NewLogParams) => {
  const axios = new Axios('');
  const metaTokenHeader = await getMetaToken();
  try {
    const response = await axios.post(PROVIDER_NEW_LOCUMS_LOG, {
      locums_log: {
        ...params.locums_log,
      },
      headers: {
        'Content-Type': 'application/json',
        ...metaTokenHeader,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const createProfessionalReferences = async (
  params: NewProfessionalReferencesParams
) => {
  const axios = new Axios('');
  const metaTokenHeader = await getMetaToken();
  try {
    const response = await axios.post(PROVIDER_PROFESSIONAL_REFERENCE, {
      references: {
        ...params.references,
      },
      headers: {
        'Content-Type': 'application/json',
        ...metaTokenHeader,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const createOneProfessionalReferences = async (
  params: OneProfessionalReferencesParams
) => {
  const axios = new Axios('');
  const metaTokenHeader = await getMetaToken();
  try {
    const response = await axios.post(ONE_PROVIDER_PROFESSIONAL_REFERENCE, {
      references: {
        ...params.references,
      },
      headers: {
        'Content-Type': 'application/json',
        ...metaTokenHeader,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// PUT

const updateProvider = async <P extends UpdateProviderParams>(params: P) => {
  const axios = new Axios('');
  try {
    const response = await axios.put<UpdateProviderResponse>(
      `${PROVIDERS}/${params?.user_id}`,
      {
        ['provider']: {
          ...params,
        },
      },
      {
        headers: {
          'Content-Type': params?.resume
            ? 'multipart/form-data'
            : 'application/json',
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSavedSearch = async <P extends UpdateSaveSearchParams>(
  params: P
) => {
  const axios = new Axios('');

  try {
    const response = await axios.put<UpdateSaveSearchProviderResponse>(
      `${PROVIDER_SAVE_SEARCH}/${params.id}`,
      {
        ['saved_search']: {
          ...params,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// DELETE

const unfavoriteJob = async (params: UnFavoriteJobParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.delete<UnFavoriteJobResponse>(
      `${UNFAVORITE_JOBS}/${params.job_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteJobs = async (params) => {
  const axios = new Axios('');
  try {
    const response = await axios.patch(
      `${RECRUITER_DELETE_JOBS}`,
      {
        ids: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteSavedSearch = async (params) => {
  const axios = new Axios('');
  try {
    const response = await axios.patch(
      `${DELETE_SAVED_SEARCH}`,
      {
        ids: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  jobboardSearch,
  fetchAppliedJobs,
  favoriteJob,
  unfavoriteJob,
  applyJobAsGuest,
  applyJobAsProvider,
  updateProvider,
  getJobApplications,
  getJobById,
  fetchSingleJob,
  deleteJobs,
  createJobAsRecruiter,
  geAdminJobById,
  saveSearchProvider,
  updateSavedSearch,
  deleteSavedSearch,
  createLocumsLog,
  createProfessionalReferences,
  getLocumsLogById,
  createOneProfessionalReferences, 
};
