//React Functionality
import { ComponentProps, FC, memo } from 'react';
import { getLabelFromValue } from '@/utils/form-data';
import useDropdownsData from '@/hooks/useDropdownsData';
//Types
import { LocumsLog } from '@/types/entities.d';
//Components
import JobListTileHeader from '../JobTiles/JobListTileHeader';
import JobShowDetailCard from '../Job/JobShowDetailCard';
import ExpandableRowContent from './ExpandableRowContent';
import JobDescDetail from '../Job/JobDescDetail';
//CSS
import { css } from '@emotion/react';
import Card from '../Card';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';

const HOVER_COLOR = appColors.content.tertiary;

const {
  windowSizes: { tablet },
} = layout;

interface ProvidersDashboardListTileProps {
  defaultCardProps?: Partial<ComponentProps<typeof JobShowDetailCard>>;
  item: LocumsLog;
  onClick?: () => void;
}

const jobListTileStyles = css`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto auto auto;
  column-gap: 40px;
  width: 100%;
  flex-grow: 0;
  &:hover {
    border-color: ${HOVER_COLOR};
  }
  cursor: pointer;
  @media (max-width: ${tablet}px) {
    column-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const contentContainerStyle = css`
  display: flex;
  //flex-direction: row;
  gap: 40px;
  padding: 16px 8px 16px 16px;
`;

const infoContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

const infoDetailsContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const cardContainerStyle = css`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const arrowContainerStyle = css`
  display: flex;
`;

const ExpandableRow: FC<ProvidersDashboardListTileProps> = ({
  defaultCardProps,
  item,
  onClick,
}) => {
  const { dropdownsHashes } = useDropdownsData();

  return (
    <>
      <div {...{ css: contentContainerStyle }}>
        <div {...{ css: infoContainerStyle }}>
          <JobListTileHeader
            {...{
              title: 'Facility Details',
            }}
          />
          <div {...{ css: cardContainerStyle }}>
            {!!item.facility_name && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'facility_name',
                  content: item.facility_name,
                  dense: true,
                }}
              />
            )}
            {!!item.management_group && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'management_group',
                  content: item.management_group,
                  dense: true,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!item.facility_city && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'facility_city',
                  content: item.facility_city,
                  dense: true,
                }}
              />
            )}

            {!!item.facility_state && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  dense: true,
                  type: 'facility_state',
                  content: item.facility_state,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!item.facility_contact && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'facility_contact',
                  content: item.facility_contact,
                  dense: true,
                }}
              />
            )}

            {!!item.facility_phone && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'facility_phone',
                  content: item.facility_phone,
                  dense: true,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!item.website && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  dense: true,
                  type: 'website',
                  content: item.website,
                }}
              />
            )}
          </div>
        </div>
        <div {...{ css: infoContainerStyle }}>
          <JobListTileHeader
            {...{
              title: 'Job Details',
            }}
          />
          {!!item.role_title && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'role_title',
                content: item.role_title,
                dense: true,
              }}
            />
          )}
          {!!item.specialty && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'specialty',
                content: getLabelFromValue(
                  item.specialty,
                  dropdownsHashes?.specialty
                ),
                dense: true,
              }}
            />
          )}
          <div {...{ css: cardContainerStyle }}>
            {!!item.pitch_date && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'pitch_date',
                  content: item.pitch_date,
                  dense: true,
                }}
              />
            )}

            {!!item.rate && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'rate',
                  content: `$${item.rate}+/hr`,
                  dense: true,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!item.role_start_date && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  dense: true,
                  type: 'role_start_date',
                  content: item.role_start_date,
                }}
              />
            )}

            {!!item.role_end_date && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'role_end_date',
                  content: item.role_end_date,
                  dense: true,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!(
              item.travel_included !== undefined &&
              item.travel_included !== null
            ) && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  dense: true,
                  type: 'travel_included',
                  content: item.travel_included === true ? 'Yes' : 'No',
                }}
              />
            )}

            {!!(
              item.lodging_included !== undefined &&
              item.lodging_included !== null
            ) && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'lodging_included',
                  content: item.lodging_included === true ? 'Yes' : 'No',
                  dense: true,
                }}
              />
            )}
          </div>
          {!!item.role_description && (
            <Card>
              <JobDescDetail
                {...{
                  header: 'Description',
                  detail: item.role_description,
                }}
              />
            </Card>
          )}

          {!!item.role_notes && (
            <Card>
              <JobDescDetail
                {...{
                  header: 'Notes',
                  detail: item.role_notes,
                }}
              />
            </Card>
          )}
        </div>
      </div>

      <div {...{ css: contentContainerStyle }}>
        <div {...{ css: infoContainerStyle }}>
          <JobListTileHeader
            {...{
              title: 'Misc. Details',
            }}
          />
          <div {...{ css: infoDetailsContainerStyle }}>
            <div {...{ css: cardContainerStyle }}>
              {!!(
                item.permission_to_present !== undefined &&
                item.permission_to_present !== null
              ) && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    type: 'permission_to_present',
                    content: item.permission_to_present === true ? 'Yes' : 'No',
                    dense: true,
                  }}
                />
              )}

              {!!item.site_contact && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    type: 'site_contact',
                    content: item.site_contact,
                    dense: true,
                  }}
                />
              )}
            </div>
            <div {...{ css: cardContainerStyle }}>
              {!!(
                item.site_interview !== undefined &&
                item.site_interview !== null
              ) && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    dense: true,
                    type: 'site_interview',
                    content: item.site_interview === true ? 'Yes' : 'No',
                  }}
                />
              )}

              {!!item.site_date && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    type: 'site_date',
                    content: item.site_date,
                    dense: true,
                  }}
                />
              )}
            </div>
            <div {...{ css: cardContainerStyle }}>
              {!!(
                item.site_decision !== undefined && item.site_decision !== null
              ) && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    type: 'site_decision',
                    content: item.site_decision === true ? 'Yes' : 'No',
                    dense: true,
                  }}
                />
              )}

              {!!(
                item.position_accepted !== undefined &&
                item.position_accepted !== null
              ) && (
                <ExpandableRowContent
                  {...{
                    ...defaultCardProps,
                    type: 'position_accepted',
                    content: item.position_accepted === true ? 'Yes' : 'No',
                    dense: true,
                  }}
                />
              )}
            </div>
            {!!item.position_reason && (
              <Card>
                <JobDescDetail
                  {...{
                    header: 'Reason Yes/No',
                    detail: item.position_reason,
                  }}
                />
              </Card>
            )}
          </div>
        </div>
        <div {...{ css: infoContainerStyle }}>
          <JobListTileHeader
            {...{
              title: 'Recruiter - Agency Details',
            }}
          />
          <div {...{ css: cardContainerStyle }}>
            {!!item.agency && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'agency',
                  content: item.agency,
                  dense: true,
                }}
              />
            )}

            {!!item.recruiter && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'recruiter',
                  content: item.recruiter,
                  dense: true,
                }}
              />
            )}
          </div>
          <div {...{ css: cardContainerStyle }}>
            {!!item.recruiter_phone && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'recruiter_phone',
                  content: item.recruiter_phone,
                  dense: true,
                }}
              />
            )}

            {!!item.recruiter_email && (
              <ExpandableRowContent
                {...{
                  ...defaultCardProps,
                  type: 'recruiter_email',
                  content: item.recruiter_email,
                  dense: true,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ExpandableRow);
