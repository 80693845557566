import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  signUpThunk,
  signUpResendConfirmationThunk,
} from '@/storage/auth/auth-actions';
import layout from '@/constants/layout';
import { useAppDispatch } from '@/storage/store';
import { SignupFormValues } from '@/types/forms';
import useModalState from '@/hooks/useModalState';
import SignupForm from '../Forms/Signup/SignupForm';
import RecruiterSignupForm from '../Forms/Signup/RecruiterSignupForm';
import EmailConfirmation from '../EmailConfirmation';
import ModalLayout from '../Layout/Modal/ModalLayout';
import { ResourceType, SignUpErrorMessage, UserType } from '@/types/types';

const {
  utils: { scrollToTop },
} = layout;

type SignupFlowModes = 'form' | 'check';

type SignupFlowState = {
  mode: SignupFlowModes;
};

const URL = window.location.pathname === '/recruiter-sign-up';

const recoveryFlowInitialState: SignupFlowState = {
  mode: 'form',
};

const getResourceFromValue = (value: number): ResourceType => {
  return value === 2 ? 'recruiter' : 'provider';
};

const SignupFlow = () => {
  const {
    actions: { close },
  } = useModalState();
  const dispatch = useAppDispatch();
  const [{ mode }, setState] = useState(recoveryFlowInitialState);

  const methods = useForm<SignupFormValues>({
    defaultValues: {
      is_type: undefined,
      first_name: '',
      last_name: '',
      email: '',
      terms_accepted: false,
      password: '',
      password_confirmation: '',
      //
      // first_name: 'Test',
      // last_name: 'Test',
      // email: `${Math.floor(Math.random() * 10 + 1)}-test@test.com`,
      // password: 'Cratebind1!',
      // password_confirmation: 'Cratebind1!',
    },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting },
    setError,
    watch,
  } = methods;

  const isFormMode = mode === 'form';

  const onSubmit = useCallback(async (form: SignupFormValues) => {
    const resource = URL ? 'recruiter' : getResourceFromValue(watch('is_type'));
    const type = URL ? 'recruiters' : (`${resource}s` as UserType);
    const params = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      password: form.password,
      terms_accepted: form.terms_accepted,
      signup_status: 'email_confirm',
    };

    try {
      const response = await dispatch(
        signUpThunk({ type, resource, params })
      ).unwrap();

      if (!!response) {
        scrollToTop();
        setState((prevState) => {
          return {
            ...prevState,
            mode: 'check',
          };
        });
      }
    } catch (error: any) {
      if (error?.message) {
        const message = error?.message as SignUpErrorMessage;
        if (
          message === 'Email already exists with a Provider' ||
          message === 'Email already exists with a Recruiter' ||
          message === 'Email has already been taken'
        ) {
          setError(
            'email',
            {
              message: 'The email address you entered is already in use.',
              type: 'manual',
            },
            {
              shouldFocus: true,
            }
          );
        }
      }
    }
  }, []);

  const resendAction = useCallback(async () => {
    const email = watch('email');
    // const resource = getResourceFromValue(watch('is_type'));
    try {
      await dispatch(signUpResendConfirmationThunk({ email }));
    } catch (error) {}
  }, []);

  if (isFormMode) {
    return (
      <ModalLayout
        {...{
          closeModal: close,
          isLoading: isSubmitting,
          hasLogo: true,
        }}
      >
        {URL ? (
          <RecruiterSignupForm {...{ onSubmit, methods }} />
        ) : (
          <SignupForm {...{ onSubmit, methods }} />
        )}
      </ModalLayout>
    );
  }

  const email = watch('email');

  return (
    <ModalLayout
      {...{
        closeModal: close,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <EmailConfirmation
        {...{
          email,
          header: 'Confirm your email address',
          msg: 'We sent a confirmation link to',
          msg2: 'Please click the link provided in the email to complete your profile and then close this window or tab in your browser',
          resendAction,
        }}
      />
    </ModalLayout>
  );
};

export default memo(SignupFlow);
