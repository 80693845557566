import styled from '@emotion/styled';
import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { IconArrowDown } from '../Icons/Arrow';
import SearchBar from './SearchBar';

const BORDER_WIDTH = 1;
const BORDER_RADIUS = 100;

const {
  windowSizes: { mobile },
} = layout;

interface SelectSearchOption {
  value: string;
  label: string;
}

interface SelectSearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  SelectSearchField: string;
  selectedSearch: string;
  placeholder: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  searchIconAction?: () => void;
  control: Control<any>;
  options: SelectSearchOption[];
}

const SelectSearchBar: ForwardRefRenderFunction<
  HTMLInputElement,
  SelectSearchBarProps
> = ({
  SelectSearchField,
  selectedSearch,
  placeholder,
  control,
  options,
  onChange,
  searchIconAction,
}) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Controller
        {...{
          name: 'searchField',
          control,
          render: ({ field }) => (
            <StyledSelect
              {...{
                ...field,
                labelId: 'select-search-label',
                id: 'select-search',
                value: SelectSearchField,
                onChange: onChange,
                IconComponent: IconArrowDown,
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
          ),
        }}
      />
      {/* @ts-ignore */}
      <Controller
        {...{
          name: selectedSearch,
          control,
          rules: {},
          render: ({ field }) => {
            return (
              <SearchBar
                {...{
                  ...field,
                  searchIconAction: searchIconAction,
                  placeholder: placeholder,
                  unsetLeftRadius: true,
                }}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default forwardRef(SelectSearchBar);

const StyledSelect = styled(Select)`
  background-color: white;
  width: 190px;
  border-radius: ${BORDER_RADIUS}px;
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
  box-shadow: 0px 10px 30px 0px rgba(49, 94, 255, 0.08);
  border: ${BORDER_WIDTH}px solid ${appColors.border.primary};
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiOutlinedInput-input:hover {
    border-color: transparent;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    transition: none;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  & .MuiSelect-icon {
    right: 8px;
    top: calc(50% - 0.6em);
  }
  & .MuiSelect-select {
    margin: 0 0 0 8px;
  }
`;
