import { CSSProperties, FC, memo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';
import Paragraph, { ParagraphProps } from '../Typography/Paragraph';

const TEXT_COLOR = appColors.content.secondary;

interface JobDetailProps {
  icon: any;
  detail: string | null | undefined;
  isBlurred?: boolean;
  textStyles?: CSSProperties;
  iconProps?: IconCustomProps;
  onClick?: () => void;
  iconColor?: boolean;
}

const jobDetailStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
`;

const jobDetailIconStyles = css`
  display: flex;
  width: 24px;
  height: 24px;
`;

const JobDetail: FC<JobDetailProps> = ({
  icon: Icon,
  detail,
  isBlurred,
  textStyles,
  iconProps,
  iconColor,
  onClick,
}) => {
  const textProps: ParagraphProps = {
    children: detail,
    variant: 'sm',
    style: { color: TEXT_COLOR, cursor: 'pointer' },
  };

  return (
    <div {...{ css: jobDetailStyles }}>
      <div {...{ css: jobDetailIconStyles }}>
        {!!Icon && (
          <Icon
            {...{
              iconColor: iconColor ? appColors.content.tertiary : TEXT_COLOR,
              ...iconProps,
            }}
          />
        )}
      </div>
      {isBlurred ? (
        <Paragraph
          {...{
            ...textProps,
            ...(!!onClick ? { onClick } : {}),
            style: {
              ...textProps.style,
              ...textStyles,
              filter: 'blur(5px)',
            },
          }}
        />
      ) : (
        <Paragraph
          {...{
            ...textProps,
            ...(!!onClick ? { onClick } : {}),
            style: { ...textProps.style, ...textStyles },
          }}
        />
      )}
    </div>
  );
};

export default memo(JobDetail);
