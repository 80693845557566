//React Functionality
import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import useDropdownsData from '@/hooks/useDropdownsData';
//Components
import { FieldsContainer, FormContainer, RowContainer } from '../';
import Input, { INPUT_COMMONS } from '@/components/Input';
import InputMask from '@/components/InputMask';
import InputArea from '@/components/InputArea';
import InputDropdown from '@/components/InputDropdown';
//Types
import { LocumsLogFormValues } from '@/types/forms';
//CSS
import { css } from '@emotion/react';

const LocumsLogFlowRoleForm = ({
  methods,
}: {
  methods: UseFormReturn<LocumsLogFormValues, any>;
}) => {
  const { control } = methods;
  const { dropdownOptions } = useDropdownsData();
  return (
    <FormContainer>
      <FieldsContainer>
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'role_title',
              control,
              render: ({ field, fieldState: { error } }) => {
                return (
                  <Input
                    {...{
                      ...field,
                      label: 'Job Title',
                      placeholder: 'Enter Job Title',
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'specialty',
              control,
              rules: { ...fieldsDefaultRules['specialty'] },
              render: ({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['specialty'],
                      placeholder: 'Enter Specialty',
                      options: dropdownOptions?.specialty,
                      ...field,
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'pitch_date',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error, isTouched },
                }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['pitch_date'],
                        ...field,
                        isTouched,
                        error,
                        id: field.name,
                        //min: new Date().toISOString().split('T')[0],
                        css: css`
                          ${isTouched ? { color: '#05051B' } : placeHolderStyle}
                        `,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'rate',
                control,
                // rules: {
                //   required: {
                //     value: true,
                //     message: 'Min hourly compensation is required',
                //   },
                //},
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputMask
                      {...{
                        ...fieldsDefaultProps['min_hourly_rate_gt'],
                        ...field,
                        label: 'Offer Rate',
                        //required: true,
                        error,
                        id: field.name,
                        mask: Number,
                        hasExtraChar: true,
                        //subText: datatableUtils.MIN_HOUR_SUBTEXT,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'role_start_date',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error, isTouched },
                }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['role_start_date'],
                        ...field,
                        isTouched,
                        error,
                        id: field.name,
                        // min: new Date().toISOString().split('T')[0],
                        css: css`
                          ${isTouched ? { color: '#05051B' } : placeHolderStyle}
                        `,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'role_end_date',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error, isTouched },
                }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['role_end_date'],
                        ...field,
                        isTouched,
                        error,
                        id: field.name,
                        //min: new Date().toISOString().split('T')[0],
                        css: css`
                          ${isTouched ? { color: '#05051B' } : placeHolderStyle}
                        `,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'travel_included',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['travel_included'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'lodging_included',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['lodging_included'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>

        {
          //@ts-ignore
          <Controller
            {...{
              name: 'role_description',
              control,
              render: ({ field, fieldState: { error } }) => {
                return (
                  <Input
                    {...{
                      ...field,
                      label: 'Job Description',
                      placeholder: 'Enter Job Description',
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'role_notes',
              control,
              render: ({ field, fieldState: { error } }) => {
                return (
                  <InputArea
                    {...{
                      ...field,
                      label: 'Additional Notes',
                      placeholder: 'Add other additional notes',
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
      </FieldsContainer>
    </FormContainer>
  );
};

export default memo(LocumsLogFlowRoleForm);

const placeHolderStyle = css`
  color: #9b9ba4;
`;
