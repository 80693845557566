//React Functionality
import { ComponentProps, FC, Fragment, useCallback, useMemo } from 'react';
import { useAppDispatch } from '@/storage/store';
import { useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';
import useStepperState from '@/hooks/useStepperState';
import { getParamValueFromForm } from '@/utils/form-data';
import {
  createLocumsLogThunk,
  createProfessionalReferencesThunk,
} from '@/storage/jobboard/jobboard-actions';
//Components
import layout from '@/constants/layout';
import {
  CancelNextButtons,
  FormHeader,
  ModalHeader,
  StepperHeaderContainer,
  StepperProgresBar,
  resetFormDefaultOptions,
} from '@/components/Forms';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
import NewLocumsLogForm from '@/components/Forms/NewLocumsLog/NewLocumsLogForm';
import LocumsLogFlowRoleForm from '@/components/Forms/NewLocumsLog/LocumsLogFlowRoleForm';
import LocumsLogFlowMiscForm from '@/components/Forms/NewLocumsLog/LocumsLogFlowMiscForm';
import LocumsLogFlowReferencesForm from '@/components/Forms/NewLocumsLog/LocumsLogFlowReferencesForm';
//Types
import {
  LocumsLogFormValues,
  ProfessionalReferencesFormValues,
} from '@/types/forms';
import { NewLogParams } from '@/types/api/params';
//CSS
import { css } from '@emotion/react';

const {
  utils: { scrollToTop },
} = layout;

const steps: {
  component: FC<any>;
  header?: ComponentProps<typeof FormHeader>;
  buttonProps?: ComponentProps<typeof CancelNextButtons>;
}[] = [
  {
    component: NewLocumsLogForm,
    header: {
      title: 'Facility Details',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Cancel',
      },
      nextButtonProps: {},
    },
  },
  {
    component: LocumsLogFlowRoleForm,
    header: {
      title: 'Job Details',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {},
    },
  },
  {
    component: LocumsLogFlowMiscForm,
    header: {
      title: 'Misc',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {},
    },
  },
  {
    component: LocumsLogFlowReferencesForm,
    header: {
      title: 'Professional References',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {
        children: 'Save Locum Log',
      },
    },
  },
];

const totalSteps = steps.length;

interface PostNewJobFlowModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
  attemptToCloseModal: () => void;
}

const PostNewJobFlowModalContent: FC<PostNewJobFlowModalContentProps> = ({
  modalLayoutProps,
  attemptToCloseModal,
}) => {
  const { processing } = useInertiaForm();
  const dispatch = useAppDispatch();

  const {
    state: { currentStep },
    actions: { goNext, goBack },
  } = useStepperState({
    totalSteps,
    nextActions: {
      lastStepAction: async () => {
        closeAndRefresh();
      },
    },
    backActions: {
      lastStepAction: async () => {
        beforeCloseModal();
      },
    },
  });

  const newJobMethods = useForm<LocumsLogFormValues>({
    defaultValues: {
      recruiter: '',
      recruiter_phone: '',
      recruiter_email: '',
      facility_name: '',
      facility_city: '',
      facility_state: '',
      facility_phone: '',
      website: '',
      facility_contact: '',
      management_group: '',
      role_title: '',
      specialty: '',
      role_description: '',
      role_start_date: '',
      role_end_date: '',
      rate: '',
      travel_included: '',
      lodging_included: '',
      role_notes: '',
      pitch_date: '',
      permission_to_present: '',
      site_interview: '',
      site_date: '',
      site_contact: '',
      site_decision: '',
      position_accepted: '',
      position_reason: '',
      agency: '',
    },
    mode: 'onSubmit',
  });

  const newProfessionalReferencesMethods =
    useForm<ProfessionalReferencesFormValues>({
      defaultValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
      },
      mode: 'onSubmit',
    });

  const methodsByindex: Record<number, any> = {
    0: newJobMethods,
    1: newJobMethods,
    2: newJobMethods,
    3: newProfessionalReferencesMethods,
    4: newJobMethods,
  };

  const currentFormMethods = methodsByindex[currentStep];

  const {
    formState: { isSubmitting },
    watch,
    reset,
  } = currentFormMethods;

  const isRequesting = processing || isSubmitting;

  const closeAndRefresh = useCallback(() => {
    close();
  }, []);

  const beforeCloseModal = useCallback(async () => {
    !!attemptToCloseModal && attemptToCloseModal();
  }, []);

  const onContinue = useCallback(
    async (form: any) => {
      if (currentStep >= 0 && currentStep <= 2) {
        goNext();
        scrollToTop();
      }
      if (currentStep === 3) {
        const references = [
          {
            first_name: form.first_name,
            last_name: form.last_name,
            phone: form.phone,
            email: form.email,
            company: form.company,
          },
          {
            first_name: form.first_name_1,
            last_name: form.last_name_1,
            phone: form.phone_1,
            email: form.email_1,
            company: form.company_1,
          },
          {
            first_name: form.first_name_2,
            last_name: form.last_name_2,
            phone: form.phone_2,
            email: form.email_2,
            company: form.company_2,
          },
        ].filter((ref) => Object.values(ref).some((value) => value));

        const referencesParams = {
          references,
        };

        const params = {
          locums_log: {
            recruiter: form.recruiter,
            recruiter_phone: form.recruiter_phone,
            recruiter_email: form.recruiter_email,
            agency: form.agency,
            facility_name: form.facility_name,
            facility_city: form.facility_city,
            facility_state: form.facility_state,
            facility_phone: form.facility_phone,
            website: form.website,
            facility_contact: form.facility_contact,
            management_group: form.management_group,
            role_title: form.role_title,
            ...getParamValueFromForm('specialty', form?.specialty),
            role_description: form.role_description,
            role_start_date: form.role_start_date,
            role_end_date: form.role_end_date,
            rate: form.rate,
            role_notes: form.role_notes,
            pitch_date: form.pitch_date,
            site_date: form.site_date,
            site_contact: form.site_contact,
            ...getParamValueFromForm('travel_included', form?.travel_included),
            ...getParamValueFromForm(
              'lodging_included',
              form?.lodging_included
            ),
            ...getParamValueFromForm(
              'permission_to_present',
              form?.permission_to_present
            ),
            ...getParamValueFromForm('site_interview', form?.site_interview),
            ...getParamValueFromForm('site_decision', form?.site_decision),
            ...getParamValueFromForm(
              'position_accepted',
              form?.position_accepted
            ),
            position_reason: form.position_reason,
          },
        } as NewLogParams;
        const hasReferencesData = references.length > 0;
        try {
          const response = await dispatch(
            createLocumsLogThunk(params)
          ).unwrap();
          const referencesResponse = hasReferencesData
            ? await dispatch(
                createProfessionalReferencesThunk(referencesParams)
              ).unwrap()
            : null;

          if (response && (referencesResponse || !hasReferencesData)) {
            reset(
              {
                referencesParams,
              },
              resetFormDefaultOptions
            );
            modalLayoutProps.closeModal();
            defaultToastSuccess('', 'Your locum log has been saved');
            window.location.reload();
          }
        } catch (error) {
          defaultToastError('There was an error', 'Please try again.');
        }
        return;
      }
    },
    [currentStep]
  );

  const { header } = steps[currentStep];

  return (
    <ModalLayout
      {...{
        ...modalLayoutProps,
        closeModal: beforeCloseModal,
        isLoading: isRequesting,
      }}
    >
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `,
        }}
      >
        <ModalHeader {...{ header: 'New Locum Log' }} />
        <FormHeader {...header} />
      </div>
      <div {...{ style: { paddingBottom: 32 } }} />
      <StepperHeaderContainer {...{}}>
        <StepperProgresBar
          {...{
            total: totalSteps,
            current: currentStep,
          }}
        />
      </StepperHeaderContainer>

      {steps.map(({ component: Form, header, buttonProps }, index) => {
        if (index === currentStep) {
          return (
            <Fragment {...{ key: index }}>
              <Form
                {...{
                  methods: newJobMethods,
                  key: index,
                }}
              />
              <div {...{ style: { paddingBottom: 32 } }} />
              <CancelNextButtons
                {...{
                  cancelButtonProps: {
                    disabled: isRequesting,
                    onClick:
                      currentStep !== 0
                        ? () => {
                            goBack();
                            scrollToTop();
                          }
                        : beforeCloseModal,
                    ...buttonProps?.cancelButtonProps,
                  },
                  nextButtonProps: {
                    onClick: newJobMethods.handleSubmit(onContinue),
                    ...buttonProps?.nextButtonProps,
                  },
                }}
              />
            </Fragment>
          );
        }
      })}
    </ModalLayout>
  );
};

export default PostNewJobFlowModalContent;
