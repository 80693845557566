//React Functionality
import { FC } from 'react';
//Components
import { defaultStyles } from '@/components/Button';
import { INPUT_COMMONS } from '@/components/Input';
//CSS
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { appColors } from '@/constants/colors';
import { IconArrowDown } from '@/components/Icons/Arrow';

interface SelectNavigationProps {
  value: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

const SelectNavigation: FC<SelectNavigationProps> = ({ value, onChange }) => {
  const mobileNavButtons = [
    { label: 'Applied Jobs', url: '/providers/applied_jobs' },
    { label: 'Saved Jobs', url: '/providers/saved_jobs' },
    { label: 'Saved Search', url: '/providers/saved_searches' },
    { label: 'Locums Log', url: '/providers/locums_log' },
    { label: 'My Details', url: '/providers/details' },
  ];

  return (
    <FormControl {...{ fullWidth: true }}>
      <StyledSelect
        {...{
          labelId: 'nav-select-label',
          value: value,
          onChange: onChange,
          displayEmpty: true,
          css: defaultStyles,
          IconComponent: IconArrowDown,
        }}
      >
        <MenuItem
          {...{
            value: '',
            disabled: true,
          }}
        >
          Select Navigation
        </MenuItem>
        {mobileNavButtons.map((button, index) => (
          <MenuItem {...{ key: index, value: button.url }}>
            {button.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectNavigation;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    /* & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    } */

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgb(155, 155, 164);
      transition: none;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${appColors.content.tertiary};
    }
  }
  & .MuiSelect-select {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    font-size: 15px;
    //color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  }
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  & .MuiInputBase-input.MuiSelect-select::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  }
  & .MuiSelect-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
