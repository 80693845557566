import { memo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FieldsContainer, FormContainer, RowContainer } from '../';
import Input from '@/components/Input';
import selectsData from '@/constants/selects';
import InputMask from '@/components/InputMask';
import { EditLocumsLogFormValues } from '@/types/forms';
import InputDropdown from '@/components/InputDropdown';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { masks } from '@/constants/input-masks';
import { css } from '@emotion/react';

const EditLocumsLogForm = ({
  methods,
}: {
  methods: UseFormReturn<EditLocumsLogFormValues, any>;
}) => {
  const { control } = methods;

  return (
    <form>
      <FormContainer>
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.facility_name',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Facility Name',
                          placeholder: 'Enter Facility Name',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.facility_city',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Facility City',
                          placeholder: 'Enter Facility City',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.facility_state',
                  control,
                  // rules: {
                  //   required: { value: true, message: 'State is required' },
                  // },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state'],
                          options: selectsData['state'],
                          ...field,
                          label: 'State',
                          //required: true,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.facility_phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          label: 'Facility Phone #',
                          placeholder: 'Enter Facility Phone #',
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.website',
                  control,
                  // rules: { ...fieldsDefaultRules['website'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Facility Website',
                          placeholder: 'Enter Facility Website',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.facility_contact',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Facility Contact Name',
                          placeholder: 'Enter Facility Contact Name',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'locums_log.management_group',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...field,
                        label: 'Management Group',
                        placeholder: 'Enter Management Group',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }

          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.agency',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Agency',
                          placeholder: 'Enter Agency',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.recruiter',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Recruiter Name',
                          placeholder: 'Enter Recruiter Name',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.recruiter_phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          label: 'Recruiter Phone #',
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'locums_log.recruiter_email',
                  control,
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...field,
                          label: 'Email',
                          placeholder: 'Enter Recruiter Email',
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(EditLocumsLogForm);
