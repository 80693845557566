import produce from 'immer';

import { DataTable } from '@/types/types';
import { LocumsLog } from '@/types/entities.d';

type RowsHandle = {
  allSelected: boolean;
  isThereOneSelected: boolean;
  selectedCount: number;
  selectedRows: Record<string, boolean>;
};

type TileModalHandle = {
  clickedTile: LocumsLog | null;
  isTileModalOpen: boolean;
};

type ProviderDashboardAction =
  | {
      payload: Record<string, boolean>;
      type: 'SET_ROW_SELECTED';
    }
  | {
      payload: LocumsLog;
      type: 'OPEN_TILE_MODAL';
    }
  | {
      payload: null;
      type: 'CLOSE_TILE_MODAL';
    };

type State = {
  table: RowsHandle;
  modal: TileModalHandle;
  dataTable: DataTable<LocumsLog>;
};

const initialState: State = {
  table: {
    allSelected: false,
    isThereOneSelected: false,
    selectedCount: 0,
    selectedRows: {},
  },
  modal: {
    clickedTile: null,
    isTileModalOpen: false,
  },
  dataTable: {
    collection: [],
    pagination: {
      previousPage: null,
      nextPage: null,
      totalCount: 0,
      currentPage: 1,
      totalPages: 0,
      per: 10,
    },
    sort: null,
  },
};

const reducer = produce(
  (draft, { type, payload }: ProviderDashboardAction) => {
    switch (type) {
      case 'SET_ROW_SELECTED':
        draft.table.selectedRows = payload;
        return;
      case 'OPEN_TILE_MODAL':
        draft.modal.clickedTile = payload;
        draft.modal.isTileModalOpen = true;
        return;
      case 'CLOSE_TILE_MODAL':
        draft.modal.clickedTile = null;
        draft.modal.isTileModalOpen = false;
        return;
    }
  },
  initialState
);

export { reducer, initialState, ProviderDashboardAction };
