//React Functionality
import { ComponentProps, FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { useAppDispatch } from '@/storage/store';
import { createOneProfessionalReferencesThunk } from '@/storage/jobboard/jobboard-actions';
//Components
import LocumsLogFlowReferencesForm from '../Forms/NewLocumsLog/LocumsLogFlowReferencesForm';
import ModalLayout from '../Layout/Modal/ModalLayout';
import layout from '@/constants/layout';
import { CancelNextButtons, FormHeader } from '../Forms';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
//Types
import { ProfessionalReferencesFormValues } from '@/types/forms';
//CSS
import { css } from '@emotion/react';

const {
  windowSizes: { tablet },
  utils: { scrollToTop },
} = layout;

interface ViewReferenceModalProps {
  modalProps: ComponentProps<typeof ModalLayout>;

  logId: number;
}
const ViewReferenceModalForm: FC<ViewReferenceModalProps> = ({
  logId,
  modalProps,
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<ProfessionalReferencesFormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
    },
    mode: 'onSubmit',
  });
  const {
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = useCallback(
    async (form: ProfessionalReferencesFormValues) => {
      const params = {
        references: {
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          phone: form.phone,
          company: form.company,
          locum_id: logId,
        },
      };
      try {
        const response = await dispatch(
          createOneProfessionalReferencesThunk(params)
        ).unwrap();
        if (response) {
          //@ts-ignore
          reset(params);
          modalProps.closeModal();
          defaultToastSuccess('', 'Your professional reference has been saved');
          window.location.reload();
        }
        return;
      } catch (error) {
        defaultToastError('There was an error', 'Please try again.');
      }
      return;
    },
    [dispatch]
  );

  return (
    <>
      <ModalLayout {...{ ...modalProps }}>
        <div {...{ css: mainContainer }}>
          <FormHeader {...{ title: 'New Professional Reference' }} />
          <LocumsLogFlowReferencesForm {...{ methods, oneReference: true }} />
          <div {...{ css: buttonsContainer }}>
            <CancelNextButtons
              {...{
                cancelButtonProps: { onClick: modalProps.closeModal },
                nextButtonProps: {
                  children: ' Save Reference',
                  onClick: methods.handleSubmit(onSubmit),
                  icon: undefined,
                  variant: '6',
                },
              }}
            />
          </div>
        </div>
      </ModalLayout>
    </>
  );
};
export default ViewReferenceModalForm;

const mainContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;
