//React Functionality
import { ComponentProps, FC, memo } from 'react';
//Components
import layout from '@/constants/layout';
import ExpandableRowContent from '@/components/Table/ExpandableRowContent';
//Types
import { LocumsLog } from '@/types/entities.d';
import useDropdownsData from '@/hooks/useDropdownsData';
import { getLabelFromValue } from '@/utils/form-data';

const {
  windowSizes: { mobile },
} = layout;

interface LogDetailsProps {
  log: Partial<LocumsLog>;
  defaultCardProps?: Partial<ComponentProps<typeof ExpandableRowContent>>;
  role?: boolean;
  facility?: boolean;
  misc?: boolean;
  agency?: boolean;
}

const LogDetails: FC<LogDetailsProps> = ({
  log,
  defaultCardProps,
  role,
  facility,
  misc,
  agency,
}) => {
  const { dropdownsHashes } = useDropdownsData();

  return (
    <>
      {!!role && (
        <>
          {!!log.role_title && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'role_title',
                content: log.role_title,
              }}
            />
          )}
          {!!log.specialty && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'specialty',
                content: getLabelFromValue(
                  log.specialty,
                  dropdownsHashes?.specialty
                ),
              }}
            />
          )}
          {!!log.rate && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'rate',
                content: `$${log.rate}+/hr`,
              }}
            />
          )}
          {!!log.role_start_date && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'role_start_date',
                content: log.role_start_date,
              }}
            />
          )}
          {!!log.role_end_date && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'role_end_date',
                content: log.role_end_date,
              }}
            />
          )}
          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'travel_included',
              content: log.travel_included === true ? 'Yes' : 'No',
            }}
          />
          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'lodging_included',
              content: log.lodging_included === true ? 'Yes' : 'No',
            }}
          />
          {!!log.pitch_date && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'pitch_date',
                content: log.pitch_date,
              }}
            />
          )}
        </>
      )}
      {!!facility && (
        <>
          {!!log.facility_name && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'facility_name',
                content: log.facility_name,
              }}
            />
          )}
          {!!log.facility_city && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'facility_city',
                content: log.facility_city,
              }}
            />
          )}
          {!!log.facility_state && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'facility_state',
                content: log.facility_state,
              }}
            />
          )}
          {!!log.website && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'website',
                content: log.website,
              }}
            />
          )}
          {!!log.facility_contact && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'facility_contact',
                content: log.facility_contact,
              }}
            />
          )}
          {!!log.facility_phone && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'facility_phone',
                content: log.facility_phone,
              }}
            />
          )}
          {!!log.management_group && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'management_group',
                content: log.management_group,
              }}
            />
          )}
        </>
      )}
      {!!misc && (
        <>
          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'permission_to_present',
              content: log.permission_to_present === true ? 'Yes' : 'No',
            }}
          />

          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'site_interview',
              content: log.site_interview === true ? 'Yes' : 'No',
            }}
          />

          {!!log.site_date && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'site_date',
                content: log.site_date,
              }}
            />
          )}
          {!!log.site_contact && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'site_contact',
                content: log.site_contact,
              }}
            />
          )}

          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'site_decision',
              content: log.site_decision === true ? 'Yes' : 'No',
            }}
          />

          <ExpandableRowContent
            {...{
              ...defaultCardProps,
              type: 'position_accepted',
              content: log.position_accepted === true ? 'Yes' : 'No',
            }}
          />
        </>
      )}
      {!!agency && (
        <>
          {!!log.agency && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'agency',
                content: log.agency,
              }}
            />
          )}
          {!!log.recruiter && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'recruiter',
                content: log.recruiter,
              }}
            />
          )}
          {!!log.recruiter_phone && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'recruiter_phone',
                content: log.recruiter_phone,
              }}
            />
          )}
          {!!log.recruiter_email && (
            <ExpandableRowContent
              {...{
                ...defaultCardProps,
                type: 'recruiter_email',
                content: log.recruiter_email,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(LogDetails);
