//React Functionality
import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import produce from 'immer';
import { getFormInitialValueFromParam } from '@/utils/form-data';
import { getLocumsLogById } from '@/api/jobs';
import useDropdownsData from '@/hooks/useDropdownsData';
import { statesHash } from '@/constants/selects/states';
//Types
import { Status } from '@/types/types';
import { LocumsLog } from '@/types/entities.d';
import { EditLocumsLogFormValues } from '@/types/forms';
import { GetLocumsLogByIdResponse } from '@/types/api/responses';
//Components
import LocumsLogTileModalContentEditMode from './LocumsLogTileModalContentEditMode';
import LocumsLogTileModalContentShowMode from './LocumsLogTileModalContentShowMode';
//CSS
import ModalLayout from '../../../Layout/Modal/ModalLayout';

const initialFormValues: EditLocumsLogFormValues = {
  locums_log: {
    id: 0,
    recruiter: '',
    recruiter_phone: '',
    recruiter_email: '',
    facility_name: '',
    facility_city: '',
    facility_state: '',
    facility_phone: '',
    website: '',
    facility_contact: '',
    management_group: '',
    role_title: '',
    role_description: '',
    role_start_date: '',
    role_end_date: '',
    rate: '',
    travel_included: '',
    lodging_included: '',
    role_notes: '',
    pitch_date: '',
    permission_to_present: '',
    site_interview: '',
    site_contact: '',
    site_decision: '',
    position_accepted: '',
    position_reason: '',
    site_date: '',
    agency: '',
    specialty: '',
  },
};

interface LocumsLogTileModalContentProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  log: LocumsLog;
}

interface State {
  logByIdResponse: GetLocumsLogByIdResponse;
  status: Status;
  isEditMode: boolean;
}

type Action =
  | {
      type: 'SET_LOG';
      payload: GetLocumsLogByIdResponse;
    }
  | {
      type: 'SET_MODE';
      payload: boolean;
    }
  | {
      type: 'SET_STATUS';
      payload: Status;
    };

const initialState: State = {
  logByIdResponse: {
    locums_log: {
      id: 0,
      recruiter: '',
      recruiter_phone: '',
      recruiter_email: '',
      facility_name: '',
      facility_city: '',
      facility_state: '',
      facility_phone: '',
      website: '',
      facility_contact: '',
      management_group: '',
      role_title: '',
      role_description: '',
      role_start_date: '',
      role_end_date: '',
      rate: '',
      role_notes: '',
      pitch_date: '',
      site_contact: '',
      site_decision: false,
      position_accepted: false,
      position_reason: '',
      site_date: '',
      agency: '',
      created_at: '',
      professional_reference_count: 0,
      specialty: '',
    },
  },
  status: 'IDLE',
  isEditMode: false,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_MODE':
      draft.isEditMode = payload;
      return;
    case 'SET_LOG':
      draft.logByIdResponse = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const LocumsLogTileModalContent: FC<
  LocumsLogTileModalContentProps & { forceEditMode?: boolean }
> = ({ log, modalProps, forceEditMode = false }) => {
  const [{ isEditMode, logByIdResponse, status }, localDispatch] = useReducer(
    reducer,
    initialState
  );

  const { dropdownsHashes } = useDropdownsData();

  const changeMode = useCallback(() => {
    localDispatch({ type: 'SET_MODE', payload: !isEditMode });
  }, [isEditMode]);

  const methods = useForm<EditLocumsLogFormValues>({
    defaultValues: { ...initialFormValues },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    reset,
  } = methods;

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await getLocumsLogById(log.id);

      if (response) {
        localDispatch({ type: 'SET_LOG', payload: response });
        const { locums_log } = response;
        const options = {
          yes: {
            value: 'yes',
            label: 'Yes',
          },
          no: {
            value: 'no',
            label: 'No',
          },
        };
        reset({
          //

          locums_log: {
            id: locums_log.id,
            recruiter: locums_log.recruiter || '',
            recruiter_phone: locums_log.recruiter_phone,
            recruiter_email: locums_log.recruiter_email,
            agency: locums_log.agency,
            management_group: locums_log.management_group,
            facility_name: locums_log.facility_name,
            facility_city: locums_log.facility_city,
            ...getFormInitialValueFromParam(
              'facility_state',
              locums_log.facility_state,
              statesHash
            ),
            facility_phone: locums_log.facility_phone,
            website: locums_log.website,
            facility_contact: locums_log.facility_contact,
            role_title: locums_log.role_title,
            ...getFormInitialValueFromParam(
              'specialty',
              locums_log.specialty,
              dropdownsHashes.specialty
            ),
            rate: String(locums_log.rate) || '',
            role_start_date: locums_log.role_start_date,
            role_end_date: locums_log.role_end_date,
            travel_included: locums_log.travel_included
              ? [options.yes]
              : [options.no],
            lodging_included: locums_log.lodging_included
              ? [options.yes]
              : [options.no],
            role_description: locums_log.role_description,
            role_notes: locums_log.role_notes,
            pitch_date: locums_log.pitch_date,
            permission_to_present: locums_log.permission_to_present
              ? [options.yes]
              : [options.no],
            site_interview: locums_log.site_interview
              ? [options.yes]
              : [options.no],
            site_date: locums_log.site_date,
            site_contact: locums_log.site_contact,
            site_decision: locums_log.site_decision
              ? [options.yes]
              : [options.no],
            position_accepted: locums_log.position_accepted
              ? [options.yes]
              : [options.no],
            position_reason: locums_log.position_reason,
          },
        });
      }
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [log.id]);

  useEffect(() => {
    if (!isEditMode) {
      initialRequest();
    }
    return () => {};
  }, [isEditMode, initialRequest]);

  useEffect(() => {
    if (forceEditMode) {
      localDispatch({ type: 'SET_MODE', payload: true });
    }
  }, [forceEditMode]);

  const isDone = status === 'SUCCESS';

  if (isEditMode || forceEditMode) {
    return (
      <ModalLayout
        {...{
          ...modalProps,
          hasLogo: false,
          isLoading: isSubmitting,
          containerStyles: {
            padding: 24,
          },
        }}
      >
        <FormProvider {...methods}>
          {isDone && (
            <LocumsLogTileModalContentEditMode
              {...{
                modalProps,
                logByIdResponse,
                changeMode,
                closeModal: modalProps.closeModal,
              }}
            />
          )}
        </FormProvider>
      </ModalLayout>
    );
  }

  if (!forceEditMode) {
    return (
      <ModalLayout
        {...{
          ...modalProps,
          hasLogo: false,
          isLoading: isSubmitting,
          containerStyles: {
            padding: 24,
          },
        }}
      >
        <FormProvider {...methods}>
          {isDone && (
            <LocumsLogTileModalContentShowMode
              {...{
                modalProps,
                logByIdResponse,
                changeMode,
                closeModal: modalProps.closeModal,
              }}
            />
          )}
        </FormProvider>
      </ModalLayout>
    );
  }
  return null;
};

export default LocumsLogTileModalContent;
