//React Functionality
import { CSSProperties, FC, memo } from 'react';
//Types
import { LocumsLog } from '@/types/entities.d';
//Components
import Card from '../Card';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import Label from '../Typography/Label';
//CSS
import { css } from '@emotion/react';
import { IconInfo } from '../Icons/Info';
import { IconMapPin } from '../Icons/MapPin';
import { IconDollar } from '../Icons/Dollar';
import { IconFacility } from '../Icons/Facility';
import { IconMedicalJob } from '../Icons/MedicalJob';
import { IconAssignment } from '../Icons/Assignment';
import {
  IconAgency,
  IconCalendar,
  IconContact,
  IconEMail,
  IconGroup,
  IconLodging,
  IconPhone,
  IconTravel,
  IconWebsite,
} from '../Icons/ExpandableContentIcons';

const {
  components: { iconStyles },
} = layout;

type Variant = 'default' | 'dense';

const propsPerField: Record<
  keyof Partial<LocumsLog>,
  {
    icon: any;
    title: string;
  }
> = {
  recruiter: {
    title: 'Recruiter',
    icon: IconContact,
  },
  recruiter_phone: {
    title: 'Recruiter Phone',
    icon: IconPhone,
  },
  recruiter_email: {
    title: 'Recruiter Email',
    icon: IconEMail,
  },
  agency: {
    title: 'Agency',
    icon: IconAgency,
  },
  management_group: {
    title: 'Management Group',
    icon: IconGroup,
  },
  facility_name: {
    title: 'Facility Name',
    icon: IconFacility,
  },
  facility_city: {
    title: 'Facility City',
    icon: IconMapPin,
  },
  facility_state: {
    title: 'Facility State',
    icon: IconMapPin,
  },
  facility_phone: {
    title: 'Facility Phone',
    icon: IconPhone,
  },
  facility_contact: {
    title: 'Facility Contact',
    icon: IconContact,
  },
  website: {
    title: 'Website',
    icon: IconWebsite,
  },
  role_title: {
    title: 'Job Title',
    icon: IconMedicalJob,
  },
  specialty: {
    title: 'Specialty',
    icon: IconMedicalJob,
  },
  role_description: {
    title: 'Job Description',
    icon: IconInfo,
  },
  role_start_date: {
    title: 'Role Start Date',
    icon: IconCalendar,
  },
  role_end_date: {
    title: 'Role End Date',
    icon: IconCalendar,
  },
  rate: {
    title: 'Rate',
    icon: IconDollar,
  },
  travel_included: {
    title: 'Travel Included',
    icon: IconTravel,
  },
  lodging_included: {
    title: 'Lodging Included',
    icon: IconLodging,
  },
  role_notes: {
    title: 'Job Notes',
    icon: IconAssignment,
  },
  pitch_date: {
    title: 'Pitch Date',
    icon: IconCalendar,
  },
  permission_to_present: {
    title: 'Permission To Present',
    icon: IconAssignment,
  },
  site_contact: {
    title: 'Site Contact',
    icon: IconContact,
  },
  site_interview: {
    title: 'Site Interview',
    icon: IconAssignment,
  },
  site_date: {
    title: 'Interview Date',
    icon: IconCalendar,
  },
  position_accepted: {
    title: 'Position Accepted',
    icon: IconAssignment,
  },
  position_reason: {
    title: 'Position Reason',
    icon: IconAssignment,
  },
  site_decision: {
    title: 'Site Decision',
    icon: IconAssignment,
  },
  id: {
    icon: undefined,
    title: '',
  },
  created_at: {
    icon: undefined,
    title: '',
  },
  professional_reference_count: {
    icon: undefined,
    title: '',
  },
  professional_references: {
    icon: undefined,
    title: '',
  },
  locum_id: {
    icon: undefined,
    title: '',
  },
};

interface LogShowDetailCardProps {
  type: keyof Partial<LocumsLog>;
  content?: string;
  isBlurred?: boolean;
  extraChar?: string;
  variant?: Variant;
  dense?: boolean;
  noBlurred?: boolean;
}

const cardStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const contentContainer = css`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

const headerContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const ExpandableRowContent: FC<LogShowDetailCardProps> = ({
  type,
  content,
  isBlurred,
  extraChar = null,
  dense,
  noBlurred,
}) => {
  const { icon: Icon, title } = propsPerField[type] || {
    title: 'N/A',
    icon: IconInfo,
  };

  if (dense) {
    return (
      <Card {...{ hasBorder: true, css: cardStyles }}>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              width: 100%;
            `,
          }}
        >
          <div {...{ css: headerContainer }}>
            <div
              {...{
                css: css`
                  ${iconStyles}
                `,
              }}
            >
              <Icon {...{ iconColor: appColors.content.tertiary }} />
            </div>
          </div>
          {content && (
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                `,
              }}
            >
              <Label {...{ text: title }} />
              <Content
                {...{
                  text: content,
                  style: {
                    ...(isBlurred ? { filter: 'blur(5px)' } : {}),
                    ...(noBlurred ? { filter: '' } : {}),
                  },
                }}
              />
            </div>
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card {...{ hasBorder: true, css: cardStyles }}>
      <div {...{ css: contentContainer }}>
        <div {...{ css: headerContainer }}>
          <div
            {...{
              css: css`
                ${iconStyles}
              `,
            }}
          >
            <Icon {...{ iconColor: appColors.content.tertiary }} />
          </div>
          <Label {...{ text: title }} />
        </div>
        {content && (
          <Content
            {...{
              text: content,
              style: {
                ...(isBlurred ? { filter: 'blur(5px)' } : {}),
                ...(noBlurred ? { filter: '' } : {}),
              },
            }}
          />
        )}
        {extraChar && (
          <Label
            {...{
              text: extraChar,
              style: {
                color: appColors.content.placeholder,
              },
            }}
          />
        )}
      </div>
    </Card>
  );
};

export default memo(ExpandableRowContent);

const Content: FC<{ text: string; style?: CSSProperties }> = ({
  text,
  style,
}) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          overflow: hidden;
          text-overflow: ellipsis;
        `,
      }}
    >
      <Paragraph
        {...{
          variant: 'md',
          style,
          children: text,
        }}
      />
    </div>
  );
};
