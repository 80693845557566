import { AnchorHTMLAttributes, FC, ReactNode, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import CellRendererText from '@/components/Table/CellRendererText';
import { SearchProvider } from '@/types/entities.d';
import { updateSavedSearchThunk } from '@/storage/jobboard/jobboard-actions';
import { useAppDispatch } from '@/storage/store';

import ColumnHeader from '@/components/Table/ColumnHeader';
import Checkbox from '@/components/Checkbox';
import styled from '@emotion/styled';
import layout from '../layout';
import {
  ParagraphVariants,
  paragraphDesktop,
  paragraphMobile,
} from '@/constants/typography';
import { appColors } from '../colors';
import { Switch } from '@mui/material';
const { mediaQuery } = layout;

// // //
const StyledText = styled.a<{ variant: ParagraphVariants }>(({ variant }) => ({
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  userSelect: 'none',
  ...paragraphDesktop[variant],
  [mediaQuery.mobile]: {
    ...paragraphMobile[variant],
  },
}));

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly text?: ReactNode;
  readonly link?: string;
  readonly target: string;
}

const CellRendererLink: FC<LinkProps> = ({ text, link, target }) => {
  return (
    <StyledText
      variant="sm"
      href={link}
      target={target}
      style={{
        color: appColors.content.tertiary,
        textDecoration: 'underline',
        lineHeight: '140%',
        fontSize: '14px',
      }}
    >
      {text}
    </StyledText>
  );
};

const columns: ColumnDef<SearchProvider>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Search Name' }} />,
    accessorKey: 'search_name',
    enableSorting: false,
    accessorFn: (row) => `${row.url} ${row.search_name}`,
    cell: (info: any) => {
      const value = info.getValue();
      const valueArray = value.split(' ');
      const URL = valueArray[0];
      const searchName = valueArray.slice(1).join(' ');
      return <CellRendererLink text={searchName} link={URL} target="_blank" />;
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Receive Notification' }} />,
    accessorKey: 'send_notification',
    enableSorting: false,
    cell: ({ row }) => {
      const dispatch = useAppDispatch();
      const [isChecked, setIsChecked] = useState(
        row.getValue('send_notification') ?? false
      ); // Use nullish coalescing operator

      const handleToggle = (event: any) => {
        const newValue = event.target.checked;
        setIsChecked(newValue);
        dispatch(
          updateSavedSearchThunk({
            params: { id: row.original.id, send_notification: newValue },
          })
        );
      };

      return (
        //@ts-ignore
        <Switch
          {...{
            size: 'small',
            checked: isChecked,
            onChange: handleToggle,
            id: row.original.id,
          }}
        />
      );
    },
  },
];
const providersCustomDatatableConfig = {
  columns,
};

export { providersCustomDatatableConfig };
