import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head, router } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Top from '../../../../app/assets/images/Save-Search-page.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Introducing Save Search</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeOne: {
              topImageSrc: Top,
              topImageAlt: 'Doctor typing on a laptop',
              topSectionTitle: 'We’re looking out for you!',
              topSectionText: (
                <>
                  Use Save Search to save your criteria, and get job alerts.{' '}
                  <br />
                  With “Save Search,” searching for jobs is easier than ever.
                  Build your perfect search using the available filters,
                  “Profession,” “Location,” and “Specialty.” Then click “Save
                  Search.” You’ll give your search a name and turn on
                  notifications. When notifications are on, you’ll receive
                  weekly emails with any posted jobs that meet your criteria.
                  It’s that easy. You can use “Save Search” as many options and
                  as many times as you want. So go browse today, and save all of
                  your searches. Try it today. At Locums.com, we want to be your
                  online resource for all things locum tenens. Visit and browse
                  daily, catch up on locums news, and get excited about the new
                  features we’re developing to make your job search even easier.
                  For example…. New feature coming soon: Online Locums Log
                  <br />
                  <br />
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      router.get(`/saved_search_instructions`);
                    }}
                  >
                    Click here
                  </a>{' '}
                  to learn how to use the “Save Search” feature and make your
                  job search more efficient.
                </>
              ),
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
