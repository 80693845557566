import { memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/react';

import { FormContainer } from '../';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { PostNewJobFormValues } from '@/types/forms';
import JobDetails from '@/components/Job/JobDetails';
import { getValuesFromForm } from '@/utils/form-data';
import Heading from '@/components/Typography/Heading';
import Paragraph from '@/components/Typography/Paragraph';
import JobDescDetail from '@/components/Job/JobDescDetail';
import JobShowDescriptionHeader from '@/components/Job/JobShowDescriptionHeader';

const {
  windowSizes: { tablet },
} = layout;

const minTablet = tablet + 1;

const jobCardContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${appColors.content.tertiary};
`;

const subheaderContentContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const detailGridContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PostNewJobPreviewForm = ({
  methods,
}: {
  methods: UseFormReturn<PostNewJobFormValues, any>;
}) => {
  const {
    formState: { isSubmitting },
    watch,
  } = methods;

  const jobTitle = watch('title');

  const desc = watch('description');
  const reqs = watch('requirements');

  const job = {
    profession: getValuesFromForm(watch('profession')),
    employment_type: getValuesFromForm(watch('employment_type')),
    assignment: getValuesFromForm(watch('assignment')),
    facility: getValuesFromForm(watch('facility')),
    specialty: getValuesFromForm(watch('specialty')),
    //
    board_certification: watch('board_certification.value'),
    state: watch('state.value'),
    min_hourly_rate: watch('min_hourly_rate'),
    credentialing_time:watch('credentialing_time.value'),
    target_start_date: watch('target_start_date'),
    any_state_licenses:watch('any_state_licenses.value')
  };
  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <Paragraph
          {...{
            variant: 'lg',
            style: { color: appColors.content.secondary },
            children:
              'Review the details of this job posting to ensure everything looks correct.',
          }}
        />

        <div {...{ css: jobCardContainer }}>
          <Heading {...{ children: jobTitle, variant: 'h2' }} />

          {/*  DETAILS  */}
          <div>
            {/*  DESKTOP DETAILS  */}
            <div
              {...{
                css: css`
                  ${subheaderContentContainerStyles}
                  @media (max-width: ${tablet}px) {
                    display: none;
                  }
                `,
              }}
            >
              <JobShowDescriptionHeader {...{ text: 'Details' }} />
              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <JobDetails
                  {...{
                    job,
                    isShowing: true,
                    defaultCardProps: {
                      extraChar: '',
                      dense: true,
                     noBlurred: true
                    },
                  }}
                />
              </div>
            </div>

            {/*  MOBILE DETAILS  */}
            <div
              {...{
                css: css`
                  ${subheaderContentContainerStyles}
                  @media (min-width: ${minTablet}px) {
                    display: none;
                  }
                `,
              }}
            >
              <JobShowDescriptionHeader
                {...{
                  text: 'Details',
                }}
              />

              <div
                {...{
                  css: detailGridContainerStyles,
                }}
              >
                <JobDetails
                  {...{
                    job,
                    isShowing: true,
                    defaultCardProps: {
                      extraChar: '',
                      isBlurred: false,
                      noBlurred: true
                    },
                  }}
                />
              </div>
            </div>
          </div>

          {!!desc && (
            <JobDescDetail
              {...{
                header: 'Description',
                detail: desc,
              }}
            />
          )}
          {!!reqs && (
            <JobDescDetail
              {...{
                header: 'Requirements',
                detail: reqs,
              }}
            />
          )}
        </div>
      </FormContainer>
    </form>
  );
};

export default memo(PostNewJobPreviewForm);
