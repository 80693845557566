//React Functionality
import { ComponentProps, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { getMetaToken } from '@/utils/meta-token';
import { useForm as useInertiaForm } from '@inertiajs/react';
//Components
import LocumsLogFlowReferencesEditForm from '../Forms/EditLocumsLog/EditLocumsReferencesForm';
import ModalLayout from '../Layout/Modal/ModalLayout';
import { CancelNextButtons, FormHeader } from '../Forms';
import { defaultToastSuccess } from '@/constants/toasts';
//Types
import { EditProfessionalReferencesFormValues } from '@/types/forms';
import { NewProfessionalReferencesParams } from '@/types/api/params';
//CSS
import { css } from '@emotion/react';

const URL = '/providers/professional_reference';
interface ViewReEditferenceModalProps {
  modalProps: ComponentProps<typeof ModalLayout>;
}

const ViewReferenceModalEditForm: FC<ViewReEditferenceModalProps> = ({
  modalProps,
}) => {
  const { patch, transform, processing } = useInertiaForm();
  const methods = useFormContext<EditProfessionalReferencesFormValues>();

  const {
    formState: { isSubmitting },
  } = methods;

  const onSaveChanges = useCallback(
    async (form: EditProfessionalReferencesFormValues) => {
      try {
        const params = {
          references: {
            first_name: form.references.first_name,
            last_name: form.references.last_name,
            email: form.references.email,
            phone: form.references.phone,
            company: form.references.company,
          },
        } as unknown as NewProfessionalReferencesParams;

        const metaTokenHeader = await getMetaToken();
        if (!!metaTokenHeader) {
          patch(`${URL}/${form.references.id}`, {
            preserveState: true,
            //@ts-ignore
            data: transform(() => ({
              ...params,
            })),
            headers: {
              ...metaTokenHeader,
            },
            onSuccess: (successResponse) => {
              modalProps.closeModal();
              defaultToastSuccess(
                '',
                'Your professional reference has been updated'
              );
            },
            onError: (errorResponse) => {
              console.log({ errorResponse });
            },
          });
        }
        return;
      } catch (error) {}
    },
    []
  );

  return (
    <ModalLayout {...modalProps}>
      <div {...{ css: mainContainer }}>
        <FormHeader {...{ title: 'Edit Professional Reference' }} />
        <LocumsLogFlowReferencesEditForm {...{ methods }} />
        <div {...{ css: buttonsContainer }}>
          <CancelNextButtons
            {...{
              cancelButtonProps: { onClick: modalProps.closeModal },
              nextButtonProps: {
                children: isSubmitting ? 'Saving...' : 'Save Reference',
                onClick: methods.handleSubmit(onSaveChanges),
              },
            }}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default ViewReferenceModalEditForm;

const mainContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
