//React Functionality
import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { fieldsDefaultProps } from '@/constants/form-fields';
//Components
import { FieldsContainer, FormContainer, RowContainer } from '../';
import Input, { INPUT_COMMONS } from '@/components/Input';
import InputArea from '@/components/InputArea';
import InputDropdown from '@/components/InputDropdown';
//Types
import { LocumsLogFormValues } from '@/types/forms';
//CSS
import { css } from '@emotion/react';

const LocumsLogFlowMiscForm = ({
  methods,
}: {
  methods: UseFormReturn<LocumsLogFormValues, any>;
}) => {
  const { control } = methods;

  return (
    <FormContainer>
      <FieldsContainer>
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'permission_to_present',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['permission_to_present'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'site_contact',
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...field,
                        label: 'Site Contact',
                        placeholder: 'Enter Site Contact Name',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'site_interview',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['site_interview'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'site_date',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error, isTouched },
                }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['site_date'],
                        ...field,
                        isTouched,
                        error,
                        id: field.name,
                        //min: new Date().toISOString().split('T')[0],
                        css: css`
                          ${isTouched ? { color: '#05051B' } : placeHolderStyle}
                        `,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>
        <RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'site_decision',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['site_decision'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'position_accepted',
                control,
                rules: {
                  required: false,
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        options: [
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ],
                        ...fieldsDefaultProps['position_accepted'],
                        ...field,
                        id: field.name,
                        error,
                        styles: {
                          placeholder: () => {
                            return {
                              fontSize: '15px',
                              color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                            };
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
        </RowContainer>
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'position_reason',
              control,
              render: ({ field, fieldState: { error } }) => {
                return (
                  <InputArea
                    {...{
                      ...field,
                      label: 'Reason Yes/No',
                      placeholder: 'Add Reason Notes',
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
      </FieldsContainer>
    </FormContainer>
  );
};

export default memo(LocumsLogFlowMiscForm);

const placeHolderStyle = css`
  color: #9b9ba4;
`;
