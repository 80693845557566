import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head, router } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Bottom from '../../../assets/images/1000-art_Locums-Log-article-page-sm.jpg';
import Top from '../../../assets/images/1000-art_Locums-Log-article-page.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Keeping a Locum Log</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeOne: {
              topImageSrc: Top,
              topImageAlt: 'A doctors sitting at a conference table',
              topSectionTitle:
                'Do you keep a locum log yet? Here’s your reminder to start one now.',
              topSectionText: (
                <>
                  Working as a locum has tremendous benefits, including travel,
                  great paychecks, the flexibility to create your own schedule,
                  and so much more. Part of the beauty of locum tenens is the
                  variety, and the seemingly endless possibilities for when,
                  where, and how much you work. Experienced locums providers,
                  and recruiters alike, share a crucial bit of advice in that
                  you must stay organized. A great starting point for this
                  organization is to keep a detailed locum log, where you
                  document every assignment and conversation about any potential
                  assignments, so you have a record of your locum journey. Why
                  take notes? Your locum log (which could be a paper notebook
                  you write in or a spreadsheet on your computer) is crucial for
                  your success in locum tenens. You need to write down the
                  details of every assignment you discuss with your locums
                  recruiter, including both those positions you accept and those
                  you decline. Your locum log allows you to keep straight which
                  agency/recruiter told you about which job, details about job
                  descriptions, dates, rates, and why a position may or may not
                  have been appealing to you. These details also become
                  beneficial as you update your curriculum vitae.
                </>
              ),
              hasQuote: true,
              bottomImageSrc: Bottom,
              bottomImageAlt: 'Recruiter typing data',
              bottomImageTitle: 'Locum Log ',
              bottomSectionText: (
                <>
                  Here are some of the important details to capture in your
                  locum log for every locum-related phone call and assignment:
                  <ul {...{ css: orderList }}>
                    <li>Opportunities presented to you</li>
                    <li>
                      Locum tenens company name, address, phone, website, Email
                    </li>
                    <li>Recruiter Name(s), Phone, Email</li>
                    <li>Date when the assignment was pitched</li>
                    <li>Dates of the position (month/year format)</li>
                    <li>Rate offered</li>
                    <li>Length of contract</li>
                    <li>
                      Did you give permission for the recruiter to present you?
                      (yes/no)
                    </li>
                    <li>Travel and lodging offered</li>
                    <li>
                      References from that assignment (You may need to provide
                      references in the future; it’s good to have names of
                      people who you can use as references)
                    </li>
                    <li> Any important names, titles, and phone numbers</li>
                  </ul>
                  Keeping detailed records of your locums journey is a great way
                  to keep yourself organized and will prove to be increasingly
                  important the longer you practice locum tenens. <br /> <br />
                  Discover how to streamline your locum job management with the
                  "Locum Log" feature.{' '}
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      router.get(`/locum_log_instructions`);
                    }}
                  >
                    Click here to learn more!
                  </a>
                </>
              ),
              withTestimonial: false,
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
