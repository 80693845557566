//React Functionality
import { FC, memo } from 'react';
import useDropdownsData from '@/hooks/useDropdownsData';
//Types
import { LocumsLog } from '@/types/entities.d';
//Components
import Card from '../Card';
import JobDetail from './JobDetail';
//CSS
import { css } from '@emotion/react';
import { IconEye } from '../Icons/Eye';
import layout from '@/constants/layout';
import { IconMapPin } from '../Icons/MapPin';
import CustomDivider from '../CustomDivider';
import { appColors } from '@/constants/colors';
import JobListTileHeader from './JobListTileHeader';
import { IconMedicalJob } from '../Icons/MedicalJob';
import { IconDollar } from '../Icons/Dollar';
import { getLabelFromValue } from '@/utils/form-data';


const HOVER_COLOR = appColors.content.tertiary;

const {
  windowSizes: { tablet },
} = layout;

interface ProvidersDashboardListTileProps {
  item: LocumsLog;
  onClick?: () => void;
}

const jobListTileStyles = css`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto auto auto;
  column-gap: 40px;
  width: 100%;
  flex-grow: 0;
  &:hover {
    border-color: ${HOVER_COLOR};
  }
  cursor: pointer;
  @media (max-width: ${tablet}px) {
    column-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const contentContainerStyle = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 8px 16px 16px;
`;

const infoContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

const infoDetailsContainerStyle = css`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const arrowContainerStyle = css`
  display: flex;
`;

const ProvidersDashboardListTile: FC<ProvidersDashboardListTileProps> = ({
  item,
  onClick,
}) => {
  const { dropdownsHashes } = useDropdownsData();
  return (
    <Card
      {...{
        css: css({
          ...jobListTileStyles,
        }),
        style: { padding: 0 },
        onClick: onClick,
      }}
    >
      <div {...{ css: contentContainerStyle }}>
        <div {...{ css: infoContainerStyle }}>
          <JobListTileHeader
            {...{
              title: item.role_title || '',
            }}
          />
          <div {...{ css: infoDetailsContainerStyle }}>
            <JobDetail
              {...{
                icon: IconEye,
                detail: '',
              }}
            />
            <CustomDivider {...{ type: 'vertical' }} />
            <JobDetail
              {...{
                icon: IconMedicalJob,
                detail: getLabelFromValue(
                  item.specialty,
                  dropdownsHashes.specialty
                ),
              }}
            />
            <JobDetail
              {...{
                icon: IconMapPin,
                detail: item.facility_state,
              }}
            />
          </div>
        </div>
        <div {...{ css: arrowContainerStyle }}></div>
      </div>
    </Card>
  );
};

export default memo(ProvidersDashboardListTile);
