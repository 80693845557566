//React Functionality
import { ComponentProps, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import useStepperState from '@/hooks/useStepperState';
import { useForm as useInertiaForm } from '@inertiajs/react';
import { getMetaToken } from '@/utils/meta-token';
import { getParamValueFromForm } from '@/utils/form-data';
//Types
import { EditLocumsLogFormValues } from '@/types/forms';
import { NewLogParams } from '@/types/api/params';
//Components
import ModalLayout from '../../../Layout/Modal/ModalLayout';
import {
  CancelNextButtons,
  FormHeader,
  StepperHeaderContainer,
  StepperProgresBar,
} from '@/components/Forms';
import EditLocumsLogForm from '@/components/Forms/EditLocumsLog/EditLocumsLogForm';
import EditLocumsLogRoleForm from '@/components/Forms/EditLocumsLog/EditLocumsLogRoleForm';
import EditLocumsLogMiscForm from '@/components/Forms/EditLocumsLog/EditLocumsLogMiscForm';
//CSS
import { css } from '@emotion/react';
import layout from '@/constants/layout';
import { defaultToastSuccess } from '@/constants/toasts';

const URL = '/providers/locum_log';

const {
  windowSizes: { tablet },
  utils: { scrollToTop },
} = layout;

const steps: {
  component: FC<any>;
  header?: ComponentProps<typeof FormHeader>;
  buttonProps?: ComponentProps<typeof CancelNextButtons>;
}[] = [
  {
    component: EditLocumsLogForm,
    header: {
      title: 'Facility Details',
    },

    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {},
    },
  },
  {
    component: EditLocumsLogRoleForm,
    header: {
      title: 'Job Details',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Cancel',
      },
      nextButtonProps: {},
    },
  },
  {
    component: EditLocumsLogMiscForm,
    header: {
      title: 'Misc',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: { children: 'Save Locum Log' },
    },
  },
];

const totalSteps = steps.length;

interface LocumsLogTileModalContentEditModeProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  changeMode: () => void;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;

const LocumsLogTileModalContentEditMode: FC<
  LocumsLogTileModalContentEditModeProps
> = ({ changeMode, modalProps }) => {
  const { patch, transform, processing } = useInertiaForm();
  const beforeCloseModal = useCallback(async () => {
    !!changeMode && changeMode();
  }, []);

  const closeAndRefresh = useCallback(() => {
    close();
  }, []);

  const {
    state: { currentStep },
    actions: { goNext, goBack },
  } = useStepperState({
    totalSteps,
    nextActions: {
      lastStepAction: async () => {
        closeAndRefresh();
      },
    },
    backActions: {
      lastStepAction: async () => {
        beforeCloseModal();
      },
    },
  });
  const methods = useFormContext<EditLocumsLogFormValues>();

  const isRequesting = processing || methods.formState.isSubmitting;

  const onCancel = useCallback(() => {
    changeMode();
    scrollToTop();
  }, []);

  const onSaveChanges = useCallback(async (form: EditLocumsLogFormValues) => {
    try {
      const params: NewLogParams = {
        //@ts-ignore
        locums_log: {
          recruiter: form.locums_log.recruiter || '',
          recruiter_phone: form.locums_log.recruiter_phone,
          recruiter_email: form.locums_log.recruiter_email,
          agency: form.locums_log.agency,
          facility_name: form.locums_log.facility_name,
          facility_city: form.locums_log.facility_city,
          ...getParamValueFromForm(
            'facility_state',
            form?.locums_log.facility_state
          ),
          // facility_state: form.locums_log.facility_state,
          facility_phone: form.locums_log.facility_phone,
          website: form.locums_log.website,
          facility_contact: form.locums_log.facility_contact,
          management_group: form.locums_log.management_group,
          role_title: form.locums_log.role_title,
          ...getParamValueFromForm('specialty', form?.locums_log.specialty),
          rate: form.locums_log.rate,
          role_start_date: form.locums_log.role_start_date,
          role_end_date: form.locums_log.role_end_date,
          ...getParamValueFromForm(
            'travel_included',
            form?.locums_log.travel_included
          ),
          ...getParamValueFromForm(
            'lodging_included',
            form?.locums_log.lodging_included
          ),
          role_description: form.locums_log.role_description,
          role_notes: form.locums_log.role_notes,
          pitch_date: form.locums_log.pitch_date,
          ...getParamValueFromForm(
            'permission_to_present',
            form?.locums_log.permission_to_present
          ),
          ...getParamValueFromForm(
            'site_interview',
            form?.locums_log.site_interview
          ),
          site_date: form.locums_log.site_date,
          site_contact: form.locums_log.site_contact,
          ...getParamValueFromForm(
            'site_decision',
            form?.locums_log.site_decision
          ),
          ...getParamValueFromForm(
            'position_accepted',
            form?.locums_log.position_accepted
          ),
          position_reason: form.locums_log.position_reason,
        },
      };
      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(`${URL}/${form.locums_log.id}`, {
          preserveState: true,
          //@ts-ignore
          data: transform(() => ({
            ...params,
          })),

          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse) => {
            modalProps.closeModal();
            defaultToastSuccess('', 'Your log has been updated');
          },
          onError: (errorResponse) => {
            console.log({ errorResponse });
          },
        });
      }
      return;
    } catch (error) {}
  }, []);

  const CurrentStepComponent = steps[currentStep].component;
  return (
    <div
      {...{
        css: contentContainer,
        style: {
          pointerEvents: isRequesting ? 'none' : 'auto',
        },
      }}
    >
      <FormHeader
        {...{
          title: 'Edit Locum Log',
          subtitle: 'Save changes in order to update the log.',
          type: 'gray',
        }}
      />
      <StepperHeaderContainer {...{ canGoBack: true }}>
        <StepperProgresBar
          {...{
            total: totalSteps,
            current: currentStep,
          }}
        />
      </StepperHeaderContainer>
      <div>
        <CurrentStepComponent {...{ methods }} />
      </div>
      <div {...{ css: buttonsContainer }}>
        <CancelNextButtons
          {...{
            cancelButtonProps: {
              disabled: isRequesting,
              onClick: currentStep === 0 ? modalProps.closeModal : goBack,
              children: currentStep === 0 ? 'Cancel' : 'Back',
            },
            nextButtonProps: {
              children:
                currentStep === totalSteps - 1 ? 'Save Changes' : 'Next',
              onClick:
                currentStep === totalSteps - 1
                  ? methods.handleSubmit(onSaveChanges)
                  : goNext,
            },
          }}
        />
      </div>
    </div>
  );
};

export default LocumsLogTileModalContentEditMode;
