//React Functionality
import { FC, useCallback, useReducer, useState } from 'react';
import useModal from '@/hooks/useModal';
import { getLocumsLogById } from '@/api/jobs';
import { FormProvider, useForm } from 'react-hook-form';
//Components
import ModalLayout from '../Layout/Modal/ModalLayout';
import layout from '@/constants/layout';
import { paragraphDesktop } from '@/constants/typography';
import Button, { ButtonProps } from '../Button';
import CellRendererText from '../Table/CellRendererText';
import Card from '../Card';
import Paragraph from '../Typography/Paragraph';
import Modal from '../Modal';
import ViewReferenceModalForm from './ViewReferenceModalForm';
import ViewReferenceModalEditForm from './ViewReferenceModalEditForm';
//Types
import { LocumsLog } from '@/types/entities.d';
import { ProfessionalReference, Status } from '@/types/types';
import { EditProfessionalReferencesFormValues } from '@/types/forms';
//CSS
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconEdit } from '../Icons/Edit';
import { IconPlus } from '../Icons/Plus';
import { IconButton } from '@mui/material';
import { IconEye } from '../Icons/Eye';
import {
  IconAgency,
  IconContact,
  IconEMail,
  IconPhone,
} from '../Icons/ExpandableContentIcons';
import { LogoSpinner } from '../Icons/Logo';
import { appColors } from '@/constants/colors';

const {
  windowSizes: { tablet },
} = layout;

interface ViewReferencesModalContentProps {
  log: Partial<LocumsLog>;
  count: string;
}

const initialFormValues: EditProfessionalReferencesFormValues = {
  references: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
  },
};

const ViewReferencesModalContent: FC<ViewReferencesModalContentProps> = ({
  log = {},
  count = '0',
}) => {
  const { ModalComponent, openModal, closeModal } = useModal();
  const HeaderText = styled.label({ ...paragraphDesktop.lg });
  interface State {
    professionalReferences: ProfessionalReference[];
    status: Status;
  }

  type Action =
    | { type: 'SET_REFERENCES'; payload: ProfessionalReference[] }
    | { type: 'SET_STATUS'; payload: Status };

  const initialState: State = {
    professionalReferences: [],
    status: 'IDLE',
  };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'SET_REFERENCES':
        return { ...state, professionalReferences: action.payload };
      case 'SET_STATUS':
        return { ...state, status: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAddingReference, setIsAddingReference] = useState(false);
  const [isEditingReference, setIsEditingReference] = useState(false);
  const methods = useForm<EditProfessionalReferencesFormValues>({
    defaultValues: { ...initialFormValues },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    reset,
  } = methods;
  const openReferencesModal = useCallback(async (locumId: number) => {
    openModal();
    dispatch({ type: 'SET_STATUS', payload: 'FETCHING' });

    try {
      const response = await getLocumsLogById(locumId);
      if (response?.locums_log?.professional_references) {
        dispatch({
          type: 'SET_REFERENCES',
          payload: response.locums_log.professional_references,
        });
        dispatch({ type: 'SET_STATUS', payload: 'SUCCESS' });
      } else {
        dispatch({ type: 'SET_STATUS', payload: 'SUCCESS' });
        dispatch({ type: 'SET_REFERENCES', payload: [] });
      }
    } catch (error) {
      dispatch({ type: 'SET_STATUS', payload: 'ERROR' });
      console.error('Error fetching references:', error);
    }
  }, []);

  /////////////////Open Add Reference///////////////////////////////////
  const handleAddReference = () => {
    setIsAddingReference(true);
    closeModal(); 
  };

  const handleReferenceFormClose = () => {
    setIsAddingReference(false);
    closeModal();
  };

  ///////////////Edit Modal///////////////////
  const handleEditReference = (reference: ProfessionalReference) => {
    setIsEditingReference(true);
    closeModal();
    methods.reset({ references: reference });
  };

  const handleEditReferenceFormClose = () => {
    setIsEditingReference(false);
    closeModal(); 
  };

  const addButtonProps: ButtonProps = {
    onClick: handleAddReference,
    variant: '1',
    children: 'Add Reference',
    icon: IconPlus,
  };

  return (
    <>
      <CellRendererText {...{ text: count }} /> 
      <IconButton
        onClick={() => {
          if (log.id) openReferencesModal(log.id);
        }}
      >
        <IconEye {...{ iconColor: appColors.content.tertiary }} />
      </IconButton>

      <ModalComponent {...{ onClose: closeModal }}>
        <ModalLayout {...{ closeModal, containerStyles: { width: '500px' } }}>
          {state.status === 'SUCCESS' ? (
            state.professionalReferences.length > 0 ? (
              <div {...{ css: MainContainer }}>
                <HeaderText>
                  Professional References ({state.professionalReferences.length}
                  )
                </HeaderText>
                {state.professionalReferences.map((reference) => (
                  <Card {...{key:reference.id}}>
                    <div {...{ css: ItemsContainer }}>
                      <div {...{ css: infoContainer }}>
                        <div {...{css:iconTextContainer}}>
                          <IconContact
                            {...{
                              iconColor: appColors.content.secondary,
                              size: 16,
                            }}
                          />
                          <Paragraph
                            {...{
                              children: `${reference.first_name} ${reference.last_name}`,
                              variant: 'md',
                              style: {
                                fontWeight: 500,
                              },
                            }}
                          />
                        </div>
                        <div {...{css:iconTextContainer}}>
                          <IconAgency
                            {...{
                              iconColor: appColors.content.secondary,
                              size: 16,
                            }}
                          />
                          <Paragraph
                            {...{
                              children: reference.company,
                              variant: 'sm',
                              style: {
                                color: appColors.content.secondary,
                              },
                            }}
                          />
                        </div>
                        <div {...{css:iconTextContainer}}>
                          <IconEMail
                            {...{
                              iconColor: appColors.content.secondary,
                              size: 16,
                            }}
                          />
                          <Paragraph
                            {...{
                              children: reference.email,
                              variant: 'sm',
                              style: {
                                color: appColors.content.secondary,
                              },
                            }}
                          />
                        </div>
                        <div {...{css:iconTextContainer}}>
                          <IconPhone
                            {...{
                              iconColor: appColors.content.secondary,
                              size: 16,
                            }}
                          />
                          <Paragraph
                            {...{
                              children: reference.phone,
                              variant: 'sm',
                              style: {
                                color: appColors.content.secondary,
                              },
                            }}
                          />
                        </div>
                      </div>

                      <Button
                        {...{
                          onClick: () => handleEditReference(reference), // Move logic inside map
                          variant: '2',
                          icon: IconEdit,
                          children: '',
                          style: { marginBlockEnd: 'auto', width: '50px' },
                        }}
                      />
                    </div>
                  </Card>
                ))}
                <Button {...addButtonProps} />
              </div>
            ) : (
              <div {...{ css: SpinnerContainer }}>
                <Paragraph
                  {...{
                    children: 'No references available',
                    variant: 'md',
                  }}
                />
                <Button {...addButtonProps} />
              </div>
            )
          ) : state.status === 'ERROR' ? (
            <p>Error fetching references</p>
          ) : (
            <div
              {...{
                css: SpinnerContainer,
              }}
            >
              <LogoSpinner />
              <Paragraph
                {...{
                  children: 'Loading references...',
                  variant: 'md',
                }}
              />
            </div>
          )}
        </ModalLayout>
      </ModalComponent>

      {isAddingReference && (
        <Modal {...{ position: 'top', onClose: handleReferenceFormClose }}>
          <ViewReferenceModalForm
            {...{
              modalProps: { closeModal: handleReferenceFormClose },
              logId: log.locum_id ?? 0,
            }}
          />
        </Modal>
      )}

      {isEditingReference && (
        <Modal
          {...{
            position: 'top',
            onClose: () => handleEditReferenceFormClose(),
          }}
        >
          <FormProvider {...methods}>
            <ViewReferenceModalEditForm
              {...{ modalProps: { closeModal: handleEditReferenceFormClose } }}
            />
          </FormProvider>
        </Modal>
      )}
    </>
  );
};

export default ViewReferencesModalContent;

const MainContainer = css`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
`;

const infoContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ItemsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const iconTextContainer = css`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
`;

const SpinnerContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`;

const noReferencesConatinmer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;
